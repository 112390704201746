import { TaskResponseDto } from '@cozero/dtos'
import { TaskFilterDto } from '@cozero/dtos'

export const GET_STATUSES = 'getStatuses'
export const GET_TOPICS = 'getTopics'
export const GET_ONE = 'getOne'
export const SEARCH = 'search'
export const CREATE = 'create'
export const UPDATE_ONE = 'updateOne'
export const DELETE_ONE = 'deleteOne'
export const VALIDATE_TASK_NAME = 'validateTaskName'

export const TAG_TASK = 'TASK'
export const TAG_VALIDATE_TASK_NAME = 'VALIDATE_TASK_NAME'
export const TAG_PAGINATED_TASKS = 'PAGINATED_TASKS'

export const SLICE_NAME = 'tasks'
export const DEFAULT_STATE: {
  filters: Partial<TaskFilterDto>
  currentPageResults?: TaskResponseDto[]
} = {
  filters: {},
}

export default [TAG_TASK, TAG_PAGINATED_TASKS, TAG_VALIDATE_TASK_NAME]
