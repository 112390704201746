import React from 'react'
import { HiOutlineDocument, HiOutlineDocumentDownload } from 'react-icons/hi'
import { IconType } from 'react-icons/lib'

import { UploadFileStatus } from 'antd/lib/upload/interface'

import classnames from 'classnames'

import classes from './SquaredIcon.module.less'

interface SquaredIconProps {
  iconNode: JSX.Element
  color?: 'default' | 'green' | 'red'
}
const DEFAULT_SIZE = 20
const _SquaredIcon = ({ iconNode, color = 'default' }: SquaredIconProps): React.ReactElement => {
  const colorClass = (() => {
    switch (color) {
      case 'red':
        return classes.iconContainerRed
      case 'green':
        return classes.iconContainerGreen
      case 'default':
      default:
        return classes.iconContainerDefault
    }
  })()
  return <div className={classnames(classes.iconContainer, colorClass)}>{iconNode}</div>
}
const SquaredIcon = React.memo(_SquaredIcon)

interface FileUploadIconProps {
  status: UploadFileStatus | undefined
}
const _FileUploadIcon = ({ status }: FileUploadIconProps): React.ReactElement => {
  const color = (() => {
    switch (status) {
      case 'done':
        return 'green'
      case 'error':
        return 'red'
      case 'uploading':
      default:
        return 'default'
    }
  })()

  return <SquaredIcon iconNode={<HiOutlineDocument size={DEFAULT_SIZE} />} color={color} />
}
export const FileUploadIcon = React.memo(_FileUploadIcon)

interface TemplateCardIconProps {
  icon: IconType
}
const _TemplateCardIcon = ({ icon }: TemplateCardIconProps): React.ReactElement => (
  <SquaredIcon iconNode={icon({ size: DEFAULT_SIZE })} />
)
export const TemplateCardIcon = React.memo(_TemplateCardIcon)
