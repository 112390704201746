import {
  CreateCbamFactorRequestResponseDto,
  CreateFactorRequestResponseDto,
  GetCbamTemplateDto,
  UpdateFactorRequestResponseDto,
} from '@cozero/dtos'
import { FactorRequestResponse } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'
import { TAG_ONE_FACTOR_REQUEST } from '../factors-requests'

import {
  CREATE_CBAM_FACTOR_REQUEST_RESPONSE,
  CREATE_GHG_FACTOR_REQUEST_RESPONSE,
  GET_CBAM_TEMPLATE_URL,
  GET_FACTOR_REQUEST_RESPONSE,
  UPDATE_ONE_FACTOR_REQUEST_RESPONSE,
} from './constants'

const factorsRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [CREATE_GHG_FACTOR_REQUEST_RESPONSE]: builder.mutation<
      FactorRequestResponse,
      CreateFactorRequestResponseDto
    >({
      query: (data) => ({
        url: logApiGatewayClient.factorRequestsResponses.CREATE,
        method: 'POST',
        data,
        params: {
          type: 'GHG',
        },
      }),
      invalidatesTags: (result) => [{ type: TAG_ONE_FACTOR_REQUEST, id: result?.factorRequestId }],
    }),

    [CREATE_CBAM_FACTOR_REQUEST_RESPONSE]: builder.mutation<
      FactorRequestResponse,
      CreateCbamFactorRequestResponseDto
    >({
      query: (data) => ({
        url: logApiGatewayClient.factorRequestsResponses.CREATE,
        method: 'POST',
        data,
        params: {
          type: 'CBAM',
        },
      }),
      invalidatesTags: (result) => [{ type: TAG_ONE_FACTOR_REQUEST, id: result?.factorRequestId }],
    }),

    [UPDATE_ONE_FACTOR_REQUEST_RESPONSE]: builder.mutation<
      FactorRequestResponse,
      { id: number; data: UpdateFactorRequestResponseDto }
    >({
      query: ({ id, data }) => ({
        url: logApiGatewayClient.factorRequestsResponses.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) => [{ type: TAG_ONE_FACTOR_REQUEST, id: result?.factorRequestId }],
    }),

    [GET_FACTOR_REQUEST_RESPONSE]: builder.query<FactorRequestResponse, number>({
      query: (userId) => ({
        url: centralApiGatewayClient.users.GET_ONE.replace(':id', `${userId}`),
        method: 'GET',
      }),
    }),

    [GET_CBAM_TEMPLATE_URL]: builder.query<string, GetCbamTemplateDto>({
      query: (params) => ({
        url: logApiGatewayClient.factorRequestsResponses.GET_CBAM_TEMPLATE_URL.replace(
          ':type',
          params.type,
        ),
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useCreateGhgFactorRequestResponseMutation,
  useCreateCbamFactorRequestResponseMutation,
  useUpdateOneFactorRequestResponseMutation,
  useGetFactorRequestResponseQuery,
  useLazyGetCbamTemplateUrlQuery,
  usePrefetch,
} = factorsRequestsApiSlice

export default factorsRequestsApiSlice
