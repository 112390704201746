import { ShapeAttrs, Types } from '@antv/g2'
import { Annotation, Datum } from '@antv/g2plot'

import { GraphInput } from '@/atoms/G2BarHorizontal'

import {
  CINDER_BLUE_40,
  CINDER_BLUE_50,
  CORAL_20,
  CORAL_60,
  CORAL_BASE,
  COZERO_BLUE_10,
  COZERO_BLUE_20,
  COZERO_BLUE_30,
  COZERO_BLUE_50,
  COZERO_BLUE_60,
  COZERO_BLUE_70,
  COZERO_BLUE_90,
  COZERO_BLUE_BASE,
  COZERO_GREEN_10,
  COZERO_GREEN_20,
  COZERO_GREEN_30,
  COZERO_GREEN_50,
  COZERO_GREEN_60,
  COZERO_GREEN_70,
  COZERO_GREEN_80,
  COZERO_ORANGE_10,
  COZERO_ORANGE_20,
  COZERO_ORANGE_30,
  COZERO_ORANGE_50,
  COZERO_ORANGE_60,
  COZERO_ORANGE_70,
  COZERO_ORANGE_80,
  COZERO_ORANGE_BASE,
  CYAN_20,
  CYAN_60,
  CYAN_BASE,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_SEMIBOLD,
  INDIGO_20,
  INDIGO_60,
  INDIGO_BASE,
  LAVENDER_20,
  LAVENDER_60,
  LAVENDER_BASE,
  OLIVE_20,
  OLIVE_60,
  OLIVE_BASE,
  PINK_20,
  PINK_60,
  PINK_BASE,
  PURPLE_20,
  PURPLE_60,
  PURPLE_BASE,
  SIZE_FONT_TEXT_MD,
  SIZE_FONT_TEXT_SM,
  SIZE_FONT_TEXT_XS,
  TEAL_20,
  TEAL_60,
  TEAL_BASE,
  YELLOW_20,
  YELLOW_60,
  YELLOW_BASE,
} from '@/styles/variables'
import { formatCompact } from '@/utils/number'

export const theme = {
  textColor: '#7b7d86',
  fontSize: 14,
  fontFamily: 'Inter',
  legends: {
    text: {
      color: '#7b7d86',
      fontSize: 14,
      fontFamily: 'Inter',
    },
  },
}

// See Gaia design scheme
export const COLORS = [
  // base colors
  COZERO_BLUE_BASE,
  COZERO_GREEN_70,
  COZERO_ORANGE_70,
  PURPLE_BASE,
  YELLOW_BASE,
  PINK_BASE,
  CYAN_BASE,
  CORAL_BASE,
  TEAL_BASE,
  LAVENDER_BASE,
  OLIVE_BASE,
  INDIGO_BASE,
  // 60 variants
  COZERO_BLUE_60,
  COZERO_GREEN_60,
  COZERO_ORANGE_60,
  PURPLE_60,
  YELLOW_60,
  PINK_60,
  CYAN_60,
  CORAL_60,
  TEAL_60,
  LAVENDER_60,
  OLIVE_60,
  INDIGO_60,
  // 20 variants
  COZERO_BLUE_20,
  COZERO_GREEN_20,
  COZERO_ORANGE_20,
  PURPLE_20,
  YELLOW_20,
  PINK_20,
  CYAN_20,
  CORAL_20,
  TEAL_20,
  LAVENDER_20,
  OLIVE_20,
  INDIGO_20,
]

const remToPx = (rem: string): number => {
  return Math.round(Number(rem) * 16)
}

export const axisTextStyle: ShapeAttrs = {
  fontSize: remToPx(SIZE_FONT_TEXT_XS),
  fontFamily: 'Inter',
  fontWeight: parseInt(FONT_WEIGHT_SEMIBOLD),
  fill: '#9D9FA9',
}

export const breadcrumbsTextStyle: ShapeAttrs = {
  ...axisTextStyle,
  fontSize: remToPx(SIZE_FONT_TEXT_SM),
}

export const legendStyles: Types.LegendCfg = {
  itemName: {
    formatter: (val) => val,
    style: {
      fill: CINDER_BLUE_50,
      fontSize: remToPx(SIZE_FONT_TEXT_MD),
      fontWeight: parseInt(FONT_WEIGHT_MEDIUM),
    },
  },
  marker: {
    symbol: 'circle',
  },
  layout: 'horizontal',
  position: 'top-left',
  label: {
    formatter: (val) => {
      if (!val) {
        return ''
      } else if (typeof val === 'number') {
        return val.toLocaleString()
      }
      return val
    },
  },
}

export const yAxisStyles: Types.AxisCfg = {
  grid: {
    line: {
      style: {
        lineWidth: 1,
        lineDash: [3, 3],
        opacity: 0.7,
      },
    },
  },
  title: {
    style: axisTextStyle,
  },
  label: {
    style: axisTextStyle,
    formatter(text) {
      if (!isNaN(Number(text))) {
        return formatCompact(Number(text))
      }
      return text
    },
  },
}

export const xAxisStyles: Types.AxisCfg = {
  label: {
    style: axisTextStyle,
  },
}

export const columnStyles: ShapeAttrs = {
  stroke: 'white',
  strokeOpacity: 1,
  lineWidth: 2,
  radius: 4,
}

export const padding = [30, 0, 0, 0]

export const scopeAttributes = [
  { name: 'Scope', scope: 1, color: COZERO_BLUE_BASE },
  { name: 'Scope', scope: 2, color: COZERO_GREEN_70 },
  { name: 'Scope', scope: 3, color: COZERO_ORANGE_70 },
  { name: 'Scope', scope: 'Scope 1', color: COZERO_BLUE_BASE },
  { name: 'Scope', scope: 'Scope 2', color: COZERO_GREEN_70 },
  { name: 'Scope', scope: 'Scope 3', color: COZERO_ORANGE_70 },
  { name: 'Scope', scope: 'Scope 1: Direct', color: COZERO_BLUE_BASE },
  { name: 'Scope', scope: 'Scope 2: Indirect', color: COZERO_GREEN_70 },
  { name: 'Scope', scope: 'Scope 3.1: Purchased goods and services', color: COZERO_ORANGE_20 },
  { name: 'Scope', scope: 'Scope 3.2: Capital goods', color: COZERO_ORANGE_70 },
  {
    name: 'Scope',
    scope: 'Scope 3.3: Fuel and energy-related activities',
    color: COZERO_ORANGE_80,
  },
  {
    name: 'Scope',
    scope: 'Scope 3.4: Upstream transportation and distribution',
    color: YELLOW_BASE,
  },
  { name: 'Scope', scope: 'Scope 3.5: Waste generated in operations', color: CORAL_BASE },
  { name: 'Scope', scope: 'Scope 3.6: Business travel', color: PINK_BASE },
  { name: 'Scope', scope: 'Scope 3.7: Employee commuting', color: COZERO_ORANGE_60 },
  { name: 'Scope', scope: 'Scope 3.8: Upstream leased assets', color: PINK_20 },
  {
    name: 'Scope',
    scope: 'Scope 3.9: Downstream transportation and distribution',
    color: PINK_60,
  },
  { name: 'Scope', scope: 'Scope 3.10: Processing of sold products', color: YELLOW_20 },
  { name: 'Scope', scope: 'Scope 3.11: Use of sold products', color: YELLOW_60 },
  { name: 'Scope', scope: 'Scope 3.12: End-of-life treatment of sold products', color: CORAL_20 },
  { name: 'Scope', scope: 'Scope 3.13: Downstream leased assets', color: CORAL_60 },
  { name: 'Scope', scope: 'Scope 3.14: Franchises', color: LAVENDER_BASE },
  { name: 'Scope', scope: 'Scope 3.15: Investments', color: LAVENDER_20 },
  { name: 'Scope', scope: 'Scope 1: Direkt', color: COZERO_BLUE_BASE },
  { name: 'Scope', scope: 'Scope 2: Indirekt', color: COZERO_GREEN_70 },
  {
    name: 'Scope',
    scope: 'Scope 3.1: Erworbene Güter und Dienstleistungen',
    color: COZERO_ORANGE_20,
  },
  { name: 'Scope', scope: 'Scope 3.2: Investitionsgüter', color: COZERO_ORANGE_70 },
  {
    name: 'Scope',
    scope: 'Scope 3.3: Auf Brennstoffe und Energie bezogene Aktivitäten',
    color: COZERO_ORANGE_80,
  },
  { name: 'Scope', scope: 'Scope 3.4: Upstream Transport und Distribution', color: YELLOW_BASE },
  {
    name: 'Scope',
    scope: 'Scope 3.5: Abfälle durch Aktivitäten des Unternehmens',
    color: CORAL_BASE,
  },
  { name: 'Scope', scope: 'Scope 3.6: Geschäftsreisen', color: PINK_BASE },
  { name: 'Scope', scope: 'Scope 3.7: Pendlerfahrten', color: COZERO_ORANGE_BASE },
  { name: 'Scope', scope: 'Scope 3.8: Upstream für geleaste Objekte', color: PINK_20 },
  {
    name: 'Scope',
    scope: 'Scope 3.9: Downstream Transport und Distribution',
    color: PINK_60,
  },
  { name: 'Scope', scope: 'Scope 3.10: Verarbeitung verkaufter Produkte', color: YELLOW_20 },
  { name: 'Scope', scope: 'Scope 3.11: Nutzung verkaufter Produkte', color: YELLOW_60 },
  {
    name: 'Scope',
    scope: 'Scope 3.12: Entsorgung/Verwertung verkaufter Produkte',
    color: CORAL_20,
  },
  { name: 'Scope', scope: 'Scope 3.13: Downstream für geleaste Objekte', color: CORAL_60 },
  { name: 'Scope', scope: 'Scope 3.14: Franchises', color: LAVENDER_BASE },
  { name: 'Scope', scope: 'Scope 3.15: Investitionen', color: LAVENDER_20 },
]

const getColor = (index: number): string => {
  return COLORS[index % COLORS.length]
}

const vodafoneColorFunction = (datum: Datum): string => {
  switch (datum.type) {
    case 'Traditional_TV':
      return TEAL_BASE
    case 'Traditional_Radio':
      return COZERO_GREEN_80
    case 'Digital_OOH':
      return COZERO_ORANGE_80
    case 'Social_display':
      return INDIGO_20
    case 'Social_video':
      return COZERO_BLUE_60
    case 'Video':
      return COZERO_BLUE_BASE
    case 'Billboard':
      return YELLOW_BASE
    case 'VOD':
      return OLIVE_BASE
    case 'Newspaper':
      return CORAL_BASE
    case 'Display':
      return INDIGO_BASE
    case 'Magazines':
      return LAVENDER_BASE
    case 'Onscreen':
      return CYAN_BASE
    default:
      return getColor(0)
  }
}

export const getScopeColor = (datum: Datum): string => {
  const colorMap = new Map([
    ['scope 1', [COZERO_BLUE_70, COZERO_BLUE_50, COZERO_BLUE_30]],
    ['scope 2', [COZERO_GREEN_60, COZERO_GREEN_50, COZERO_GREEN_30]],
    ['scope 3', [COZERO_ORANGE_70, COZERO_ORANGE_50, COZERO_ORANGE_30]],
  ])

  const scope = datum['ancestor-node'] ? datum['ancestor-node'] : datum.name
  const path = datum.path ? datum.path : scope
  const color = colorMap.get(scope.toLocaleLowerCase())
  const depth = path.split('/').length - 1
  const shadeIndex = depth > 2 ? 2 : depth

  if (color) {
    return color[shadeIndex]
  }

  return CINDER_BLUE_40
}

export const getEdgeColor = (
  datum: Datum,
): {
  stroke: string
  opacity: number
} => {
  const colorMap = new Map([
    ['scope 1', COZERO_BLUE_30],
    ['scope 2', COZERO_GREEN_30],
    ['scope 3', COZERO_ORANGE_30],
  ])

  const { name } = datum

  const color = colorMap.get(name.toLocaleLowerCase())

  return {
    stroke: color ? color : COZERO_BLUE_90,
    opacity: 1,
  }
}

export const getScopeColorFromTitle = (datum: Datum): string => {
  const pattern = /Scope (\d+)/
  const match = datum.type.match(pattern)
  switch (match?.[1]) {
    case '1':
      return COZERO_BLUE_70
    case '2':
      return COZERO_GREEN_60
    default:
      return COZERO_ORANGE_80
  }
}

export const getScopeColorObjFromTitle = (datum: Datum): { background: string; text: string } => {
  const pattern = /Scope (\d+)/
  const match = datum.type.match(pattern)
  switch (match?.[1]) {
    case '1':
      return {
        background: COZERO_BLUE_10,
        text: COZERO_BLUE_70,
      }
    case '2':
      return {
        background: COZERO_GREEN_10,
        text: COZERO_GREEN_70,
      }
    default:
      return {
        background: COZERO_ORANGE_10,
        text: COZERO_ORANGE_70,
      }
  }
}

export const reportKeyToColorFunction = new Map<string, (datum: Datum) => string>([
  ['total-spend-per-channel', vodafoneColorFunction],
  ['impressions-per-channel', vodafoneColorFunction],
  ['emissions-per-spent-by-channel', vodafoneColorFunction],
  ['emissions-per-impression-per-channel', vodafoneColorFunction],
  ['emissions-per-media-channel', vodafoneColorFunction],
  ['emissions-per-location-media-channel', vodafoneColorFunction],
  ['emissions-spent-tag-print', vodafoneColorFunction],
  ['emissions-spent-tag-audio-visual', vodafoneColorFunction],
  ['emissions-spent-tag-ooh', vodafoneColorFunction],
  ['emission-top6-ghg', getScopeColorFromTitle],
])

export const getOriginalScopeTitle = (text: string): string => {
  // Remove "Scope X.X: " or "Scope X: " from the title. Despite the locale we always have the Scope in english
  return text.replace(/^Scope \d+(\.\d+)?:\s*/, '')
}

export const reportKeyToParseLabelFunction = new Map<string, (text: string) => string>([
  ['emission-top6-ghg', getOriginalScopeTitle],
])

export const generateHorizontalPercentageAnnotation = (
  data: GraphInput[],
  totalEmissions: number,
): Annotation[] => {
  const paddingTopBottom = 3
  const paddingLeftRight = 7
  return data.map((item, index) => {
    const { background, text } = getScopeColorObjFromTitle(item)
    return {
      type: 'text',
      content: `${((item.value / totalEmissions) * 100).toFixed(2)}%`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      position: (xScale: any, yScale: any) => {
        const unit = yScale.value.ticks[1] / 10
        return [index, item.value + unit + unit / 2]
      },
      style: {
        fill: text,
      },
      background: {
        padding: [paddingTopBottom, paddingLeftRight, paddingTopBottom, paddingLeftRight],
        style: {
          radius: 10,
          fill: background,
        },
      },
    }
  })
}

export const generateVerticalPercentageAnnotation = (
  data: GraphInput[],
  totalEmissions: number,
): Annotation[] => {
  const paddingTopBottom = 3
  const paddingLeftRight = 7
  return data.map((item, index) => {
    const { background, text } = getScopeColorObjFromTitle(item)
    const percentage = ((item.value / totalEmissions) * 100).toFixed(2)
    return {
      type: 'text',
      content: `${percentage}%`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      position: (xScale: any, yScale: any) => {
        const unit = yScale.value.ticks[1] / 10
        const numberWidth = 0.02
        // Example 10.24 -> 5 digits
        const is2DigitsPercentage = percentage.length === 5
        return [index - numberWidth * (is2DigitsPercentage ? 1 : 0), item.value + unit + unit]
      },
      style: {
        fill: text,
      },
      offsetX: -18,
      background: {
        padding: [paddingTopBottom, paddingLeftRight, paddingTopBottom, paddingLeftRight],
        style: {
          radius: 10,
          fill: background,
        },
      },
    }
  })
}

export const reportKeyToAnnotationsFunction = new Map<
  string,
  (data: GraphInput[], totalEmissions: number) => Annotation[]
>([
  ['emission-top6-ghg', generateHorizontalPercentageAnnotation],
  ['carbon-emissions-breakdown-by-scope', generateVerticalPercentageAnnotation],
])
