import { ViteImportMeta } from '../types/vite'

const envVar = (import.meta as ViteImportMeta).env

const env = {
  API_URL: envVar.VITE_API_URL,
  NODE_ENV: envVar.VITE_NODE_ENV,
  MIXPANEL_ID: envVar.VITE_MIXPANEL_ID,
  HUBSPOT_FORM_KEY: envVar.VITE_HUBSPOT_FORM_KEY,
  HUBSPOT_FORM_PORTAL_ID: envVar.VITE_HUBSPOT_FORM_PORTAL_ID,
  COMMIT_HASH: envVar.VITE_VERCEL_GIT_COMMIT_SHA,
  SENTRY_DSN: envVar.VITE_SENTRY_DSN,
}

export const languages = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'Deutsch' },
  { id: 'ja', name: 'Japanese / 日本語' },
]

export const config = {
  ...env,
  languages,
} as const

export const formatColumns = {
  number: [
    'CompanyCalculationsDbt.carbonValue',
    'CompanyCalculationsDbt.carbonPerEmployee',
    'CompanyCalculationsDbt.consumptionValue',
    'ProductEmissionsDbt.carbonValue',
    'ProductEmissionsDbt.carbonValueInG',
    'ProductQuantitiesDbt.volume',
    'ProductEmissionsQuantitiesDbt.carbonValueVolume',
  ],
  date_month: [
    'Quantities.startDate.month',
    'Quantities.endDate.month',
    'CompanyCalculationsDbt.timestamp.month',
    'ProductQuantitiesDbt.timestamp.month',
    'ProductEmissionsQuantitiesDbt.timestamp.month',
    'CarbonIntensities.timestamp.month',
  ],
  date_year: [
    'Quantities.startDate.year',
    'Quantities.endDate.month',
    'CompanyCalculationsDbt.timestamp.year',
    'ProductQuantitiesDbt.timestamp.year',
    'ProductEmissionsQuantitiesDbt.timestamp.year',
    'CarbonIntensities.timestamp.year',
  ],
  date_quarter: [
    'CompanyCalculationsDbt.timestamp.quarter',
    'ProductQuantitiesDbt.timestamp.quarter',
    'ProductEmissionsQuantitiesDbt.timestamp.quarter',
    'CarbonIntensities.timestamp.quarter',
  ],
}

export const tabKeys = {
  '': '1',
  'organization-products': '2',
  'organization-business-units': '2',
  'organization-locations': '2',
  'log-locations': '3',
  'log-products': '4',
  'log-reports': '5',
  'log-factors': '18',
  customers: '6',
  suppliers: '7',
  act: '8',
  'share-share-pages': '9',
  'share-stories': '10',
  'share-certifications': '11',
  'share-sustainability-reports': '12',
  settings: '14',
  'settings-organization': '14',
  'settings-plans': '14',
  'settings-subscription': '14',
  'settings-api': '14',
  'settings-data': '14',
  'settings-function': '14',
} as const

export const menuKeys = {
  '1': '',
  '2': 'sub1',
  '3': 'sub1',
  '4': 'sub1',
  '5': 'sub1',
  '6': 'sub1',
  '7': 'sub1',
  '18': 'sub1',
  '8': '',
  '9': 'sub2',
  '10': 'sub2',
  '11': 'sub2',
  '12': 'sub2',
  '13': '',
  '14': '',
} as const

export const supportUrl = 'https://cozero.io/wiki/reach-out-to-our-support/'

export const dateFormat = 'lll'

export const maxUploadBytes = 50000000
