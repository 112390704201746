import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight, HiInformationCircle } from 'react-icons/hi'

import { Col, Row } from 'antd/es'
import { ColumnsType } from 'antd/es/table'

import moment from 'moment'

import { GroupedMethodologyChangelog } from '@cozero/models'
import { CalculationEntryType } from '@cozero/services'

import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { CINDER_BLUE_80, COZERO_BLUE_80 } from '@/styles/variables'
import { formatNumber } from '@/utils/number'
import { formatChangePercentage } from '@/utils/recalculations'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

const DEFAULT_KEY_COLUMNS = [
  'count',
  'category',
  'subcategory',
  'reason',
  'year',
  'organization-impacted-logs',
  'product-impacted-logs',
  'notification-date',
  'recalculation-date',
  'view-details',
] as const

const ALL_COLUMNS_KEYS = [...DEFAULT_KEY_COLUMNS, 'implemented-dates', 'implemented-date'] as const

export type MethodologyChangesTableColumnKeys = (typeof ALL_COLUMNS_KEYS)[number]

type UseChangelogsTableColumnsProps = {
  methodologyChangelogTotal: number
  shownColumns?: MethodologyChangesTableColumnKeys[]
}

type UseChangelogsTableColumnsHook = {
  methodologyChangelogTotal: number
  columns: ColumnsType<GroupedMethodologyChangelog>
}

const calculateChangelogEmissionsChange = (
  record: GroupedMethodologyChangelog,
  type: CalculationEntryType = 'organization',
): {
  oldCarbonFootprint: number
  carbonFootprint: number
  changePercentage: number
} => {
  const oldCarbonFootprint =
    type === 'product' ? record.oldProductCarbonFootprint : record.oldLogCarbonFootprint
  const carbonFootprint =
    type === 'product' ? record.productCarbonFootprint : record.logCarbonFootprint

  let changePercentage = ((carbonFootprint - oldCarbonFootprint) / oldCarbonFootprint) * 100
  changePercentage = !isNaN(changePercentage) ? changePercentage : 0

  return { changePercentage, oldCarbonFootprint, carbonFootprint }
}

const generateColumns = (
  allColumns: ColumnsType<GroupedMethodologyChangelog>,
  shownColumnsKeys?: MethodologyChangesTableColumnKeys[],
): ColumnsType<GroupedMethodologyChangelog> => {
  if (!shownColumnsKeys || shownColumnsKeys.length === 0) {
    return allColumns.filter(({ key }) => DEFAULT_KEY_COLUMNS.includes(key as never))
  }

  return allColumns.filter(({ key }) =>
    shownColumnsKeys.includes(key as MethodologyChangesTableColumnKeys),
  )
}

export const useChangelogsTableColumns = ({
  methodologyChangelogTotal,
  shownColumns,
}: UseChangelogsTableColumnsProps): UseChangelogsTableColumnsHook => {
  const { t } = useTranslation('common')

  const allColumns: ColumnsType<GroupedMethodologyChangelog> = [
    {
      key: 'category',
      title: t('calculation-changes.columns.category'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <Row align="middle">
            <Col span={5}>
              <img
                src={record.subcategory?.category?.image?.url || ''}
                alt={record.subcategory.category.name?.toString() || ''}
              />
            </Col>
            <Col span={19}>{record.subcategory.category.name?.toString() || ''}</Col>
          </Row>
        )
      },
      width: 250,
    },
    {
      key: 'subcategory',
      title: t('calculation-changes.columns.subcategory'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <span>
            <Text size="xl">{record.subcategory?.name?.toString()}</Text>
          </span>
        )
      },
    },
    {
      width: 200,
      key: 'reason',
      title: t('calculation-changes.columns.reason'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <Row>
            <Tooltip
              overlayClassName={classes.tooltip}
              color={CINDER_BLUE_80}
              title={record.methodologyChangelogGlobal.changeReasonDescription?.toString()}
            >
              <Text size="xl">
                {truncate(
                  record.methodologyChangelogGlobal.changeReasonDescription?.toString() ?? '',
                  50,
                )}
              </Text>
            </Tooltip>
          </Row>
        )
      },
    },
    {
      title: t('calculation-changes.columns.year'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <Text size="xl">
            {record.affectedYears.length ? (
              record.affectedYears.reduce((acc, value) => `${acc}, ${value}`, '').slice(1)
            ) : (
              <span>
                {' '}
                -{' '}
                <Tooltip
                  overlayClassName={classes.tooltip}
                  color={CINDER_BLUE_80}
                  title={t('calculation-changes.columns.tooltip-affected-years')}
                >
                  <HiInformationCircle className={classes.icon} size={14} color={COZERO_BLUE_80} />
                </Tooltip>
              </span>
            )}
          </Text>
        )
      },
    },
    {
      key: 'organization-impacted-logs',
      title: t('calculation-changes.columns.organization-emissions'),
      width: 300,
      render(text: string, record: GroupedMethodologyChangelog) {
        const { changePercentage, oldCarbonFootprint, carbonFootprint } =
          calculateChangelogEmissionsChange(record, 'organization')

        return (
          <Row align="middle" gutter={16} justify={'space-between'}>
            <Col span={14}>
              <Text>
                <span className={classes.striked}>{formatNumber(oldCarbonFootprint)}</span>{' '}
                <HiArrowRight />
                {formatNumber(carbonFootprint)}
              </Text>
            </Col>
            <Col span={10}>
              <Row align={'middle'}>
                <Pill size="xs" color={changePercentage <= 0 ? 'green' : 'orange'}>
                  <Text fontWeight="medium">
                    {changePercentage < 0 ? '-' : changePercentage > 0 ? '+' : ''}
                    {formatChangePercentage({
                      changePercentage,
                      affectedLogCount: record.affectedLogCount,
                      affectedProductCount: record.affectedProductCount,
                    })}
                  </Text>
                </Pill>
              </Row>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 'product-impacted-logs',
      title: t('calculation-changes.columns.product-emissions'),
      width: 300,
      render(text: string, record: GroupedMethodologyChangelog) {
        const { changePercentage, oldCarbonFootprint, carbonFootprint } =
          calculateChangelogEmissionsChange(record, 'product')
        return (
          <Row align="middle" gutter={16} justify={'space-between'}>
            <Col span={14}>
              <Text>
                <span className={classes.striked}>{formatNumber(oldCarbonFootprint)}</span>{' '}
                <HiArrowRight />
                {formatNumber(carbonFootprint)}
              </Text>
            </Col>
            <Col span={10}>
              <Row align={'middle'}>
                <Pill size="xs" color={changePercentage <= 0 ? 'green' : 'orange'}>
                  <Text fontWeight="medium">
                    {changePercentage < 0 ? '-' : changePercentage > 0 ? '+' : ''}
                    {changePercentage === 0
                      ? '---'
                      : `${formatNumber(Math.abs(changePercentage))}%`}
                  </Text>
                </Pill>
              </Row>
            </Col>
          </Row>
        )
      },
    },
    {
      fixed: 'right',
      key: 'implemented-dates',
      title: (
        <Row gutter={8}>
          <Col>{t('calculation-changes.columns.implementation-date')}</Col>
          <Col>→</Col>
          <Col>{t('calculation-changes.columns.recalculation-date')}</Col>
        </Row>
      ),
      render(text: string, record: GroupedMethodologyChangelog) {
        const format = `DD MMM YYYY`
        const implementation = moment(record.createdAt).format(format)
        const recalculation = record.recalculationStartDate
          ? moment(record.recalculationStartDate).format(format)
          : '-'
        return (
          <Row align="middle" gutter={8}>
            <Col>{implementation}</Col>
            <Col> → </Col>
            <Col>{recalculation}</Col>
          </Row>
        )
      },
    },
    {
      key: 'implemented-date',
      title: (
        <Row gutter={8}>
          <Col>{t('calculation-changes.columns.implementation')}</Col>
        </Row>
      ),
      render(text: string, record: GroupedMethodologyChangelog) {
        const format = `D MMM YYYY`
        const implementation = moment(record.createdAt).format(format)
        return (
          <Row align="middle" gutter={8}>
            <Col>{implementation}</Col>
          </Row>
        )
      },
    },
    {
      key: 'notification-date',
      title: (
        <Row gutter={8}>
          <Col>{t('calculation-changes.columns.notification-date')}</Col>
        </Row>
      ),
      render(text: string, record: GroupedMethodologyChangelog) {
        const format = `D MMM YYYY`
        const implementation = moment(record.createdAt).format(format)
        return (
          <Row align="middle" gutter={8}>
            <Col>{implementation}</Col>
          </Row>
        )
      },
    },
    {
      fixed: 'right',
      key: 'recalculation-date',
      title: (
        <Row gutter={8}>
          <Col>{t('calculation-changes.columns.recalculation-date')}</Col>
        </Row>
      ),
      render(text: string, record: GroupedMethodologyChangelog) {
        const format = `D MMM YYYY`
        const recalculation = moment(record.recalculationStartDate).format(format)
        return (
          <Row align="middle" gutter={8}>
            <Col>{recalculation}</Col>
          </Row>
        )
      },
    },
    {
      fixed: 'right',
      key: 'view-details',
      title: t('calculation-changes.columns.action'),
      render() {
        return (
          <Button
            action="view-calculation-details"
            category={AnalyticsCategories.CALCULATION_CHANGES}
            type="secondary"
          >
            {t('calculation-changes.columns.view-details')}
          </Button>
        )
      },
    },
  ]

  return { methodologyChangelogTotal, columns: generateColumns(allColumns, shownColumns) }
}
