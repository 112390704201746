import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { Store, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import { REDUX_STORAGE_KEY } from '@/constants/localStorage'
import actActionSimulatorSlice from '@/redux/actAnalytics/actionSimulator/slice'
import organigramSlice from '@/redux/organigram/slice'

import actAnalytics from './actAnalytics/slice'
// defaults to localStorage for web
import actGraphsSlice from './actGraphs'
import apiSlice from './api'
import authSlice from './auth'
import basketSlice from './basket'
import businessUnitSlice from './businessUnits'
import climateActionsSlice from './climateActions'
import factorRequestSlice from './factors-requests'
import factorRequestResponseSlice from './factors-requests-responses'
import filterSlice from './filters'
import logEntriesOverviewSlice from './logEntriesOverview/slice'
import methodlogyChangelogSlice from './methodologyChangelogs'
import onboardingSlice from './onboarding'
import strategyBuilder from './strategyBuilder/slice'
import targetsSlice from './targets'
import tasksSlice from './tasks/slice'

const rootReducer = combineReducers({
  [actGraphsSlice.name]: actGraphsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [basketSlice.name]: basketSlice.reducer,
  [businessUnitSlice.name]: businessUnitSlice.reducer,
  [targetsSlice.name]: targetsSlice.reducer,
  [climateActionsSlice.name]: climateActionsSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [factorRequestSlice.name]: factorRequestSlice.reducer,
  [methodlogyChangelogSlice.name]: methodlogyChangelogSlice.reducer,
  [factorRequestResponseSlice.name]: factorRequestResponseSlice.reducer,
  [strategyBuilder.name]: strategyBuilder.reducer,
  [actAnalytics.name]: actAnalytics.reducer,
  [organigramSlice.name]: organigramSlice.reducer,
  [actActionSimulatorSlice.name]: actActionSimulatorSlice.reducer,
  [logEntriesOverviewSlice.name]: persistReducer(
    {
      key: logEntriesOverviewSlice.name,
      storage,
      blacklist: ['selectedRows'],
    },
    logEntriesOverviewSlice.reducer,
  ),
  [tasksSlice.name]: persistReducer(
    {
      key: tasksSlice.name,
      storage,
    },
    tasksSlice.reducer,
  ),
})

// Configure the persistence of our reducers
const _persistedReducer = persistReducer(
  {
    key: REDUX_STORAGE_KEY,
    keyPrefix: '',
    storage,
    whitelist: [
      actGraphsSlice.name,
      businessUnitSlice.name,
      authSlice.name,
      climateActionsSlice.name,
      onboardingSlice.name,
      targetsSlice.name,
      strategyBuilder.name,
      tasksSlice.name,
    ],
  },
  rootReducer,
)

// Configure our store
const store: Store<RootState> = configureStore({
  // Pass our reducer object
  reducer: _persistedReducer,
  // Add all the regular middleware needed for our store, as
  // Thunk, Immutability check middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // As we are passing around some pretty big objects,
      // which will make this check take >30ms, redux will throw a warning
      // It catches non-serializable values, and is not turned on in production
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})

// A type  store
type AppDispatch = typeof store.dispatch
// Declare the RootState` types from the store itself
type RootState = ReturnType<typeof rootReducer>

// A typesafe version of `useDispatch`
const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

// A typesafe version of useSelector
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const persistedStore = persistStore(store)

export type { AppDispatch, RootState }
export { persistedStore, useAppSelector, useAppDispatch }
export default store
