import moment, { Moment } from 'moment'

import { dateFormat } from './config'

interface DateRange {
  startDate: Date
  endDate: Date
}

export function isWithinDateRange<T extends DateRange>(
  array: T[],
  dateRange: [Moment | null, Moment | null],
): T[] {
  const mStartDate = moment(dateRange[0])
  const mEndDate = moment(dateRange[1])
  return array.filter((obj) => {
    let valid: boolean
    const objStartDate = moment(obj.startDate)
    if (obj.endDate) {
      const objEndDate = moment(obj.endDate)
      valid = mEndDate.diff(objStartDate) >= 0 && mStartDate.diff(objEndDate) <= 0
    } else {
      valid = mStartDate.diff(objStartDate) <= 0 && mEndDate.diff(objStartDate) >= 0
    }
    return valid
  })
}

export const prettifyDate = (
  date: Date | string,
  format?: string,
  { useLocalTime }: { useLocalTime?: boolean } = { useLocalTime: true },
): string => {
  return useLocalTime
    ? moment(date)
        .local()
        .format(format ?? dateFormat ?? 'll')
    : moment(date)
        .utc()
        .format(format ?? dateFormat ?? 'll')
}

/**
 * Returns an array of dates between the start and end date
 * @param difference - how many days between the start and end date
 * @returns an array of dates
 */
export const generateListOfYears = (difference = 10): number[] => {
  const currentYear = moment().year()
  const startYear = moment(currentYear, 'YYYY').subtract(difference, 'years').year()
  const years = []
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i)
  }
  return years
}

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Month Year'
 * @example
 * formatDate('2021-01-01') // 'January 2021'
 **/
export const formatDateToMonthYear = (date: string): string => {
  return new Intl.DateTimeFormat('en', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(date))
}

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Year'
 * @example
 * formatDate('2021-01-01') // '2021'
 */
export const formatDateToYear = (date: Date): string => moment(date).utc().format('YYYY')

/**
 * Format a date string to a human readable format
 * @param date - The date string to format
 * @returns The formatted date string in the format 'Short Month Year'
 * @example
 * formatDate('2021-01-01') // 'Jan 2021'
 */
export const formatDateToShortMonthYear = (date: Date): string =>
  moment(date).utc().format('MMM YYYY').replace('.', '')

/**
 * Calculate the start and end date of a given year
 * @param date - The date containing the year that we calculate upon.
 * @returns The start and end date of the given year as Moment objects
 * @example
 * getYearStartEnd(2023) // the equivelant of [moment('2023/01/01', 'YYYY/MM/DD'), moment('2023/12/01', 'YYYY/MM/DD')
 */
export const getYearStartEnd = function (date: Date | string | null): [Moment, Moment] | null {
  if (date === null || date === undefined) {
    return null
  }

  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null
  }

  const year: number = date.getFullYear()

  const startOfYear: Moment = moment().year(year).startOf('year')
  const endOfYear: Moment = moment().year(year).endOf('year')

  return [startOfYear, endOfYear]
}

/**
 *
 * @param moments
 * @returns
 */
export const disabledDates = (
  disabledRange: [Moment, Moment] | null,
): ((current: Moment) => boolean) => {
  if (!disabledRange) {
    return () => false
  }

  const [startMoment, endMoment] = disabledRange

  return (current: Moment): boolean => {
    return !current.isBetween(startMoment, endMoment, 'day', '[]')
  }
}

/**
 * Get the time ago in a short format
 * @param date - The date to get the time ago for
 * @returns The time ago in the format '1h' or '3m' or '20s' or 'Jan 2' or '01/02/2021'
 */
export const getTimeAgoShort = (date: Date | string | Moment): string => {
  const mDate = moment(date)
  const now = moment()
  const diffDays = now.diff(mDate, 'days')
  const diffHours = now.diff(mDate, 'hours')
  const diffMinutes = now.diff(mDate, 'minutes')
  const isLastYear = mDate.year() !== now.year()
  if (diffMinutes < 1) {
    return now.diff(mDate, 'seconds') + 's'
  }
  if (diffHours < 1) {
    return now.diff(mDate, 'minutes') + 'm'
  }
  if (diffDays < 1) {
    return now.diff(mDate, 'hours') + 'h'
  }
  if (isLastYear) {
    return mDate.format('l')
  }
  return mDate.format('MMM D')
}
