import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd'
import { Tooltip } from 'antd/es'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'

import moment from 'moment'

import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import { useAppSelector } from '@/redux'
import { selectLocale } from '@/redux/auth'
import { CINDER_BLUE_80 } from '@/styles/variables'
import { formatNumber } from '@/utils/number'
import { truncate } from '@/utils/string'

import useImpactedLogs, { ILogTableContent } from '../../hooks/useImpactedLogs'

import classes from './classes.module.less'

const OrganisationImpactedLogsTable = (): ReactElement => {
  const {
    totalLogs,
    mappedLogs,
    setLogPage,
    setLogsPageSize,
    logsPage,
    logsPageSize,
    loadingLogs,
  } = useImpactedLogs()
  const { t } = useTranslation()
  const locale = useAppSelector(selectLocale)
  const navigate = useNavigate()

  const changeTable = useCallback(
    (pagination: TablePaginationConfig): void => {
      if (pagination.current && pagination.current !== logsPage) {
        setLogPage(pagination.current)
      }
    },
    [logsPage, setLogPage],
  )

  const navigateToLog = useCallback(
    (row: ILogTableContent) => {
      navigate(routes.log.corporateFootprint.edit.replace(':id', row.id.toString()))
    },
    [navigate],
  )

  const columns: ColumnsType<ILogTableContent> = useMemo(
    () => [
      {
        key: 'category',
        title: t('calculation-changes.changelog-columns.category'),
        render(text: string, record: ILogTableContent) {
          return <span>{record.categoryName}</span>
        },
      },
      {
        key: 'activityDataSources',
        title: t('calculation-changes.columns.activityDataSources'),
        render(text: string, record: ILogTableContent) {
          const adsString = record.activityDataSources.join(', ')
          return (
            <Tooltip overlayClassName={classes.tooltip} color={CINDER_BLUE_80} title={adsString}>
              <Text size="xl">{truncate(adsString ?? '---', 50)}</Text>
            </Tooltip>
          )
        },
      },
      {
        key: 'description',
        title: t('calculation-changes.changelog-columns.description'),
        render(text: string, record: ILogTableContent) {
          return <span>{record.description}</span>
        },
      },
      {
        key: 'location',
        title: t('calculation-changes.changelog-columns.location'),
        render(text: string, record: ILogTableContent) {
          return record.locationName ? <Tag>{record.locationName}</Tag> : null
        },
      },
      {
        key: 'business-unit',
        title: t('calculation-changes.changelog-columns.business-unit'),
        render(text: string, record: ILogTableContent) {
          return record.businessUnitName ? <Tag>{record.businessUnitName}</Tag> : null
        },
      },
      {
        key: 'period',
        title: t('calculation-changes.changelog-columns.period'),
        render(text: string, record: ILogTableContent) {
          return (
            <Row gutter={8} align="middle">
              <Col>
                <Tag>
                  {moment(record.startDate)
                    .locale(locale ?? 'en')
                    .format('ll')}
                </Tag>
              </Col>
              <Col>to</Col>
              <Col>
                <Tag className={classes.tagSpacing}>
                  {moment(record.endDate)
                    .locale(locale ?? 'en')
                    .format('ll')}
                </Tag>
              </Col>
            </Row>
          )
        },
      },
      {
        key: 'emissions',
        title: `${t('calculation-changes.changelog-columns.emissions')} (${t('co2-tonnes')})`,
        fixed: 'right',
        sorter: (a, b) => (a.affectedEmissionSum as number) - (b.affectedEmissionSum as number),
        render(text: string, record: ILogTableContent) {
          return (
            <Text color="blue" size="lg">
              {record?.affectedEmissionSum ? formatNumber(record.affectedEmissionSum) : 0}
            </Text>
          )
        },
      },
    ],
    [mappedLogs],
  )
  if (!totalLogs && !loadingLogs) {
    return <></>
  }
  return (
    <Row className={classes.tableWrapper}>
      <Text className={classes.tableHeader} fontWeight="medium" size="lg" color="secondary">
        {t('calculation-changes.impact-modal.organisation-logs-title')}
      </Text>
      <Col span={24}>
        <Table
          dataSource={mappedLogs}
          loading={loadingLogs}
          rowKey="id"
          columns={columns}
          scroll={{ x: 'max-content' }}
          showWrapper={false}
          rowClassName={classes.tableRow}
          onRow={(row) => ({
            onClick: () => navigateToLog(row),
          })}
          onChange={changeTable}
          pagination={{
            current: logsPage,
            defaultPageSize: logsPageSize,
            pageSize: logsPageSize,
            total: totalLogs,
            onShowSizeChange: (_current, size) => {
              setLogsPageSize(size)
            },
            pageSizeOptions: [5],
          }}
        />
      </Col>
    </Row>
  )
}

export default OrganisationImpactedLogsTable
