import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineCube, HiOutlineGlobeAlt } from 'react-icons/hi'

import { FactorRequestDetailsView } from '@cozero/models'

import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import classes from './FactorRequestReviewDescription.module.less'

interface Props {
  factorRequest: FactorRequestDetailsView
}

const _FactorRequestReviewDescription = ({ factorRequest }: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <div className={classes.requestDecriptionContainer}>
      <div className={classes.descriptionSection}>
        <Text className={classes.labels} size="xl" fontWeight="medium">
          {t('customer-requests.table.product-name')}
        </Text>
        <Text className={classes.labels} size="xl" fontWeight="medium">
          {t('onboarding.suppliers.steps.request.fields.type-of-request')}
        </Text>
      </div>

      <div className={classes.descriptionSection}>
        <Tag
          className={classes.tags}
          icon={<HiOutlineCube size={16} className={classes.rowIcon} />}
        >
          {factorRequest.activityDataSource.name as string}
        </Tag>
        <Tag
          className={classes.tags}
          icon={<HiOutlineGlobeAlt size={16} className={classes.rowIcon} />}
        >
          {factorRequest.type}
        </Tag>
      </div>
    </div>
  )
}

export const FactorRequestReviewDescription = React.memo(_FactorRequestReviewDescription)
