import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { message } from 'antd'
import { RcFile } from 'antd/es/upload'
import Upload, { UploadChangeParam } from 'antd/lib/upload'

import { CbamTemplateType } from '@prisma/client'
import saveAs from 'file-saver'

import { GetCbamTemplateDto } from '@cozero/dtos'
import { FactorRequestDetailsView } from '@cozero/models'
import { routes } from '@cozero/utils'

import {
  useCreateCbamFactorRequestResponseMutation,
  useLazyGetCbamTemplateUrlQuery,
  useUpdateOneFactorRequestResponseMutation,
} from '@/redux/factors-requests-responses'

import { CustomUploadFile } from './components/FileUploader/FileUploader'

interface UseCbamTemplatesArgs {
  factorRequest: FactorRequestDetailsView
}

interface UseCbamTemplatesReturn {
  selectedFile: CustomUploadFile | null
  validationError: boolean
  FILE_LIMIT_IN_MB: number
  handleBeforeUpload: (file: RcFile) => Promise<boolean | string>
  handleSave: (cbamTemplateType: CbamTemplateType) => Promise<void>
  handleFileChange: (filesList: UploadChangeParam<CustomUploadFile>) => void
  handleDeleteFile: () => void
  downloadTemplate: (params: GetCbamTemplateDto) => Promise<void>
}

const FILE_LIMIT_IN_MB = 25

export const useCbamTemplates = ({
  factorRequest,
}: UseCbamTemplatesArgs): UseCbamTemplatesReturn => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [createFactorRequestResponse] = useCreateCbamFactorRequestResponseMutation()
  const [updateFactorRequestResponse] = useUpdateOneFactorRequestResponseMutation()
  const [getCbamTemplate] = useLazyGetCbamTemplateUrlQuery()

  const initialTemplate = factorRequest.factorRequestResponse?.cbamTemplate
  const [selectedFile, setSelectedFile] = useState<CustomUploadFile | null>(
    initialTemplate
      ? {
          uid: initialTemplate.id.toString(),
          name: initialTemplate.name ?? '',
          status: 'done',
          percent: 100,
          url: initialTemplate.url ?? '',
          response: {
            path: initialTemplate.path ?? '',
            name: initialTemplate.name ?? '',
            signedUrl: initialTemplate.url ?? '',
          },
        }
      : null,
  )

  // we should replace this with proper error codes
  // once we start validating the files
  const [validationError, setValidationError] = useState(false)

  const handleBeforeUpload: UseCbamTemplatesReturn['handleBeforeUpload'] = async (file) => {
    const isLt5m = file.size / 1024 / 1024 < FILE_LIMIT_IN_MB
    if (!isLt5m) {
      setValidationError(true)
      message.error(
        t('share.supplier-engagement-cbam.file-uploader.errors.validation.file-limit', {
          FILE_LIMIT_IN_MB,
        }),
      )
    }

    return isLt5m || Upload.LIST_IGNORE
  }

  const handleSave: UseCbamTemplatesReturn['handleSave'] = async (cbamTemplateType) => {
    if (!selectedFile?.response?.path || selectedFile.error) {
      message.error(t('share.supplier-engagement-cbam.file-uploader.errors.validation.no-file'))
      setValidationError(true)
      return
    }

    try {
      if (!factorRequest?.factorRequestResponse) {
        await createFactorRequestResponse({
          factorRequestId: factorRequest.id,
          cbamTemplate: {
            name: selectedFile.name,
            path: selectedFile.response.path,
          },
          cbamTemplateType,
        }).unwrap()
      } else {
        await updateFactorRequestResponse({
          id: factorRequest.factorRequestResponse.id,
          data: {
            cbamTemplate: {
              name: selectedFile.name,
              path: selectedFile.response.path,
            },
            cbamTemplateType,
          },
        }).unwrap()
      }
      navigate(routes.log.factors.requestsPage.details.replace(':id', factorRequest.id.toString()))
    } catch (error) {
      message.error(t('share.supplier-engagement-cbam.cbam-form.errors.submit'))
    }
  }

  const downloadTemplate: UseCbamTemplatesReturn['downloadTemplate'] = async (params) => {
    try {
      const templateUrl = await getCbamTemplate(params).unwrap()
      saveAs(templateUrl)
    } catch (error) {
      message.error(t('share.supplier-engagement-cbam.cbam-form.errors.template-download'))
    }
  }

  const handleFileChange: UseCbamTemplatesReturn['handleFileChange'] = ({ file }) => {
    setSelectedFile(file)
    setValidationError(false)
  }

  const handleDeleteFile: UseCbamTemplatesReturn['handleDeleteFile'] = () => setSelectedFile(null)

  return {
    selectedFile,
    validationError,
    FILE_LIMIT_IN_MB,
    downloadTemplate,
    handleBeforeUpload,
    handleSave,
    handleFileChange,
    handleDeleteFile,
  }
}
