/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

import { range } from 'lodash-es'

import { LogEntriesTableColumnKey } from '@cozero/models'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { usePricingFeature } from '@/hooks/usePricingFeature'

import { ColumnManagerModal } from '../components/ColumnManagerModal/ColumnManagerModal'

import { TableColumns, useTableColumns } from './useTableColumns'

type ManagedColumns = {
  visibleColumns: LogEntriesTableColumnKey[]
  hiddenColumns: LogEntriesTableColumnKey[]
}

const getPersistedVisibleKeys = (): ManagedColumns['visibleColumns'] => {
  const rawItem = localStorage.getItem('log-entries-visible-column-keys')
  if (!rawItem) {
    return []
  }

  try {
    return JSON.parse(rawItem)
  } catch (error) {
    return []
  }
}

const persistVisibleKeys = (value: ManagedColumns['visibleColumns']): void => {
  localStorage.setItem('log-entries-visible-column-keys', JSON.stringify(value))
}

const DEFAULT_VISIBLE_KEYS = [
  LogEntriesTableColumnKey.LOG_ID,
  LogEntriesTableColumnKey.BUSINESS_UNIT,
  LogEntriesTableColumnKey.LOCATION,
  LogEntriesTableColumnKey.START_DATE,
  LogEntriesTableColumnKey.END_DATE,
  LogEntriesTableColumnKey.TITLE,
  LogEntriesTableColumnKey.CATEGORY,
  LogEntriesTableColumnKey.SUBCATEGORY,
  LogEntriesTableColumnKey.CALCULATION_METHOD,
  LogEntriesTableColumnKey.ACTIVTY_DATA_SOURCE,
  LogEntriesTableColumnKey.SCOPE,
  LogEntriesTableColumnKey.DATA_QUALITY,
  LogEntriesTableColumnKey.ORGANIZATION_EMISSIONS,
]

export const useColumnManager = (): {
  visibleKeys: LogEntriesTableColumnKey[]
  columns: TableColumns
  modal: React.ReactElement
  showModal: (analyticsKey: string) => void
  hideColumn: (key: LogEntriesTableColumnKey) => void
} => {
  const { trackAction } = useMixpanel()
  const { tableColumns } = useTableColumns()
  const { isFeatureEnabled } = usePricingFeature()

  const getInitialVisibleKeys = React.useCallback((): ManagedColumns['visibleColumns'] => {
    const persistedVisibleKeys = getPersistedVisibleKeys()

    if (persistedVisibleKeys?.length > 0) {
      return persistedVisibleKeys
    }

    const visibleKeys = [...DEFAULT_VISIBLE_KEYS]

    if (isFeatureEnabled('cbam')) {
      const index = visibleKeys.indexOf(LogEntriesTableColumnKey.ORGANIZATION_EMISSIONS)
      visibleKeys.splice(index, 0, LogEntriesTableColumnKey.CBAM_EMISSIONS_SUM)
    }

    return visibleKeys
  }, [isFeatureEnabled])

  const [visibleKeys, setVisibleKeys] = React.useState<ManagedColumns['visibleColumns']>(
    getInitialVisibleKeys(),
  )
  const [hiddenKeys, setHiddenKeys] = React.useState<ManagedColumns['hiddenColumns']>(
    tableColumns
      .filter(({ key, draggable }) => !visibleKeys.includes(key) && draggable)
      .map(({ key }) => key),
  )

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const showModal = React.useCallback(
    (analyticsKey: string) => {
      setIsModalOpen(true)
      trackAction(AnalyticsCategories.LOG_ENTRY_OVERVIEW, analyticsKey)
    },
    [trackAction],
  )

  const hideModal = React.useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const getVisibleColumnKeys = React.useCallback((managedColumns: ManagedColumns) => {
    const { visibleColumns, hiddenColumns } = managedColumns
    const hasHiddenInputs = hiddenColumns.some((column) => column.startsWith('input'))

    // If any input columns are hidden, filter out all input columns
    if (hasHiddenInputs) {
      return visibleColumns.filter((column) => !column.startsWith('input'))
    }

    // Otherwise, add all input columns after INPUT_1_VALUE
    const updatedColumns = [...visibleColumns]
    const firstInputIndex = updatedColumns.indexOf(LogEntriesTableColumnKey.INPUT_1_VALUE)

    if (firstInputIndex === -1) {
      return updatedColumns
    }

    const additionalInputColumns = range(2, 16).map(
      (i) => LogEntriesTableColumnKey[`INPUT_${i}_VALUE` as keyof typeof LogEntriesTableColumnKey],
    )

    updatedColumns.splice(firstInputIndex + 1, 0, ...additionalInputColumns)
    return updatedColumns
  }, [])

  const onOk = React.useCallback(
    (managedColumns: ManagedColumns) => {
      const visibleColumnKeys = getVisibleColumnKeys(managedColumns)
      setVisibleKeys(visibleColumnKeys)
      setHiddenKeys(managedColumns.hiddenColumns)
      hideModal()
    },
    [getVisibleColumnKeys, hideModal],
  )

  const hideInputColumns = React.useCallback(() => {
    // hide all input columns if one is hidden
    setVisibleKeys((prevVisible) =>
      prevVisible.filter((columnKey) => !columnKey.startsWith('input')),
    )
    setHiddenKeys((prevHidden) => [...prevHidden, LogEntriesTableColumnKey.INPUT_1_VALUE])
  }, [])

  const hideColumn = React.useCallback(
    (key: LogEntriesTableColumnKey) => {
      if (key.startsWith('input')) {
        hideInputColumns()
        return
      }

      setHiddenKeys((prevHidden) => [...prevHidden, key])
      setVisibleKeys((prevVisible) => prevVisible.filter((columnKey) => columnKey !== key))
    },
    [hideInputColumns],
  )

  const modal = React.useMemo(
    () => (
      <ColumnManagerModal
        open={isModalOpen}
        onOk={onOk}
        onCancel={hideModal}
        visibleKeys={visibleKeys}
        hiddenKeys={hiddenKeys}
      />
    ),
    [hiddenKeys, hideModal, isModalOpen, onOk, visibleKeys],
  )

  const columns = React.useMemo(
    () =>
      tableColumns
        .filter((column) => visibleKeys.includes(column.key))
        .sort((a, b) => visibleKeys.indexOf(a.key) - visibleKeys.indexOf(b.key)),
    [tableColumns, visibleKeys],
  )

  React.useEffect(() => {
    persistVisibleKeys(visibleKeys)
  }, [visibleKeys])

  return {
    visibleKeys,
    columns,
    hideColumn,
    modal,
    showModal,
  }
}
