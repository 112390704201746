import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { Col, Row } from 'antd'

import { FactorRequestType } from '@prisma/client'

import { ActivityDataSource, Territory } from '@cozero/models'

import ActivityDataSourceLazyLoadedCascader from '@/organisms/ActivityDataSourceLazyLoadedCascader'

import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Select, { SelectOptionsProperties } from '@/atoms/Select'
import Text from '@/atoms/Text'

import { GRAY_400 } from '@/styles/variables'

import { TerritoryCascader } from '../TerritoryCascader'

import classes from './RequestDetails.module.less'

interface RequestDetailsProps {
  lifecycleOptions: SelectOptionsProperties[]
  territoryOptions: Territory[]
  activityDataSources: ActivityDataSource[]
  unitsOptions: SelectOptionsProperties[]
  requestType: FactorRequestType
  onClickAddProduct: () => void
}

const RequestDetails = ({
  lifecycleOptions,
  territoryOptions,
  activityDataSources,
  unitsOptions,
  requestType,
}: RequestDetailsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Text fontWeight="semibold" mode="title" size="xs">
        {t('suppliers.factor.request-details')}
      </Text>
      <Row gutter={[48, 16]}>
        <Col span={12}>
          <Form.Item
            className={classes.activityDataSourceSpacing}
            label={<Label className={classes.labelCustom}>{t('log.product')}</Label>}
            name="activityDataSourceId"
            rules={[{ required: true, message: t('suppliers.factor.validation.required-product') }]}
          >
            <ActivityDataSourceLazyLoadedCascader
              activityDataSources={activityDataSources}
              placeholder={t('products.select')}
            />
          </Form.Item>
          {
            // Commented due to feature issues check - https://www.notion.so/cozero/Supplier-engagement-feature-Creating-data-for-the-wrong-organization-d9dda6631eb8412b9a12ff3c39604c122dc
            /* <Button
          className={classes.addProductButton}
          category={AnalyticsCategories.LOG_ENTRY}
          action="add"
          onClick={onClickAddProduct}
          type="text"
          color="blue"
        >
          {t('suppliers.factor.add-product')}
        </Button> */
          }
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Label className={classes.labelCustom}>{t('products.footprint.product-mass')}</Label>
            }
            name="denominatorUnitId"
            rules={[{ required: true, message: t('suppliers.factor.validation.required-unit') }]}
          >
            <Select
              options={unitsOptions}
              disabled={!unitsOptions.length}
              placeholder={t('products.footprint.unit-placeholder')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Label className={classes.labelCustom}>{t('products.footprint.product-code')}</Label>
            }
            name="productCode"
          >
            <InputField
              type="text"
              placeholder={t('products.footprint.product-code-placeholder')}
            />
          </Form.Item>
        </Col>
        {requestType === 'GHG' && (
          <Col span={12}>
            <Form.Item
              label={
                <Label className={classes.labelCustom}>
                  {t('suppliers.factor.lifecycle-steps')}
                </Label>
              }
              name="productLifecycleStepIds"
              rules={[
                {
                  type: 'array',
                  min: 1,
                  required: true,
                  message: t('suppliers.factor.validation.required-lifecycle-step'),
                },
              ]}
            >
              <Select
                options={lifecycleOptions}
                placeholder={t('suppliers.factor.option-placeholder')}
                mode="multiple"
              />
            </Form.Item>
          </Col>
        )}

        <Col span={12}>
          <Form.Item
            label={
              <Label className={classes.labelCustom}>
                {t('suppliers.factor.request-year.title')}
              </Label>
            }
            name="year"
            tooltip={{
              title: t('suppliers.factor.request-year.description'),
              icon: <HiOutlineInformationCircle color={GRAY_400} />,
            }}
            className={classes.labelCustom}
            rules={[{ required: true, message: t('suppliers.factor.validation.required-year') }]}
          >
            <DatePicker
              format="YYYY"
              picker="year"
              placeholder={t('year-placeholder')}
              className={classes.datePicker}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Label className={classes.labelCustom}>
                {t('act.marketplace.solution.supplier.country')}
              </Label>
            }
            name="territoryId"
            tooltip={{
              title: t('suppliers.factor.request-country.description'),
              icon: <HiOutlineInformationCircle color={GRAY_400} />,
            }}
          >
            <TerritoryCascader
              options={territoryOptions}
              placeholder={t('act.marketplace.solution.supplier.country-placeholder')}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default RequestDetails
