/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiInformationCircle } from 'react-icons/hi'

import { Row } from 'antd/es'

import OverviewRow from '@/organisms/OverviewRow'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import HighlightValue from '@/atoms/HighlightValue'
import iconClasses from '@/atoms/Icons/Icons.module.less'
import GlobeAltIcon from '@/atoms/Icons/Outlines/GlobeAlt'
import OfficeBuildingIcon from '@/atoms/Icons/Outlines/OfficeBuilding'
import Pill from '@/atoms/Pill'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { CINDER_BLUE_60, CINDER_BLUE_80, COZERO_BLUE_80 } from '@/styles/variables'
import { formatNumber } from '@/utils/number'

import { TabOption } from '../..'

import classes from './CCFOverviewCards.module.less'
import { useGetCCFOverviewCardsQuery } from './useGetCCFOverviewCardsQuery'

type LogsOverviewCardsProps = {
  selectedTabOption: TabOption
}

export const CCFOverviewCards = ({ selectedTabOption }: LogsOverviewCardsProps): JSX.Element => {
  const { isFeatureEnabled } = usePricingFeature()
  const { t } = useTranslation('common')

  const { data: overviewStats, isLoading } = useGetCCFOverviewCardsQuery({
    selectedTabOption,
  })

  const overviewData: OverviewCardProps[] = [
    {
      content: (
        <div className={classes.cardContent}>
          <HighlightValue
            value={
              overviewStats?.organizationEmissionsValue
                ? formatNumber(overviewStats?.organizationEmissionsValue).toString()
                : '--'
            }
            unit={t('co2-tonnes')}
          />
          <Row>
            <Pill className={classes.cardPill}>
              {selectedTabOption === 'log-overview' ? (
                <>
                  <span className={classes.logsPercentage}>{overviewStats?.percentageOfLogs}%</span>
                  <span className={classes.totalLogsPill}>
                    {' '}
                    {t('log.overview-stats.of')} {overviewStats?.total}{' '}
                    {t('log.log', { count: overviewStats?.total })}
                  </span>
                </>
              ) : (
                <span className={classes.totalLogsPill}>
                  {formatNumber(overviewStats?.total ?? 0, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}{' '}
                  {t('log.log-entry', { count: overviewStats?.total })}
                </span>
              )}
            </Pill>
          </Row>
        </div>
      ),
      headerTitle: t('log.overview-stats.organization-footprint'),
      headerColor: CINDER_BLUE_60,
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
        subtitle: t('log.overview-stats.organization-footprint-info'),
      },
      icon: (
        <OfficeBuildingIcon
          size={14}
          color={CINDER_BLUE_80}
          wrapperClassName={iconClasses.cinder30Border}
          border
        />
      ),
    },
  ]

  if (isFeatureEnabled('cbam')) {
    overviewData.push({
      content: (
        <div className={classes.cardContent}>
          <HighlightValue
            value={
              overviewStats?.cbamEmissionsValue
                ? formatNumber(overviewStats?.cbamEmissionsValue).toString()
                : '--'
            }
            unit={t('co2-tonnes')}
          />
          {selectedTabOption === 'leo' && (
            <Row>
              <Pill className={classes.cardPill}>
                <span className={classes.totalLogsPill}>
                  {formatNumber(overviewStats?.cbamTotal ?? 0, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}{' '}
                  {t('log.log-entry', { count: overviewStats?.cbamTotal })}
                </span>
              </Pill>
            </Row>
          )}
        </div>
      ),
      headerTitle: t('log.overview-stats.cbam-footprint'),
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
        subtitle: t('log.overview-stats.cbam-footprint-info'),
      },
      icon: (
        <GlobeAltIcon
          width={14}
          height={14}
          color={CINDER_BLUE_80}
          wrapperClassName={iconClasses.cinder30Border}
          border
        />
      ),
    })
  }
  if (!overviewData) {
    return <></>
  }

  return (
    <OverviewRow
      hideTitle
      loadingOverview={isLoading}
      marginBetween={14}
      overviewData={overviewData}
      classNameTitle={classes.overViewCardTitle}
    />
  )
}
