import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineLocationMarker, HiOutlineOfficeBuilding, HiPlus, HiX } from 'react-icons/hi'
import { HiOutlineChatBubbleOvalLeftEllipsis } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

import { Col, Row } from 'antd'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly } from '@/redux/auth'

import classes from './classes.module.less'

export interface EmptyStateCardProps {
  title: string
  description: string
  buttonText: string
  type: 'location' | 'businessUnit' | 'logs' | 'graphs' | 'chat'
  onClick: () => void
  onClose?: () => void
  className?: string
  imgSrc?: string
}

export function EmptyStateCard({
  title,
  description,
  buttonText,
  type,
  onClick,
  onClose,
  className,
  imgSrc,
}: EmptyStateCardProps): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const { isFeatureEnabled } = usePricingFeature()

  const Icon = (): JSX.Element => {
    switch (type) {
      case 'businessUnit':
        return <HiOutlineOfficeBuilding />
      case 'location':
        return <HiOutlineLocationMarker />
      case 'chat':
        return <HiOutlineChatBubbleOvalLeftEllipsis />
      default:
        return <HiPlus />
    }
  }

  const onCloseHandler = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const goToBulkLogCreation = (): void => {
    navigate(routes.log.corporateFootprint.bulkImport.create)
  }

  return (
    <Card
      className={`${className ? className : ''} ${classes.onboardingComponentCard}`}
      shadow="none"
    >
      <div className={classes.flexContainer}>
        <div>
          <div className={classes.header}>
            <Title size="sm" className={classes.title}>
              {title}
            </Title>

            {onClose && (
              <Button
                type="text"
                onClick={onCloseHandler}
                category={AnalyticsCategories.ONBOARDING}
                action={`Close ${type} onboarding card without finishing`}
              >
                <HiX className={classes.icon} />
              </Button>
            )}
          </div>

          <div className={classes.bodyWrapper} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size="xl" className={classes.subtitle}>
              {description}
            </Text>
            <Row gutter={[20, 20]}>
              <Col>
                <Button
                  size="lg"
                  type="primary"
                  category={AnalyticsCategories.ONBOARDING}
                  action={`Start ${type} onboarding`}
                  className={classes.cardBtn}
                  onClick={onClick}
                  prefixIcon={<Icon />}
                >
                  {buttonText}
                </Button>
              </Col>
              {type === 'logs' && !userIsReadOnly && isFeatureEnabled('bulk-import') && (
                <Col>
                  <Button
                    size="lg"
                    type="primary"
                    category={AnalyticsCategories.ONBOARDING}
                    action={'add-bulk'}
                    className={classes.cardBtn}
                    onClick={goToBulkLogCreation}
                    prefixIcon={<Icon />}
                  >
                    {t(`log.create.bulk`)}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>

        {imgSrc && <img src={imgSrc} />}
      </div>
    </Card>
  )
}
