import { CalculationMethod, CalculationMethodWithDisabledState } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_CALCULATION_METHODS, TAG_CALCULATION_METHODS_WITH_SINGLE_INPUTS } from './tags'

const calculationMethodsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCalculationMethods: builder.query<
      CalculationMethod[],
      { subcategoryId?: number; activityDataSourceId?: number; productLifecycleStepKey?: string }
    >({
      query: ({ subcategoryId, activityDataSourceId, productLifecycleStepKey }) => ({
        url: logApiGatewayClient.calculation_methods.GET_MANY,
        method: 'GET',
        params: {
          subcategoryId,
          ...(activityDataSourceId ? { activityDataSourceId } : {}),
          ...(productLifecycleStepKey ? { productLifecycleStepKey } : {}),
        },
      }),
      providesTags: (result) =>
        providesList<CalculationMethod[], typeof TAG_CALCULATION_METHODS>(
          result,
          TAG_CALCULATION_METHODS,
        ),
    }),
    getCalculationMethodsWithSingleInputs: builder.query<
      CalculationMethodWithDisabledState[],
      { subcategoryId?: number; activityDataSourceId?: number }
    >({
      query: ({ subcategoryId, activityDataSourceId }) => ({
        url: logApiGatewayClient.calculation_methods.GET_MANY_WITH_SINGLE_INPUTS,
        method: 'GET',
        params: {
          subcategoryId,
          ...(activityDataSourceId ? { activityDataSourceId } : {}),
        },
      }),
      providesTags: (result) =>
        providesList<
          CalculationMethodWithDisabledState[],
          typeof TAG_CALCULATION_METHODS_WITH_SINGLE_INPUTS
        >(result, TAG_CALCULATION_METHODS_WITH_SINGLE_INPUTS),
    }),
  }),
})

export const { useGetCalculationMethodsQuery, useGetCalculationMethodsWithSingleInputsQuery } =
  calculationMethodsSlice

export default calculationMethodsSlice
