import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Radio, Row } from 'antd'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import Label from '@/atoms/Label'
import Select, { SelectOptionsProperties } from '@/atoms/Select'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './SupplierDetails.module.less'

interface SupplierDetailsProps {
  supplierOptions: SelectOptionsProperties[]
  supplierEmailOptions: SelectOptionsProperties[]
  onClickAddSupplier: () => void
}

const SupplierDetails = ({
  supplierOptions,
  supplierEmailOptions,
  onClickAddSupplier,
}: SupplierDetailsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Text fontWeight="semibold" mode="title" size="xs">
        {t('suppliers.details')}
      </Text>
      <Row gutter={[48, 16]}>
        <Col span={12}>
          <Form.Item
            className={classes.supplierNameSpacing}
            label={<Label>{t('suppliers.name-long')}</Label>}
            name="supplierId"
            rules={[{ required: true, message: t('suppliers.validation.required-supplier') }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={supplierOptions}
              placeholder={t('suppliers.select-placeholer')}
            />
          </Form.Item>
          <Button
            className={classes.addSupplierButton}
            category={AnalyticsCategories.SUPPLIER}
            action="add"
            onClick={onClickAddSupplier}
            type="text"
            color="blue"
          >
            {t('suppliers.add')}
          </Button>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<Label>{t('email')}</Label>}
            name="email"
            rules={[{ required: true, message: t('suppliers.validation.required-email') }]}
          >
            <Select
              disabled={!supplierEmailOptions.length}
              options={supplierEmailOptions}
              placeholder={t('email-placeholder')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<Label>{t('suppliers.language')}</Label>}
            name="locale"
            rules={[{ required: true, message: t('suppliers.validation.required-language') }]}
          >
            <Radio.Group>
              <Radio value="en">{t('suppliers.languages.en')}</Radio>
              <Radio value="de">{t('suppliers.languages.de')}</Radio>
              <Radio value="ja">{t('suppliers.languages.jp')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default SupplierDetails
