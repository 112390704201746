/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Space } from 'antd/es'
import type { ColumnsType } from 'antd/es/table'

import moment from 'moment'

import { RevenueDto, RevenueValueDto } from '@cozero/dtos'

import Table from '@/molecules/Table'

import { formatCompact } from '@/utils/number'

import { ValueDeletion } from '../ValueDeletion/ValueDeletion'
import { ValueEdition } from '../ValueEdition/ValueEdition'

interface RevenueTableProps {
  revenue?: RevenueDto
}

export const RevenueTable: React.FC<RevenueTableProps> = ({ revenue }) => {
  const { t } = useTranslation('common')

  const columns = React.useMemo<ColumnsType<RevenueValueDto>>(
    () => [
      {
        title: t('revenue.form.business-level.label'),
        key: 'businessLevel',
        dataIndex: 'businessUnit',
        render: (businessUnit: RevenueValueDto['businessUnit']) =>
          !businessUnit.parentUnitId
            ? t('revenue.form.business-level.entire-organization')
            : businessUnit.title,
      },
      {
        title: t('revenue.form.accounting-period.label'),
        key: 'accountingPeriod',
        render: (record: RevenueValueDto) => {
          const start = moment.utc(record.periodStart).format('YYYY-MM')
          const end = moment.utc(record.periodEnd).format('YYYY-MM')
          return `${start} → ${end}`
        },
      },
      {
        title: t('revenue.title'),
        dataIndex: 'value',
        key: 'revenue',
        align: 'right',
        render: (value: number) =>
          `${formatCompact(value, { minimumFractionDigits: 2 })} ${revenue?.currency}`,
      },
      {
        dataIndex: 'id',
        key: 'revenue',
        align: 'right',
        // Fix the column width to the content
        width: '0',
        render: (id: number) => (
          <Space>
            <ValueEdition revenueValueId={id} />
            <ValueDeletion revenueValueId={id} />
          </Space>
        ),
      },
    ],
    [t, revenue?.currency],
  )

  return (
    <>
      <Table
        columns={columns}
        dataSource={revenue?.revenueValues}
        pagination={false}
        emptyDescription=""
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}
