import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDocument, HiOutlineGlobeAlt } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'

import { CbamTemplateType } from '@prisma/client'

import { FactorRequestDetailsView } from '@cozero/models'
import { routes } from '@cozero/utils'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'

import LayoutFooter from '@/molecules/LayoutFooter'

import Button from '@/atoms/Button'
import Spinner from '@/atoms/LoadingSpinner'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFindFactorRequestByIdQuery } from '@/redux/factors-requests'

import classes from './CbamTemplatesPageClasses.module.less'
import { FileUploader } from './components/FileUploader/FileUploader'
import { TemplateCard } from './components/TemplateCard/TemplateCard'
import { useCbamTemplates } from './useCbamTemplates'

export const AVAILABLE_TEMPLATES = [
  {
    id: CbamTemplateType.EU,
    icon: HiOutlineGlobeAlt,
    title: 'share.supplier-engagement-cbam.template-step.cards.eu.title',
    content: 'share.supplier-engagement-cbam.template-step.cards.eu.content',
  },
  {
    id: CbamTemplateType.COZERO,
    icon: HiOutlineDocument,
    title: 'share.supplier-engagement-cbam.template-step.cards.cozero.title',
    content: 'share.supplier-engagement-cbam.template-step.cards.cozero.content',
  },
] as const

const Content = ({
  factorRequest,
}: {
  factorRequest: FactorRequestDetailsView
}): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<CbamTemplateType>(
    AVAILABLE_TEMPLATES[0].id,
  )
  const {
    selectedFile,
    validationError,
    FILE_LIMIT_IN_MB,
    handleBeforeUpload,
    handleFileChange,
    handleSave,
    handleDeleteFile,
    downloadTemplate,
  } = useCbamTemplates({
    factorRequest: factorRequest,
  })

  const handleClose = (): void =>
    navigate(routes.log.factors.requestsPage.details.replace(':id', factorRequest.id.toString()))

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}></div>
      <div className={classes.mainSection}>
        <CustomHeader
          showArrow={false}
          title={t('share.supplier-engagement-cbam.title', {
            product: factorRequest.activityDataSource.name,
          })}
          onClose={handleClose}
          goBack={handleClose}
        />

        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <LifecycleModalTitle
              title={t('share.supplier-engagement-cbam.description.title')}
              description={t('share.supplier-engagement-cbam.description.text')}
            />
          </div>

          <div className={classes.cbamRequestSteps}>
            <div className={classes.cbamStep}>
              <h4>1. {t('share.supplier-engagement-cbam.template-step.title')}</h4>
              <div className={classes.templatesContainer}>
                {AVAILABLE_TEMPLATES.map((template) => (
                  <TemplateCard
                    {...template}
                    title={t(template.title)}
                    key={template.id}
                    onClick={(id) => setSelectedTemplateId(id)}
                    checked={selectedTemplateId === template.id}
                  >
                    <p>{t(template.content)}</p>
                  </TemplateCard>
                ))}
              </div>

              <div className={classes.btnContainer}>
                <Button
                  type="primary"
                  size="lg"
                  onClick={() => downloadTemplate({ type: selectedTemplateId })}
                >
                  {t('share.supplier-engagement-cbam.template-step.download-btn')}
                </Button>
              </div>
            </div>

            <div className={classes.cbamStep}>
              <h4>2. {t('share.supplier-engagement-cbam.upload-step.title')}</h4>
              <FileUploader
                description={t('share.supplier-engagement-cbam.file-uploader.description', {
                  fileLimit: FILE_LIMIT_IN_MB,
                })}
                selectedFile={selectedFile}
                error={validationError}
                beforeUpload={handleBeforeUpload}
                onChange={handleFileChange}
                onDelete={handleDeleteFile}
              />
            </div>
          </div>
        </div>

        <LayoutFooter
          hideCancelButton={true}
          categoryOkButton={AnalyticsCategories.PRODUCTS}
          categoryCancelButton={AnalyticsCategories.PRODUCTS}
          isDataValid={selectedFile?.status === 'done'}
          onCancelClick={() => {
            // requires an onCancel even though set to false lol
          }}
          onSaveClick={() => handleSave(selectedTemplateId)}
          saveButtonText={t('actions.save-continue')}
          cancelButtonText={t('actions.back')}
          saveButtonDisabled={false}
        />
      </div>
    </div>
  )
}

export const CbamTemplatesPage = (): React.ReactElement => {
  const navigate = useNavigate()
  const { id: routeId } = useParams()

  const {
    data: factorRequest,
    isUninitialized,
    isLoading,
  } = useFindFactorRequestByIdQuery(routeId as string, {
    skip: !routeId,
  })

  React.useEffect(() => {
    if (!routeId || (!isUninitialized && !isLoading && !factorRequest)) {
      navigate(`${routes.log.factors.base}/404`)
    }
  }, [routeId, isUninitialized, isLoading, factorRequest])

  if (isLoading || !factorRequest) {
    return (
      <div className={classes.container}>
        <div className={classes.sidebar}></div>
        <div className={classes.fullScreenContainer}>
          <Spinner />
        </div>
      </div>
    )
  }

  return <Content factorRequest={factorRequest} />
}
