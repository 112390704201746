import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TaskFilterDto, TaskResponseDto } from '@cozero/dtos'

import { DEFAULT_STATE, SLICE_NAME } from './constants'

const tasksSlice = createSlice({
  name: SLICE_NAME,
  initialState: DEFAULT_STATE,
  reducers: {
    setTasksFilters: (state, action: PayloadAction<Partial<TaskFilterDto>>) => {
      if (state.filters) {
        state.filters = action.payload
      }
    },
    resetTasksFilters: (state) => {
      state.filters = DEFAULT_STATE.filters
    },
    setCurrentPageResults: (state, action: PayloadAction<TaskResponseDto[]>) => {
      state.currentPageResults = action.payload
    },
  },
})

export const { setTasksFilters, resetTasksFilters, setCurrentPageResults } = tasksSlice.actions

export default tasksSlice
