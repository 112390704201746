import React from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import { routes } from '@cozero/utils'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, getUserRole } from '@/redux/auth'

type FeatureRouteProps = RouteProps & {
  requiredFeature?: string
  admin?: boolean
  excludeRoles?: string[]
  children?: React.ReactNode
}

const FeatureRoute = ({
  children,
  requiredFeature,
  admin,
  excludeRoles,
}: FeatureRouteProps): JSX.Element => {
  const userRole = useAppSelector(getUserRole)
  const { isFeatureEnabled } = usePricingFeature()
  const isHomeEnabled = isFeatureEnabled('dashboard-home')
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)

  if (admin && !isManagerOrAdmin) {
    return <Navigate to={isHomeEnabled ? routes.dashboardHome.home : routes.overview.dashboard} />
  }
  if (requiredFeature && !isFeatureEnabled(requiredFeature)) {
    return <Navigate to={isHomeEnabled ? routes.dashboardHome.home : routes.overview.dashboard} />
  }
  if (userRole && excludeRoles?.includes(userRole)) {
    return <Navigate to={isHomeEnabled ? routes.dashboardHome.home : routes.overview.dashboard} />
  }

  return <>{children}</>
}

export default FeatureRoute
