import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import NotFoundPage from '@/pages/404'
import FactorRequestForm from '@/pages/Log/FactorRequest/FactorRequestFormPage'
import FactorRequestTypePicker from '@/pages/Log/FactorRequest/FactorRequestTypePicker'
import FactorReviewPage from '@/pages/Log/FactorReview'

import LogProvider from '@/contexts/log'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>{children}</PrivateRoute>
  </LogProvider>
)

const getFactorRequestRoutes = (_: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.factors.request.base,
    element: (
      <Wrapper>
        <FactorRequestTypePicker />
      </Wrapper>
    ),
  },
  {
    path: routes.log.factors.request.create,
    element: (
      <Wrapper>
        <FactorRequestForm />
      </Wrapper>
    ),
  },
  {
    path: routes.log.factors.review,
    element: (
      <Wrapper>
        <FactorReviewPage />
      </Wrapper>
    ),
  },
  {
    path: `${routes.log.factors.base}/*`,
    element: (
      <Wrapper>
        <NotFoundPage />
      </Wrapper>
    ),
  },
]

export default getFactorRequestRoutes
