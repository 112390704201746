import '@csstools/normalize.css'
import 'antd/dist/antd.less'
import 'core-js/es/global-this'
import 'core-js/features/array/at'
import 'moment/locale/de'

import React from 'react'
import { createRoot } from 'react-dom/client'

import * as Sentry from '@sentry/react'

import { config } from '@/utils/config'

import '@/main.css'

import { App } from './App'

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: config.NODE_ENV,
  enabled: config.NODE_ENV !== 'development',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api.cozero\.io\/v1/,
    /^https:\/\/api.staging.cozero\.io\/v1/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
