/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Col, Row, Tabs, message } from 'antd/es'
import TabPane from 'antd/es/tabs/TabPane'

import { CreateLogDto } from '@cozero/dtos'
import { Organization } from '@cozero/models'
import { routes } from '@cozero/utils'

import LogCategoryModal from '@/organisms/LogCategoryModal'
import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import LogHeader from '@/molecules/LogHeader'

import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useMixpanel } from '@/hooks/useMixpanel'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useSelectedBusinessUnit } from '@/hooks/useSelectedBusinessUnit'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly, selectUserOrganization } from '@/redux/auth'
import { isAxiosError } from '@/redux/axiosBaseQuery'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useCreateLogMutation, useHasLogEntriesQuery } from '@/redux/logs'
import { hasFinishedOnboardingStep } from '@/redux/onboarding'
import { createQueryFilter } from '@/utils/filters'

import { LogEntries } from './LogEntries'
import { Logs } from './Logs'
import classes from './classes.module.less'
import { CCFOverviewCards } from './components/CCFOverviewCards/CCFOverviewCards'
import { LastUpdatePill } from './components/LastUpdatePill'

export type TabOption = 'leo' | 'log-overview'

function LocationLogs(): JSX.Element {
  const { t } = useTranslation('common')
  const { selectedBusinessUnit } = useSelectedBusinessUnit()
  const { isFeatureEnabled } = usePricingFeature()
  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const organization = useAppSelector(selectUserOrganization) as Organization
  const [loadingLogCreation, setLoadingLogCreation] = useState(false)

  const { search } = useLocation()
  const { trackAction } = useMixpanel()

  const { pageNumber: currentPage, saveFilters } = useFiltersContext()

  const hasFinishedLogOnboardingStep = useAppSelector((state) =>
    hasFinishedOnboardingStep(state, 'logs'),
  )
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { data: categories } = useGetCategoriesQuery()
  const [createLog] = useCreateLogMutation()

  const { data: hasLogEntries, isLoading: isLoadingHasLogEntries } = useHasLogEntriesQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit },
  )
  const { loading: logLoading } = useLogContext()
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  const { locationId } = useParams()
  const queryLocation = new URLSearchParams(search).get('location') || locationId
  const onChooseCategory = async (id: number): Promise<void> => {
    setLoadingLogCreation(true)
    try {
      await submitLog({ categoryId: id, type: 'location' })
    } finally {
      setLoadingLogCreation(false)
    }
  }

  const submitLog = async (newLog: Partial<CreateLogDto>): Promise<void> => {
    try {
      const createdLog = await createLog(newLog).unwrap()
      if (createdLog) {
        const category = categories?.find((obj) => obj.id === newLog.categoryId)
        message.success(t('log.create-success', { category: category?.name }))
        return navigate(
          `${routes.log.corporateFootprint.edit.replace(':id', createdLog.id.toString())}`,
          {
            state: { firstLog: !hasLogEntries || !hasFinishedLogOnboardingStep },
          },
        )
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.status === 402) {
          const limit = getLimit(organization, 'log')
          setSubscribeModalSettings({
            closable: true,
            title: t('subscription.upgrade-modal.title-logs-limit', {
              limit: limit?.max,
              item: t('product.logs.title'),
            }),
            visible: true,
          })
        } else {
          message.error(t('location.errors.log-creation'))
        }
      } else {
        message.error(t('location.errors.log-creation'))
      }
    }
  }

  const onCloseModal = (): void => {
    setModalOpen(false)
  }

  const openModal = async (): Promise<void> => {
    setModalOpen(true)
  }

  const goToBulkLog = (): void => {
    navigate(routes.log.corporateFootprint.bulkImport.create)
  }

  const goToBulkLogCreation = (): void => {
    navigate(routes.log.corporateFootprint.bulkImport.create)
  }

  useEffect(() => {
    if (queryLocation && currentPage === 1) {
      const queryFilter = createQueryFilter({
        value: [queryLocation],
        type: 'location',
        t,
        options: [],
      })
      saveFilters(queryFilter)
      navigate(location.pathname)
    }
  }, [currentPage, queryLocation, selectedBusinessUnit?.key, navigate, saveFilters, t])

  const [selectedTab, setSelectedTab] = useState<TabOption>('leo')

  const onTabClick = React.useCallback(
    (tabKey: TabOption) => {
      setSelectedTab(tabKey)
      trackAction(AnalyticsCategories.LOG_ENTRY_OVERVIEW, `tab-switch-to-${tabKey}`)
    },
    [trackAction],
  )

  const isLoading = logLoading || isLoadingHasLogEntries

  if (!hasLogEntries && !isLoading) {
    return (
      <>
        <Row className={classes.logEmptyState}>
          <Col span={24}>
            <EmptyStateCard
              title={t('log.onboarding.title')}
              description={t('log.onboarding.description')}
              buttonText={t('log.onboarding.create-log')}
              type="logs"
              onClick={openModal}
            />
          </Col>
          <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
        </Row>
        <LogCategoryModal
          open={modalOpen}
          onChooseCategory={onChooseCategory}
          onClose={onCloseModal}
          loadingLogCreation={loadingLogCreation}
        />
      </>
    )
  }

  return (
    <>
      <LogHeader
        title={t('log.locations-title')}
        subtitle={t('log.locations-subtitle')}
        logType="location"
      />
      <Row className={classes.section} align="stretch" justify="space-between">
        <CCFOverviewCards selectedTabOption={selectedTab} />

        <Col>
          <Row gutter={16} justify="space-between" align="bottom">
            <Col>
              <Row gutter={16}>
                {!userIsReadOnly && isFeatureEnabled('bulk-create') && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action={'add-bulk'}
                      type="primary"
                      onClick={goToBulkLogCreation}
                      className={classes.createButton}
                      data-cy="new-emission-log"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t(`log.create.bulk`)}
                    </Button>
                  </Col>
                )}
                {!userIsReadOnly && isFeatureEnabled('bulk-import') && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action="go-to-bulk-import"
                      type="secondary"
                      onClick={goToBulkLog}
                      data-cy="bulk-import-btn"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t('log.bulkImport')}
                    </Button>
                  </Col>
                )}
                {!userIsReadOnly && (
                  <Col>
                    <Button
                      category={AnalyticsCategories.LOGS}
                      action={'open-create-modal'}
                      type="primary"
                      onClick={openModal}
                      className={classes.createButton}
                      data-cy="new-emission-log"
                      prefixIcon={<HiOutlinePlus />}
                    >
                      {t(`log.create.title`)}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Tabs
        className={classes.logTabs}
        onTabClick={(key) => onTabClick(key as 'leo' | 'log-overview')}
        activeKey={selectedTab}
        tabBarExtraContent={selectedTab === 'leo' && <LastUpdatePill />}
      >
        <TabPane
          tab={
            <span className={classes.logTabPane}>
              {t('log.log-entries-overview.tab-title')}
              <Pill size="sm" className={classes.logTabPanePill}>
                {t('new')}
              </Pill>
            </span>
          }
          key="leo"
        >
          <LogEntries />
        </TabPane>

        <TabPane
          tab={<span className={classes.logTabPane}>{t('log.logs')}</span>}
          key="log-overview"
        >
          <Logs />
        </TabPane>
      </Tabs>

      <LogCategoryModal
        open={modalOpen}
        onChooseCategory={onChooseCategory}
        loadingLogCreation={loadingLogCreation}
        onClose={onCloseModal}
      />
    </>
  )
}

export default LocationLogs
