import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowNarrowRight } from 'react-icons/hi'

import { Col, Row, Spin } from 'antd/es'
import { SelectProps } from 'antd/es'

import { SearchOutlined } from '@ant-design/icons'

import { ProductTypeWithExtraInfo } from '@cozero/models'

import Select from '@/atoms/Select'

import classes from './ProductTypeSelect.module.less'

interface ProductTypeSelectProps {
  value?: number
  productTypes: ProductTypeWithExtraInfo[]
  onChange?: (value: number | number[]) => void
}

interface DataSource {
  title: string
  children: ProductTypeSelectProps['productTypes']
}

const ProductTypeSelect = ({
  productTypes,
  value,
  defaultValue,
  onChange,
  ...rest
}: ProductTypeSelectProps & SelectProps): JSX.Element => {
  const { t } = useTranslation('common')

  const DATA_SOURCE: DataSource = {
    title: '',
    children: [],
  }

  const [dataSource, setDataSource] = useState<DataSource>(DATA_SOURCE)
  const [filteredDataSource, setFilteredDataSource] = useState<DataSource>(DATA_SOURCE)
  const [search, setSearch] = useState<string>('')
  const [showCategories, setShowCategories] = useState(false)

  useEffect(() => {
    if (productTypes.length) {
      dataSource.children = productTypes
      const newDatasource = { ...dataSource }
      if (newDatasource.children.length) {
        setDataSource(newDatasource)
        setFilteredDataSource(newDatasource)
        setSearch('')
      }
    }
  }, [productTypes])

  const handleSearch = useCallback((value: string) => {
    if (!value) {
      setFilteredDataSource((prevState) => {
        return { ...prevState, children: [...productTypes] }
      })
    } else {
      const filteredCategories = dataSource.children.filter((el) =>
        el.search.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredDataSource((prevState) => {
        return { ...prevState, children: [...filteredCategories] }
      })
    }
    setSearch(value)
  }, [])

  const handleChange = useCallback((value: number | number[]) => {
    if (onChange) {
      onChange(value)
    }
  }, [])

  const renderOptions = useMemo(() => {
    return filteredDataSource.children.map((opt) => {
      return {
        value: Number(opt.id),
        key: opt.id,
        label: (
          <Row>
            <Col key={opt.path.join()} span={24} className={classes.optionRow}>
              {opt.path.map((el, i: number) => {
                return (
                  <>
                    {el}
                    {i < opt.path.length - 1 && (
                      <HiArrowNarrowRight className={classes.arrowIcon} />
                    )}
                  </>
                )
              })}
            </Col>
          </Row>
        ),
      }
    })
  }, [search, filteredDataSource])

  const renderCategories = useMemo(() => {
    if (!productTypes || !productTypes.length) {
      return []
    }
    return productTypes.map((el) => {
      return {
        value: el.id,
        label: (
          <Row key={el.key} style={{ marginLeft: `${el.path.length * 10}px` }}>
            {el.path[el.path.length - 1]}
          </Row>
        ),
      }
    })
  }, [productTypes])

  const options = useMemo(() => {
    if (showCategories) {
      return renderCategories
    }

    if (search || search === '') {
      return renderOptions.filter(
        (x) => !search || (x?.label?.props?.children?.key ?? '').toLowerCase().includes(search),
      )
    }
    return []
  }, [search, showCategories, filteredDataSource])
  return (
    <>
      <Spin spinning={!options?.length && !!defaultValue}>
        <Select
          showSearch
          allowClear
          filterOption={false}
          suffixIcon={<SearchOutlined />}
          dropdownMatchSelectWidth={false}
          onSearch={handleSearch}
          onChange={handleChange}
          options={options}
          placeholder={t('product.lifecycle-steps.configuration.product-type.placeholder')}
          value={value ?? defaultValue ?? []}
          {...rest}
        />
      </Spin>
    </>
  )
}

export default ProductTypeSelect
