import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const Home = lazy(() => import('@/pages/Home'))
const OverviewWrapperPage = lazy(() => import('@/pages/Overview/OverviewWrapperPage'))
const NotFoundPage = lazy(() => import('@/pages/404'))

const Wrapper = ({
  feature = 'reports',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
  </PrivateRoute>
)

/**
 * All routes in the /share/ namespace
 */
const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.overview.base,
    element: (
      <Wrapper>
        <Suspense fallback={<SuspenseSpinner />}>
          <OverviewWrapperPage />
        </Suspense>
      </Wrapper>
    ),
    children: [
      {
        path: `${routes.overview.base}/*`,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <NotFoundPage />
            </Suspense>
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getOverviewRoutes
