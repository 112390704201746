/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePencil } from 'react-icons/hi'

import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import moment from 'moment'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'

import { useGetRevenueQuery, useUpdateRevenueValueMutation } from '@/redux/revenues'

import { ValueForm, ValueFormValues } from '../ValueForm/ValueForm'

import classes from './ValueEdition.module.less'

export const ValueEdition = ({ revenueValueId }: { revenueValueId: number }): ReactElement => {
  const { t } = useTranslation('common')
  const [showEditionModal, setShowEditionModal] = React.useState(false)

  const { data: revenue } = useGetRevenueQuery()

  const revenueWithFilteredValues = React.useMemo(() => {
    if (!revenue) {
      return undefined
    }

    return {
      ...revenue,
      revenueValues: revenue.revenueValues.filter((value) => value.id !== revenueValueId),
    }
  }, [revenue, revenueValueId])

  const initialValues = React.useMemo<ValueFormValues | undefined>(() => {
    const revenuValue = revenue?.revenueValues.find((value) => value.id === revenueValueId)

    if (!revenuValue) {
      return undefined
    }

    return {
      accountingPeriod: [moment(revenuValue.periodStart), moment(revenuValue.periodEnd)],
      annualRevenue: revenuValue.value,
    }
  }, [revenue, revenueValueId])

  const [form] = useForm()

  const [updateRevenueValueMutation] = useUpdateRevenueValueMutation()

  const onFinish = React.useCallback(
    async (formValues: ValueFormValues) => {
      const [accountingPeriodStart, accountingPeriodEnd] = formValues.accountingPeriod

      if (!accountingPeriodStart || !accountingPeriodEnd) {
        return
      }

      try {
        await updateRevenueValueMutation({
          id: revenueValueId,
          periodStart: accountingPeriodStart.utc().startOf('month').toDate(),
          periodEnd: accountingPeriodEnd.utc().endOf('month').toDate(),
          value: formValues.annualRevenue,
        })
      } catch (error) {
        message.error(t('revenue.form.save-error-message'))
      }

      setShowEditionModal(false)
    },
    [revenueValueId, t, updateRevenueValueMutation],
  )

  const onCancel = React.useCallback(() => {
    setShowEditionModal(false)
    form.resetFields()
  }, [form])

  return (
    <>
      <Button
        className={classes.button}
        title={t('revenue.table.edit.title')}
        action="Edit revenue"
        type="text"
        iconClass={classes.btnIcon}
        prefixIcon={<HiOutlinePencil size={16} />}
        onClick={() => setShowEditionModal(true)}
        disabled={!revenueWithFilteredValues}
      />
      {revenueWithFilteredValues && (
        <Modal
          wrapClassName={classes.modal}
          centered
          open={showEditionModal}
          title={t('revenue.table.edit.title')}
          okText={t('revenue.table.edit.save')}
          onCancel={onCancel}
          onOk={() => form.submit()}
        >
          <ValueForm
            form={form}
            revenue={revenueWithFilteredValues}
            onFinish={onFinish}
            initialValues={initialValues}
          />
        </Modal>
      )}
    </>
  )
}
