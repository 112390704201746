import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineBookOpen } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useFiltersContext } from '@/contexts/filters'

import classes from './LogHeader.module.less'

function LogHeader({
  title,
  subtitle,
  logType,
}: {
  title: string
  subtitle: string
  logType: string
}): JSX.Element {
  const { t } = useTranslation('common')

  const { filters, resetSearch } = useFiltersContext()

  /** We need to reset the filters because they are currently global and applied across views. */
  useEffect(() => {
    // Resetting filters on component dismount
    return () => {
      if (filters?.length) {
        resetSearch()
      }
    }
  }, [])

  return (
    <Row justify="center">
      <Col span={24}>
        <Row justify="space-between" align="bottom">
          <Col span={12}>
            <Title data-cy="emission-log-title" as="h1">
              {title}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={16} span={24} className={classes.subtitle}>
            <Text size="xl" color="secondary">
              {subtitle}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              prefixIcon={<HiOutlineBookOpen size={20} />}
              href={t(`intercom.carbonFootprint.${logType}`)}
              type="link"
              target="_blank"
              rel="noreferrer"
              className={classes.learnLink}
            >
              {t(`log.${logType}-learn-title`)}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LogHeader
