export const GET_MANY_COMPUTED_REPORTS = 'getManyComputedReportsData'
export const GET_COMPUTED_REPORT_TYPES = 'getComputedReportTypes'
export const GET_LATEST_COMPUTED_REPORT_DATA = 'getLatestComputedReportData'
export const GET_COMPUTED_REPORT_BY_ID = 'getComputedReportById'
export const CREATE_COMPUTED_REPORT = 'createComputedReport'
export const DELETE_ONE_COMPUTED_REPORT = 'deleteOneComputedReport'
export const UPDATE_COMPUTED_REPORT = 'updateComputedReport'

export const TAG_MANY_COMPUTED_REPORTS = 'TAG_MANY_COMPUTED_REPORTS'
export const TAG_ONE_COMPUTED_REPORT = 'TAG_ONE_COMPUTED_REPORT'
