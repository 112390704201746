import { Unit, UnitType } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

interface UnitQueryParams {
  excludeFromPCF?: boolean
  type?: UnitType
}

const unitsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query<Unit[], UnitQueryParams | void>({
      query: (params) => ({
        url: logApiGatewayClient.units.GET_MANY,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetUnitsQuery } = unitsSlice

export default unitsSlice
