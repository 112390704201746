import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'

import { PageFilter } from '@cozero/models'

import Select from '@/atoms/Select'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

interface Props {
  filters: (PageFilter & { key: FilterKey })[]
  values: {
    [FilterKey.ACTIVE]: boolean
  }
  onChange: (filter: PageFilter & { key: FilterKey }, value: Select.value | string) => void
}

const QuantitiesFiltersForm = ({ values, onChange }: Props): ReactElement => {
  const { t } = useTranslation('')

  const handleChange = (value: Select.value | string, key: string): void => {
    onChange({ key } as unknown as PageFilter & { key: FilterKey }, value)
  }

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('location.active')}>
              <Select
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={`${values.active}`}
                options={[
                  { value: 'false', label: t('no') },
                  { value: 'true', label: t('yes') },
                ]}
                onChange={(value) => handleChange(`${value}`, FilterKey.ACTIVE)}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(QuantitiesFiltersForm)
