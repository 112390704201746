/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiOutlineBookmark,
  HiOutlineCalculator,
  HiOutlineCalendar,
  HiOutlineChip,
  HiOutlineClipboardList,
  HiOutlineDownload,
  HiOutlineGlobe,
  HiOutlineHashtag,
  HiOutlineLocationMarker,
  HiOutlineSearch,
  HiOutlineShare,
  HiOutlineTag,
  HiOutlineTrendingUp,
  HiOutlineUser,
} from 'react-icons/hi'
import { HiOutlineCommandLine } from 'react-icons/hi2'

import { OrganizationCarbonFootprintSearchFiltersDto } from '@cozero/dtos'

import { LogicOperatorOption } from '@/molecules/FilterDrawerForm/inputs/LogicOperatorInput'
import { FilterValues, FomInputType, FormInput } from '@/molecules/FilterDrawerForm/types'

import { ConsoleOutlineIcon } from '@/atoms/Icons/Outlines/Console'
import { DocumentOutlineIcon } from '@/atoms/Icons/Outlines/Document'
import OfficeBuilding from '@/atoms/Icons/Outlines/OfficeBuilding'
import { ScopeOutlineIcon } from '@/atoms/Icons/Outlines/Scope'
import { SubcategoryOutlineIcon } from '@/atoms/Icons/Outlines/Subcategory'
import Supplier from '@/atoms/Icons/Outlines/Supplier'
import { TotalEmissionsOutlineIcon } from '@/atoms/Icons/Outlines/TotalEmissions'

import { usePricingFeature } from '@/hooks/usePricingFeature'

export type SearchSelectFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  | 'businessUnit'
  | 'location'
  | 'category'
  | 'subcategory'
  | 'calculationMethod'
  | 'responsible'
  | 'territory'
  | 'supplier'
  | 'organizationTags'
  | 'scope'
  | 'activityDataSource'
  | 'emissionFactorUnit'
  | 'emissionFactorSource'
  | 'emissionFactorTerritory'
  | 'modeledImpact'
>

export type SingleSelectFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'inputUnit'
>

export type SearchLogEntryDataQualitySelectFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'dataQuality'
>

export type DatePickerFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'startDate' | 'endDate' | 'createdAt' | 'emissionFactorYear'
>

export type TextFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'description' | 'title' | 'logId' | 'logEntryId'
>

export type LogicOperatorFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  | 'organizationEmissions'
  | 'emissionFactorValue'
  | 'cbamEmissionsSum'
  | 'inputValue'
  | 'energyConsumption'
>

type DateRangePickerFiltersValuesKey = string

export type OrganizationCarbonFootprintFormValues = FilterValues<
  SearchSelectFiltersValuesKey,
  SearchLogEntryDataQualitySelectFiltersValuesKey,
  DatePickerFiltersValuesKey,
  DateRangePickerFiltersValuesKey,
  TextFiltersValuesKey,
  LogicOperatorFiltersValuesKey,
  SingleSelectFiltersValuesKey
>

type FormInputs = FormInput<
  SearchSelectFiltersValuesKey,
  SearchLogEntryDataQualitySelectFiltersValuesKey,
  DatePickerFiltersValuesKey,
  DateRangePickerFiltersValuesKey,
  TextFiltersValuesKey,
  LogicOperatorFiltersValuesKey,
  SingleSelectFiltersValuesKey
>[]

export type OrganizationCarboonFootprintInputsHookReturn = {
  inputs: FormInputs
}

export const useOrganizationCarbonFootprintFormConfiguration =
  (): OrganizationCarboonFootprintInputsHookReturn => {
    const { t } = useTranslation()
    const { isFeatureEnabled } = usePricingFeature()
    const iconSize = 16

    const numericFilterOptions = useMemo<LogicOperatorOption[]>(
      () => [
        {
          label: t('filter.is-equal'),
          value: 'EQUAL',
        },
        {
          label: t('filter.lt'),
          value: 'LESS',
        },
        {
          label: t('filter.gt'),
          value: 'MORE',
        },
      ],
      [t],
    )

    const inputs: FormInputs = useMemo(() => {
      const inputs: FormInputs = []

      // Push each input individually (for easier validation)
      inputs.push({
        key: 'logId',
        type: FomInputType.TEXT,
        icon: <HiOutlineHashtag size={iconSize} />,
        label: t('log.log-entries-drawer.input.log-id.label'),
        placeholder: t('log.log-entries-drawer.input.enter-id'),
      })

      inputs.push({
        key: 'logEntryId',
        type: FomInputType.TEXT,
        icon: <HiOutlineHashtag size={iconSize} />,
        placeholder: t('log.log-entries-drawer.input.enter-id'),
        label: t('log.log-entry-id'),
      })

      inputs.push({
        key: 'businessUnit',
        icon: <OfficeBuilding size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('business-unit.name'),
      })

      inputs.push({
        key: 'location',
        icon: <HiOutlineLocationMarker size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('location.location'),
      })

      inputs.push({
        key: 'startDate',
        icon: <HiOutlineCalendar size={iconSize} />,
        type: FomInputType.DATE_PICKER,
        label: t('log.log-entries-overview.table-columns.period-start'),
      })

      inputs.push({
        key: 'endDate',
        icon: <HiOutlineCalendar size={iconSize} />,
        type: FomInputType.DATE_PICKER,
        label: t('log.log-entries-overview.table-columns.period-end'),
      })

      inputs.push({
        key: 'title',
        icon: <DocumentOutlineIcon size={iconSize} />,
        type: FomInputType.TEXT,
        label: t('log.log-entries-drawer.input.log-entry-title.label'),
        placeholder: t('log.log-entries-drawer.input.log-entry-title.placeholder'),
      })

      inputs.push({
        key: 'category',
        icon: <HiOutlineBookmark size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.category'),
      })

      inputs.push({
        key: 'subcategory',
        icon: <SubcategoryOutlineIcon size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.subcategory'),
      })

      inputs.push({
        key: 'calculationMethod',
        icon: <HiOutlineCalculator size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.calculation-method'),
      })

      inputs.push({
        key: 'activityDataSource',
        icon: <HiOutlineTrendingUp size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.business-activity'),
      })

      inputs.push({
        key: 'scope',
        icon: <ScopeOutlineIcon size={iconSize - 2} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.scope'),
      })

      inputs.push({
        key: 'dataQuality',
        icon: <ScopeOutlineIcon size={iconSize - 2} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.data-quality'),
      })

      inputs.push({
        key: 'responsible',
        icon: <HiOutlineUser size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.responsible'),
      })

      inputs.push({
        key: 'supplier',
        icon: <Supplier size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.supplier'),
      })

      inputs.push({
        key: 'organizationTags',
        icon: (
          <>
            <OfficeBuilding size={iconSize - 2} />
            <HiOutlineTag size={iconSize} />
          </>
        ),
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.organization-tags'),
      })

      inputs.push({
        key: 'territory',
        icon: <HiOutlineGlobe size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.territory'),
      })

      inputs.push({
        key: 'description',
        icon: <HiOutlineClipboardList size={iconSize} />,
        type: FomInputType.TEXT,
        label: t('log.log-entries-drawer.input.description.label'),
        placeholder: t('log.log-entries-drawer.input.description.placeholder'),
      })

      inputs.push({
        key: 'modeledImpact',
        icon: <HiOutlineShare size={iconSize} />,
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-type'),
      })

      inputs.push({
        key: 'inputUnit',
        icon: (
          <>
            <HiOutlineDownload size={iconSize} />
            <HiOutlineCommandLine size={iconSize} />
          </>
        ),
        type: FomInputType.SINGLE_SELECT,
        label: t('log.log-entries-overview.filters.input-unit'),
      })

      inputs.push({
        key: 'inputValue',
        icon: <HiOutlineDownload size={iconSize} />,
        type: FomInputType.LOGIC_OPERATOR,
        label: t('log.log-entries-overview.filters.input-value'),
        options: [
          {
            label: t('filter.is-equal'),
            value: 'EQUAL',
          },
          {
            label: t('filter.lt'),
            value: 'LESS',
          },
          {
            label: t('filter.gt'),
            value: 'MORE',
          },
        ],
      })

      inputs.push({
        key: 'emissionFactorValue',
        icon: <HiOutlineChip size={iconSize} />,
        type: FomInputType.LOGIC_OPERATOR,
        label: t('log.log-entries-overview.table-columns.emission-factor-value'),
        options: numericFilterOptions,
        inputProps: {
          type: 'number',
        },
      })

      inputs.push({
        key: 'emissionFactorUnit',
        icon: (
          <>
            <HiOutlineChip size={iconSize} />
            <ConsoleOutlineIcon size={iconSize} />
          </>
        ),
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-unit'),
      })

      inputs.push({
        key: 'emissionFactorTerritory',
        icon: (
          <>
            <HiOutlineChip size={iconSize} />
            <HiOutlineGlobe size={iconSize} />
          </>
        ),
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-territory'),
      })

      inputs.push({
        key: 'emissionFactorYear',
        icon: (
          <>
            <HiOutlineChip size={iconSize} />
            <HiOutlineCalendar size={iconSize} />
          </>
        ),
        type: FomInputType.DATE_PICKER_YEAR,
        label: t('log.log-entries-overview.table-columns.emission-factor-year'),
      })

      inputs.push({
        key: 'emissionFactorSource',
        icon: (
          <>
            <HiOutlineChip size={iconSize} />
            <HiOutlineSearch size={iconSize} />
          </>
        ),
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-source'),
      })

      /*
      inputs.push({
        key: 'numberOfFiles', // TODO: Confirm if this can be a logic operator instead of a select
        type: FomInputType.LOGIC_OPERATOR,
        label: 'Number of Files', // TODO: Translate
      });
      */

      inputs.push({
        key: 'createdAt',
        icon: <HiOutlineCalendar size={iconSize} />,
        type: FomInputType.DATE_PICKER,
        label: t('log.createdAt'),
      })

      inputs.push({
        key: 'energyConsumption',
        icon: <TotalEmissionsOutlineIcon size={iconSize} />,
        type: FomInputType.LOGIC_OPERATOR,
        label: t('log.log-entries-overview.table-columns.energy-consumption'),
        options: numericFilterOptions,
        inputProps: {
          type: 'number',
        },
      })

      if (isFeatureEnabled('cbam')) {
        inputs.push({
          key: 'cbamEmissionsSum',
          icon: <TotalEmissionsOutlineIcon size={iconSize} />,
          type: FomInputType.LOGIC_OPERATOR,
          label: t('log.log-entries-overview.table-columns.cbam-emissions'),
          options: numericFilterOptions,
          inputProps: {
            type: 'number',
          },
        })
      }

      inputs.push({
        key: 'organizationEmissions',
        icon: <TotalEmissionsOutlineIcon size={iconSize} />,
        type: FomInputType.LOGIC_OPERATOR,
        label: t('organigram.export.columns.total'),
        options: numericFilterOptions,
        inputProps: {
          type: 'number',
        },
      })

      return inputs
    }, [t, isFeatureEnabled, numericFilterOptions])

    return { inputs }
  }
