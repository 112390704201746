import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineHome } from 'react-icons/hi'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { Breadcrumb, Divider } from 'antd/es'

import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import { ROUTES } from '@/templates/Router'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import i18n from '@/i18n'

import classes from './Breadcrumbs.module.less'
import Crumb from './Crumb'

export const Breadcrumbs = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const breadcrumbs = useBreadcrumbs(ROUTES(t), { excludePaths: ['/', 'log'] })
  const { isFeatureEnabled } = usePricingFeature()
  const isHomeEnabled = isFeatureEnabled('dashboard-home')

  const isHome = location.pathname === routes.base

  const crumbs = useMemo(
    () =>
      breadcrumbs.map((item) => {
        return (
          <Crumb
            key={item.key}
            path={item.match.pathname}
            childPath={item.location.pathname}
            preventNavigation={
              (item as BreadcrumbData<string> & { preventNavigation: boolean }).preventNavigation
            }
          >
            {item.breadcrumb}
          </Crumb>
        )
      }),
    [breadcrumbs, i18n.language],
  )

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <Breadcrumb>
          <Breadcrumb.Item key={routes.dashboardHome.home}>
            <Link
              to={
                isHome
                  ? location
                  : isHomeEnabled
                  ? routes.dashboardHome.home
                  : routes.overview.dashboard
              }
              onClick={(e) => isHome && e.preventDefault()}
              className={classes.home}
            >
              <HiOutlineHome />
            </Link>
          </Breadcrumb.Item>

          {crumbs}
        </Breadcrumb>
      </div>

      <Divider className={classes.divider} />
    </div>
  )
}

export default memo(Breadcrumbs)
