import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineTrash } from 'react-icons/hi'

import { Progress } from 'antd'
import { RcFile, UploadFile } from 'antd/lib/upload'
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger'

import classnames from 'classnames'

import Button from '@/atoms/Button'

import FILE_ICON from '@/assets/bulk-import/bulk-import-file.svg'
import { useGetSignedUrlMutation, useUploadFileToSignedUrlMutation } from '@/redux/files'

import { FileUploadIcon } from '../SquaredIcon/SquaredIcon'

import classes from './FileUploader.module.less'

interface FileItemProps {
  file: UploadFile
  onDelete: () => void
}

const _FileItem = ({ file, onDelete }: FileItemProps): React.ReactElement => {
  const { t } = useTranslation()

  const progressBarProps = (() => {
    if (file.status === 'error') {
      return {
        percent: 100,
        status: 'exception',
      } as const
    }

    return {
      percent: Math.round(file.percent ?? 0),
      status: file.percent !== 100 ? 'active' : 'success',
    } as const
  })()

  return (
    <div
      className={classnames(classes.fileItemContainer, {
        [classes.uploadError]: !!file.error,
      })}
    >
      <div className={classes.fileItemContentContainer}>
        <div className={classes.fileDescriptionContainer}>
          <FileUploadIcon status={file.status} />

          <div className={classes.textContainer}>
            <span className={classes.name}> {file.name}</span>
            {file.error && (
              <span className={classes.errorMsg}>
                {t('share.supplier-engagement-cbam.file-uploader.errors.upload.default')}
              </span>
            )}
          </div>
        </div>

        {(file.status === 'done' || file.status === 'error') && (
          <Button type="text" size="sm" className={classes.fileItemDeleteBtn} onClick={onDelete}>
            <HiOutlineTrash size={20} />
          </Button>
        )}
      </div>

      <div
        className={classnames(classes.progressBarContainer, {
          [classes.progressBarVisible]: file.status === 'uploading',
          [classes.progressBarHidden]: file.status !== 'uploading',
        })}
      >
        <Progress {...progressBarProps} />
      </div>
    </div>
  )
}

const FileItem = React.memo(_FileItem)

export interface ISignedFilesUrl {
  name: string
  fileObj?: RcFile
  signedUrl: string
  path: string
}

export interface CustomUploadFile extends UploadFile {
  response?: ISignedFilesUrl
}

type Props = Pick<DraggerProps, 'onChange' | 'beforeUpload'> & {
  selectedFile: UploadFile | null
  description: string
  error?: boolean
  onDelete: () => void
}

const _FileUploader = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const [getSignedUrl] = useGetSignedUrlMutation()
  const [uploadFileToSignedUrl] = useUploadFileToSignedUrlMutation()

  const getFileSignedUrl = async (file: RcFile): Promise<ISignedFilesUrl> => {
    const url = (await getSignedUrl([file.name]).unwrap())?.[0]

    return {
      name: file.name,
      fileObj: file,
      signedUrl: url.signedUrl,
      path: url.path,
    }
  }

  const handleUpload: DraggerProps['customRequest'] = async ({
    file,
    onProgress,
    onError,
    onSuccess,
  }) => {
    try {
      const _file = file as RcFile
      const signedFileProps = await getFileSignedUrl(_file)
      onProgress?.({ percent: 50 })
      await uploadFileToSignedUrl(signedFileProps).unwrap()
      onSuccess?.({ ..._file, ...signedFileProps })
    } catch (error) {
      onError?.(error, file)
    }
  }

  return (
    <Dragger
      className={classnames(classes.draggerContainerClasses, {
        [classes.draggerContainerError]: !!props.error,
      })}
      maxCount={1}
      fileList={props.selectedFile ? [props.selectedFile] : undefined}
      accept={'.xlsx'}
      multiple={false}
      customRequest={handleUpload}
      beforeUpload={props.beforeUpload}
      onChange={props.onChange}
      itemRender={(_node, file) => <FileItem file={file} onDelete={props.onDelete} />}
    >
      <div>
        <img src={FILE_ICON} style={{ marginBottom: 8 }} />
      </div>

      <p className={`ant-upload-text ${classes.uploadText}`}>
        {t(`log.bulk.file-uploader.title`)}{' '}
        <span className={classes.link}>{t(`log.bulk.file-uploader.browse-file`)}</span>
      </p>

      <p>{props.description}</p>
    </Dragger>
  )
}

export const FileUploader = React.memo(_FileUploader)
