import React from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Select, message } from 'antd'
import { Col, Row } from 'antd/es'

import { BusinessUnit, UnitType } from '@cozero/models'

import ActionCard from '@/pages/Onboarding/Supplier/ActionCard'

import Button from '@/atoms/Button'

import { useCreateRevenueMutation } from '@/redux/revenues'
import { useGetUnitsQuery } from '@/redux/units/api'

import classes from './CurrencySelectionCard.module.less'

export const CurrencySelectionCard = ({
  businessUnit,
}: {
  businessUnit: BusinessUnit | null
}): JSX.Element => {
  const { t } = useTranslation('common')

  const { data: units = [] } = useGetUnitsQuery({ type: UnitType.CURRENCY })
  const [createRevenueMutation] = useCreateRevenueMutation()

  const onFinish = (formValues: { currency: number }): void => {
    if (!businessUnit) {
      return
    }
    createRevenueMutation({
      organizationId: businessUnit.organizationId,
      businessUnitId: businessUnit.id,
      unitId: formValues.currency,
    })
      .unwrap()
      .catch(() => {
        message.error(t('revenue.onboarding.currency-error-message'))
      })
  }

  return (
    <ActionCard
      title={t('revenue.onboarding.title')}
      text={t('revenue.onboarding.subtitle')}
      active={true}
      status={t('act.onboarding.todo')}
      shadow="none"
    >
      <Form onFinish={onFinish}>
        <Row gutter={[0, 8]}>
          <Col span={12}>
            <Form.Item
              className={classes.paddingTop}
              name={'currency'}
              rules={[
                {
                  required: true,
                  message: t('revenue.onboarding.currency-required-message'),
                },
              ]}
            >
              <Select
                className={classes.select}
                placeholder={t('revenue.onboarding.placeholder')}
                options={units.map((unit) => ({
                  label: unit.name,
                  value: unit.id,
                }))}
                showSearch
                filterOption={(input, option) =>
                  (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                }
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12} />
          <Col span={24} className={classes.alignEnd}>
            <Button type="primary" htmlType="submit">
              {t('revenue.onboarding.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </ActionCard>
  )
}
