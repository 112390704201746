export const CREATE_MILESTONE = 'createMilestone'
export const GET_MILESTONE = 'getMilestone'
export const UPDATE_MILESTONE = 'updateMilestone'
export const DELETE_MILESTONE = 'deleteMilestone'
export const GET_MILESTONES = 'getMilestones'
export const GET_MILESTONE_CARDS = 'getMilestoneCards'
export const VALIDATE_MILESTONE_NAME = 'validateMilestoneName'

export default [
  CREATE_MILESTONE,
  GET_MILESTONE,
  UPDATE_MILESTONE,
  DELETE_MILESTONE,
  GET_MILESTONES,
  GET_MILESTONE_CARDS,
  VALIDATE_MILESTONE_NAME,
]
