import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter, HiPlus } from 'react-icons/hi'
import { HiOutlineBookOpen } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router'

import { Col, Modal, Row } from 'antd/es'

import produce from 'immer'
import { debounce } from 'lodash-es'

import { PageFilter, PageType } from '@cozero/models'
import { routes } from '@cozero/utils'

import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import FiltersDrawer from '@/molecules/FiltersDrawer'
import LocationsTable from '@/molecules/LocationsTable'
import OnboardingStepModal, { onboardingModalStyle } from '@/molecules/OnboardingStepModal'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFiltersContext } from '@/contexts/filters'
import { useSubscriptionContext } from '@/contexts/subscription'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppDispatch, useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUserOrganization, useLazyGetUserDataQuery } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetPaginatedLocationsQuery, useLazyGetLocationsCountQuery } from '@/redux/locations'
import { selectSteps, setSteps, useUpdateOnboardingMutation } from '@/redux/onboarding'

import classes from './classes.module.less'

const Locations = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState<PageFilter[]>([])
  const [pageSize, setPageSize] = useState(10)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const steps = useAppSelector(selectSteps)
  const dispatch = useAppDispatch()
  const organization = useAppSelector(selectUserOrganization)
  const { isFeatureEnabled } = usePricingFeature()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { pageNumber: currentPage, savePageNumber: setCurrentPage } = useFiltersContext()
  const [getMe] = useLazyGetUserDataQuery()
  const [updateOnboarding] = useUpdateOnboardingMutation()
  const { data: paginatedLocations, isLoading: loading } = useGetPaginatedLocationsQuery(
    {
      pageSize,
      page: currentPage,
      selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
      search: searchTerm,
      filters,
    },
    { skip: !selectedBusinessUnit },
  )
  const [getLocationsCount] = useLazyGetLocationsCountQuery()
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const onSearchFieldChange = useCallback(
    debounce((event) => {
      setSearchTerm(event.target.value)
      return setCurrentPage(1)
    }, 500),
    [],
  )

  const hasFinishedStep = useMemo(
    () =>
      steps?.length === 0 ||
      steps?.filter((step) => step.onboardingStep.key === 'locations' && step.completed)?.length ===
        1,
    [steps],
  )

  const goToCreatePage = async (): Promise<void> => {
    const limit = getLimit(organization, 'locations')
    if (limit) {
      const locationsCount = await getLocationsCount({
        businessUnitKey: selectedBusinessUnit?.key ?? '',
      }).unwrap()
      if (locationsCount >= limit.max) {
        setSubscribeModalSettings({
          closable: true,
          title: t('subscription.upgrade-modal.title-limit', {
            limit: limit.max,
            item: t('locations.title'),
          }),
          visible: true,
        })
        return
      }
    }
    navigate(routes.organization.addLocation)
  }

  const handleUpdateSteps = useCallback(async () => {
    const stepIndex = steps?.findIndex((step) => step.onboardingStep.key === 'locations')
    if (stepIndex === undefined && stepIndex !== -1) {
      throw new Error('Step not found')
    }
    const clonedSteps = steps ? [...steps] : []
    const updatedSteps = produce(clonedSteps, (draft) => {
      draft[stepIndex].completed = true
    })
    const data = await updateOnboarding({
      steps: updatedSteps,
    }).unwrap()
    if (data) {
      await getMe().unwrap()
      dispatch(setSteps(data))
      Modal.success({
        content: <OnboardingStepModal stepKey="locations" />,
        ...onboardingModalStyle,
      })
    }
  }, [steps])

  const finishOnboardingStep = async (): Promise<void> => {
    await handleUpdateSteps()
  }

  function onSearchLocations(filters: PageFilter[]): void {
    setFilters(filters)
    return setCurrentPage(1)
  }

  async function openDrawer(): Promise<void> {
    setFilterDrawerOpen(true)
  }

  // On return from adding a location, check if we finished the onboarding step
  useEffect(() => {
    if ((location.state as { finishOnboarding: boolean })?.finishOnboarding) {
      finishOnboardingStep()
    }
  }, [location.state])

  if (!loading && !hasFinishedStep && !paginatedLocations?.total) {
    return (
      <Row className={classes.locationEmptyState}>
        <Col span={24}>
          <EmptyStateCard
            title={t('locations.onboarding.title')}
            description={t('locations.onboarding.description')}
            buttonText={t('locations.onboarding.title')}
            type="location"
            onClick={goToCreatePage}
            className={classes.onboardingCard}
          />
        </Col>
        <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
      </Row>
    )
  }

  return (
    <Row>
      <Col span={24} data-cy="view-location-table">
        <Row className={classes.section} gutter={16}>
          <Col span={24}>
            <Title as="h1" size="sm">
              {t('locations.title')}
            </Title>
          </Col>

          <Col xl={12} lg={16} span={24}>
            <Text size="xl" color="secondary">
              {t('locations.subtitle')}
            </Text>
          </Col>

          <Col span={24}>
            <Button
              prefixIcon={<HiOutlineBookOpen size={20} />}
              href={t('intercom.log.locations')}
              type="link"
              target="_blank"
              rel="noreferrer"
              className={classes.learnLink}
            >
              {t('locations.learn')}
            </Button>
          </Col>
        </Row>

        <Row className={classes.section} gutter={16} justify="end">
          <Col md={6}>
            <InputField
              type="search"
              placeholder={t('locations.search')}
              loading={loading}
              onChange={onSearchFieldChange}
              size="middle"
            />
          </Col>

          <Col>
            <Button
              onClick={openDrawer}
              category={AnalyticsCategories.LOCATIONS}
              action={'open-filter'}
              prefixIcon={<HiOutlineFilter />}
            >
              {t('log.filter.add')}
            </Button>
          </Col>

          {isManagerOrAdmin && (
            <Col>
              <Button
                type="primary"
                category={AnalyticsCategories.LOCATIONS}
                action={'add'}
                onClick={goToCreatePage}
                prefixIcon={<HiPlus />}
                fillParent
                data-cy="create-location-btn"
              >
                {t('location.create-location-btn')}
              </Button>
            </Col>
          )}
        </Row>

        <Row className={classes.section} gutter={16}>
          <Col span={24}>
            <LocationsTable
              loading={loading}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage || 1}
              paginatedLocations={paginatedLocations}
              businessUnitsAllowed={isFeatureEnabled('business-units') || false}
              isManagerOrAdmin={isManagerOrAdmin}
            />
          </Col>
        </Row>
      </Col>

      <FiltersDrawer
        visible={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        search={onSearchLocations}
        filterOptions={[]}
        pageType={PageType.LOCATION_LIST}
      />
    </Row>
  )
}

export default Locations
