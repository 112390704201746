import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import GenericStepSelector from '@/pages/GenericLifecycleSteps/GenericStepSelector'
import SelectSteps from '@/pages/GenericLifecycleSteps/SelectSteps'
import { LifecycleStepsID, LifecycleStepsKey } from '@/pages/GenericLifecycleSteps/hooks/steps'
import UpsertProductPage from '@/pages/Organization/NewProduct'
import ProductConfiguration from '@/pages/Organization/NewProduct/ProductConfiguration'
import Quantities from '@/pages/Organization/NewProduct/Quantities'

import LifecycleProvider from '@/contexts/lifecycle'
import LogProvider from '@/contexts/log'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <LifecycleProvider>
      <PrivateRoute>{children}</PrivateRoute>
    </LifecycleProvider>
  </LogProvider>
)

const getLogRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.products.upsert.base,
    element: (
      <Wrapper>
        <UpsertProductPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
    children: [
      {
        path: routes.log.products.upsert.stepsCreate,
        element: <SelectSteps />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.configurationCreate,
        element: <ProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.quantitiesCreate,
        element: <Quantities />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.rawMaterialsAcquisitionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.productionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.distributionAndStorageCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.endOfLifeCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['end-of-life']}
            step={LifecycleStepsKey.END_OF_LIFE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.packagingCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.usageCreate,
        element: (
          <GenericStepSelector stepId={LifecycleStepsID.usage} step={LifecycleStepsKey.USAGE} />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.stepsEdit,
        element: <SelectSteps edit />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.configurationEdit,
        element: <ProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.quantitiesEdit,
        element: <Quantities />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.rawMaterialsAcquisitionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.productionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.distributionAndStorageEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.endOfLifeEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['end-of-life']}
            step={LifecycleStepsKey.END_OF_LIFE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.packagingEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.products.upsert.usageEdit,
        element: (
          <GenericStepSelector stepId={LifecycleStepsID.usage} step={LifecycleStepsKey.USAGE} />
        ),
        breadcrumb: t('layout.products'),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getLogRoutes
