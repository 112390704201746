/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { isEqual } from 'lodash'

import { PageFilter } from '@cozero/models'

import { useFiltersContext } from '@/contexts/filters'
import useMemoCompare from '@/hooks/useMemoCompare'
import { useSelectedBusinessUnit } from '@/hooks/useSelectedBusinessUnit'
import { useGetLogEntryOverviewRowsSumsQuery } from '@/redux/logEntries'
import { useGetBusinessUnitOverviewQuery } from '@/redux/logs'

import { TabOption } from '../..'
import { useOrganizationEmissionsFiltersState } from '../../hooks/useOrganizationEmissionsFiltersState'

type UseGetCCFCardsProps = {
  selectedTabOption: TabOption
}

type UseGetCCFCardsReturn = {
  isLoading: boolean
  data: CCFCardsData | null
}

type CCFCardsData = {
  organizationEmissionsValue?: number
  total: number
  cbamEmissionsValue?: number
  cbamTotal?: number
  percentageOfLogs?: number
}

/*
  This hook is used to get the data for the CCF overview cards depending on the selected tab option.
*/
export const useGetCCFOverviewCardsQuery = ({
  selectedTabOption,
}: UseGetCCFCardsProps): UseGetCCFCardsReturn => {
  const { filters } = useFiltersContext()
  const { selectedBusinessUnit } = useSelectedBusinessUnit()
  const [leoFilters] = useOrganizationEmissionsFiltersState()

  const memoedFilters = useMemoCompare<Omit<PageFilter, 'options'>[] | undefined>(
    filters,
    (
      prevFilters: Omit<PageFilter, 'options'>[] | undefined,
      nextFilters: Omit<PageFilter, 'options'>[] | undefined,
    ) => isEqual(prevFilters, nextFilters),
  )
  const { data: overviewStats, isLoading: overviewLoading } = useGetBusinessUnitOverviewQuery(
    selectedBusinessUnit?.id
      ? {
          businessUnitId: selectedBusinessUnit?.id,
          filters: memoedFilters,
        }
      : skipToken,
    { skip: !selectedBusinessUnit?.id || selectedTabOption !== 'log-overview' },
  )

  const { data: leoOverviewStats, isLoading: leoOverviewLoading } =
    useGetLogEntryOverviewRowsSumsQuery(
      {
        filters: leoFilters,
        selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
      },
      {
        skip: !selectedBusinessUnit?.id || selectedTabOption !== 'leo',
      },
    )

  const data: CCFCardsData | null = React.useMemo(() => {
    return (
      (selectedTabOption === 'log-overview'
        ? {
            organizationEmissionsValue: overviewStats?.emissionsDataValue ?? 0,
            total: overviewStats?.totalLogs ?? 0,
            cbamEmissionsValue: overviewStats?.cbamEmissionsValue ?? 0,
            percentageOfLogs: overviewStats?.percentageOfLogs ?? 0,
          }
        : {
            organizationEmissionsValue: leoOverviewStats?.organizationEmissions,
            cbamEmissionsValue: leoOverviewStats?.cbamEmissionsSum ?? 0,
            cbamTotal: leoOverviewStats?.totalCbamLogEntriesCount ?? 0,
            total: leoOverviewStats?.totalLogEntriesCount ?? 0,
          }) || null
    )
  }, [leoOverviewStats, overviewStats, selectedTabOption])

  return {
    isLoading: selectedTabOption === 'log-overview' ? overviewLoading : leoOverviewLoading,
    data,
  }
}
