import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import { LineChart, LineChartProps } from '@opd/g2plot-react'

import { RevenueDto } from '@cozero/dtos'

import GraphTooltip from '@/molecules/GraphTooltip'

import Pill from '@/atoms/Pill'

import { CINDER_BLUE_40, FONT_WEIGHT_MEDIUM } from '@/styles/variables'

import classes from './RevenueGraph.module.less'
import { generateDatapoints, getRevenueTooltipData } from './RevenueGraph.utils'

export const RevenueGraph = ({
  revenueData: { currency, revenueValues },
}: {
  revenueData: RevenueDto
}): JSX.Element | null => {
  const { t } = useTranslation()

  const graphValues = generateDatapoints(revenueValues)

  const graphConfig: LineChartProps = {
    appendPadding: [10, 0, 0, 0],
    xField: 'year',
    yField: 'value',
    xAxis: {
      tickCount: 2,
      tickLine: null,
      range: [0, 1],
      line: {
        style: {
          stroke: '#e7e8ee',
          width: 1,
        },
      },
      label: {
        style: {
          fontSize: 11,
          fill: CINDER_BLUE_40,
          fontWeight: Number(FONT_WEIGHT_MEDIUM),
        },
      },
    },
    yAxis: {
      label: null,
      line: {
        style: {
          stroke: '#e7e8ee',
        },
      },
      grid: null,
    },
    data: graphValues,
    point: {
      size: 3,
      shape: 'circle',
      style: {
        fill: '#2871FF',
        lineWidth: 1,
      },
    },
  }

  if (graphValues.length < 2) {
    return null
  }

  return (
    <div className={classes.graphContainer}>
      <span className={classes.title}>{t('revenue.graph.title')}</span>
      <span className={classes.legend}>{t('revenue.graph.legend')}</span>

      <LineChart
        {...graphConfig}
        data={graphValues}
        className={classes.graph}
        tooltip={{
          ...GraphTooltip.defaultConfig,
          customContent: (_title, data) => {
            const tooltipData = data.map((datum) => {
              const { pillColor, yearVariationText, value, timePeriod, isFirstDatapoint } =
                getRevenueTooltipData({
                  revenueValues,
                  currency,
                  periodStart: datum.data.periodStart,
                  periodEnd: datum.data.periodEnd,
                })

              return {
                color: datum.color,
                title: t('revenue.graph.legend'),
                value,
                timePeriod,
                percentageColumn: isFirstDatapoint ? null : (
                  <div className={classes.tooltipContainer}>
                    <Pill
                      size="md"
                      color={pillColor}
                      className={classes.tooltipPill}
                      textClasses={classes.tooltipPillText}
                    >
                      {yearVariationText}
                    </Pill>
                  </div>
                ),
              }
            })

            return ReactDOMServer.renderToString(
              <GraphTooltip title={tooltipData[0]?.timePeriod} data={tooltipData} />,
            )
          },
        }}
      />
    </div>
  )
}
