import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineCheckCircle, HiOutlineX } from 'react-icons/hi'

import { Drawer, Spin, Tabs } from 'antd'

import { UserUpdateStatus } from '@prisma/client'

import { UserUpdatesMilestoneContext } from '@cozero/models'
import { UserUpdatesTaskContext } from '@cozero/models'

import { Shape } from '@/molecules/Shape/Shape'

import Avatar from '@/atoms/Avatar'
import Button from '@/atoms/Button'
import Divider from '@/atoms/Divider'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { COZERO_BLUE_80 } from '@/styles/variables'
import { getTimeAgoShort, prettifyDate } from '@/utils/date'

import classes from './UpdatesDrawer.module.less'
import { getAction, useUpdatesDrawer } from './useUpdatesDrawer'

export const UpdatesDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}): JSX.Element => {
  const { t } = useTranslation('common')
  const {
    userUpdateItems,
    isLoading,
    handleClose,
    selectedFilter,
    setSelectedFilter,
    markAllAsRead,
    markAsRead,
  } = useUpdatesDrawer({ onClose, isOpen })

  const userUpdateItemsComponent = useMemo(
    (): React.ReactNode[] =>
      userUpdateItems.map((item) => {
        const { key, ...values } = getAction(
          item.update.type,
          t,
          item.update.context as UserUpdatesMilestoneContext | UserUpdatesTaskContext,
          item.doneBy.doneByName,
        )
        return (
          <div className={classes.container} key={item.update.id}>
            <div className={classes.updateRow}>
              <div className={classes.updateInfoContainer}>
                <div className={classes.dotContainer}>
                  {item.update.status === UserUpdateStatus.UNREAD && (
                    <Shape type={'dot'} color={COZERO_BLUE_80} />
                  )}
                </div>
                <div className={classes.avatarContainer}>
                  <Avatar
                    firstName={item.doneBy?.firstName}
                    lastName={item.doneBy?.lastName}
                    image={item.doneBy?.photo}
                    className={classes.userPhoto}
                    showTooltip={false}
                  />
                </div>

                <span className={classes.action}>
                  <Trans
                    i18nKey={key}
                    values={values}
                    components={{
                      container: <span className={classes.userNameContainer} />,
                      tooltip: (
                        <Tooltip content={item.doneBy?.doneByName} align={{ offset: [0, -4] }}>
                          {''}
                        </Tooltip>
                      ),
                      text: (
                        <Text size="xl" ellipsis>
                          {''}
                        </Text>
                      ),
                      username: <span className={classes.userName} />,
                      entity: <span className={classes.entityName} />,
                    }}
                  ></Trans>
                </span>
              </div>
              <div className={classes.timeAgoContainer}>
                <Tooltip content={prettifyDate(item.update.createdAt)} align={{ offset: [0, -4] }}>
                  <Text size="lg" className={classes.date} color="secondary">
                    {getTimeAgoShort(item.update.createdAt)}
                  </Text>
                </Tooltip>
                {item.update.status === UserUpdateStatus.UNREAD && (
                  <div className={classes.markAsReadContainer}>
                    <Button
                      type="text"
                      onClick={() => markAsRead(item.update.id)}
                      prefixIcon={<HiOutlineCheckCircle size={16} />}
                      className={classes.markAsReadButton}
                    >
                      {t('dashboard.home.all-updates.mark-as-read')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <Divider color="light" className={classes.divider} />
          </div>
        )
      }),
    [userUpdateItems],
  )

  return (
    <Drawer
      title={t('dashboard.home.all-updates.title')}
      placement="left"
      onClose={handleClose}
      open={isOpen}
      width={400}
      closeIcon={
        <div className={classes.closeIcon}>
          <HiOutlineX size={16} />
        </div>
      }
    >
      <div className={classes.filtersContainer}>
        <div className={classes.filtersSection}>
          <div className={classes.tabsContainer}>
            <Tabs
              items={[
                { label: t('dashboard.home.all-updates.filter.all'), key: 'ALL' },
                { label: t('dashboard.home.all-updates.filter.unread'), key: 'UNREAD' },
              ]}
              activeKey={selectedFilter}
              onChange={(key) => setSelectedFilter(key as 'ALL' | 'UNREAD')}
            />
          </div>
          <Button
            type="text"
            prefixIcon={<HiOutlineCheckCircle size={16} />}
            onClick={markAllAsRead}
          >
            {t('dashboard.home.all-updates.filter.mark-all-as-read')}
          </Button>
        </div>
        <Divider color="light" className={classes.drawerSectionDivider} />
      </div>
      {isLoading ? (
        <Spin />
      ) : userUpdateItems?.length ? (
        userUpdateItemsComponent
      ) : selectedFilter === 'UNREAD' ? (
        <Text size="xl">{t('dashboard.home.all-updates.no-updates-unread')}</Text>
      ) : (
        <Text size="xl">{t('dashboard.home.all-updates.no-updates')}</Text>
      )}
    </Drawer>
  )
}
