import { UserUpdate, UserUpdateStatus } from '@prisma/client'

import { BulkReadUserUpdateDto } from '@cozero/dtos'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  DELETE_USER_UPDATE,
  GET_USER_UPDATE,
  GET_USER_UPDATES,
  READ_USER_UPDATES,
  TAG_USER_UPDATES,
  UPDATE_USER_UPDATE,
} from './constants'

const userUpdatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_USER_UPDATES]: builder.query<UserUpdate[], void>({
      query: () => ({
        url: centralApiGatewayClient.userUpdates.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<UserUpdate[], typeof TAG_USER_UPDATES>(result, TAG_USER_UPDATES),
    }),

    [GET_USER_UPDATE]: builder.query<UserUpdate, number>({
      query: (id) => ({
        url: centralApiGatewayClient.userUpdates.GET_ONE.replace(':id', `${id}`),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_USER_UPDATES, id: result?.id }],
    }),

    [UPDATE_USER_UPDATE]: builder.mutation<UserUpdate, { id: number; status: UserUpdateStatus }>({
      query: ({ id, status }) => ({
        url: centralApiGatewayClient.userUpdates.UPDATE_ONE.replace(':id', `${id}`),
        method: 'PUT',
        data: { status },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: TAG_USER_UPDATES, id }],
    }),

    [READ_USER_UPDATES]: builder.mutation<void, BulkReadUserUpdateDto>({
      query: (body) => ({
        url: centralApiGatewayClient.userUpdates.READ_MANY,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [TAG_USER_UPDATES],
    }),

    [DELETE_USER_UPDATE]: builder.mutation<UserUpdate, number>({
      query: (id) => ({
        url: centralApiGatewayClient.userUpdates.DELETE_ONE.replace(':id', `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: TAG_USER_UPDATES, id: result?.id }],
    }),
  }),
})

export const {
  useGetUserUpdatesQuery,
  useGetUserUpdateQuery,
  useUpdateUserUpdateMutation,
  useDeleteUserUpdateMutation,
  useReadUserUpdatesMutation,
} = userUpdatesApiSlice

export default userUpdatesApiSlice
