import { ActivityDataSourceQuery, SelectOptionDto } from '@cozero/dtos'
import { ActivityDataSource, ActivityDataSourceWithChildren } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_ACTIVITY_DATA_SOURCES, TAG_ACTIVITY_DATA_SOURCES_FIND_MANY } from './tags'

export const GET_ACTIVITY_DATA_SOURCES_TREE = 'getActivityDataSourcesTree'
export const GET_ACTIVITY_DATA_WITHOUT_STRUCTURE = 'getActivityDataSourcesWithoutStructure'

const activityDataSourceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_ACTIVITY_DATA_SOURCES_TREE]: builder.query<
      ActivityDataSourceWithChildren[],
      { subcategoryId: number; calculationMethodId?: number }
    >({
      query: ({ subcategoryId, calculationMethodId }) => ({
        url: logApiGatewayClient.activity_data_sources.GET_MANY,
        method: 'GET',
        params: { subcategoryId, calculationMethodId },
      }),
      providesTags: (result) =>
        providesList<ActivityDataSourceWithChildren[], typeof TAG_ACTIVITY_DATA_SOURCES>(
          result,
          TAG_ACTIVITY_DATA_SOURCES,
        ),
    }),
    getActivityDataSourceOptions: builder.query<SelectOptionDto[], void>({
      query: () => ({
        url: logApiGatewayClient.activity_data_sources.GET_OPTIONS,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<SelectOptionDto[], typeof TAG_ACTIVITY_DATA_SOURCES_FIND_MANY>(
          result,
          TAG_ACTIVITY_DATA_SOURCES_FIND_MANY,
        ),
    }),
    [GET_ACTIVITY_DATA_WITHOUT_STRUCTURE]: builder.query<
      Array<ActivityDataSource>,
      ActivityDataSourceQuery | void
    >({
      query: (params) => ({
        method: 'GET',
        url: logApiGatewayClient.activity_data_sources.GET_MANY_WITHOUT_STRUCTURE,
        params,
      }),
    }),
  }),
})

export const {
  useGetActivityDataSourcesTreeQuery,
  useGetActivityDataSourceOptionsQuery,
  useGetActivityDataSourcesWithoutStructureQuery,
} = activityDataSourceSlice

export default activityDataSourceSlice
