import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { Col, Row, Spin } from 'antd'

import classes from '@/pages/Log/Factors/classes.module.less'

import ChangelogsTable from '@/organisms/ChangelogsTable'

import Alert from '@/atoms/Alert'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useFindMethodologyChangelogsQuery } from '@/redux/methodologyChangelogs'

const CalculationChanges = (): ReactElement => {
  const { t } = useTranslation('common')
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const [methodologyChangelogPageSize, setMethodologyChangelogPageSize] = useState(10)
  const [methodologyChangelogPage, setMethodologyChangelogPage] = useState(1)

  const { data: methodologyChangelogs, isFetching: loading } = useFindMethodologyChangelogsQuery(
    {
      page: methodologyChangelogPage,
      pageSize: methodologyChangelogPageSize,
      businessUnitId: selectedBusinessUnit!.id,
    },
    {
      skip: !selectedBusinessUnit,
    },
  )

  return (
    <Spin spinning={loading}>
      <div className={classes.innerContainer}>
        <Row className={classes.headerRow}>
          <Col span={24} className={classes.header}>
            <Title size="sm">{t('calculation-changes.title')}</Title>
          </Col>
          <Col xl={14} lg={20} span={24}>
            <Text size="xl" color="secondary">
              {t('calculation-changes.subtitle')}
            </Text>
          </Col>
        </Row>
        <Row className={classes.info}>
          <Col xl={14} lg={20} span={24}>
            <Alert type="info">{t('calculation-changes.alert-info')}</Alert>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={classes.factorsTableContainer}>
            <ChangelogsTable
              methodologyChangelogs={methodologyChangelogs || { total: 0, data: [] }}
              page={methodologyChangelogPage}
              pageSize={methodologyChangelogPageSize}
              setPageSize={setMethodologyChangelogPageSize}
              setPage={setMethodologyChangelogPage}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default CalculationChanges
