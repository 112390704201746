import { useCallback } from 'react'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'

import { MenuItem } from './NavSidebar'

type MenuItemPartial = Pick<MenuItem, 'feature' | 'excludeRoles'>

export const useMenuFeature = (): {
  canUseMenuFeature: (item: MenuItemPartial) => boolean
} => {
  const user = useAppSelector(selectUser)
  const { isFeatureEnabled } = usePricingFeature()

  const canUseMenuFeature = useCallback((item: MenuItemPartial): boolean => {
    return isFeatureEnabled(item.feature) && !item.excludeRoles?.includes(user?.role?.type || '')
  }, [])

  return { canUseMenuFeature }
}
