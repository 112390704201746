import { BusinessUnit } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

export const useSelectedBusinessUnit = (): {
  selectedBusinessUnit: BusinessUnit | null
} => {
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  return {
    selectedBusinessUnit,
  }
}
