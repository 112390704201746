/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Space, message } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { Moment } from 'moment'

import { RevenueDto } from '@cozero/dtos'

import Button from '@/atoms/Button'

import { useCreateRevenueValueMutation } from '@/redux/revenues'

import { ValueForm, ValueFormValues } from '../ValueForm/ValueForm'

import classes from './ValueCreation.module.less'

const getFirstOfMonthInUtc = (date: Moment): Date => {
  return date.utc().startOf('month').toDate()
}

const getLastOfMonthInUtc = (date: Moment): Date => {
  return date.utc().endOf('month').toDate()
}

export const ValueCreation = ({ revenue }: { revenue: RevenueDto }): ReactElement => {
  const { t } = useTranslation('common')

  const [form] = useForm()
  const [createRevenueValueMutation] = useCreateRevenueValueMutation()

  const onFinish = React.useCallback(
    async (formValues: ValueFormValues): Promise<void> => {
      if (!formValues.accountingPeriod[0] || !formValues.accountingPeriod[1]) {
        return
      }

      const periodStart = getFirstOfMonthInUtc(formValues.accountingPeriod[0])
      const periodEnd = getLastOfMonthInUtc(formValues.accountingPeriod[1])

      await createRevenueValueMutation({
        revenueId: revenue.id,
        organizationId: revenue.organizationId,
        businessUnitId: revenue.businessUnitId,
        periodStart,
        periodEnd,
        value: formValues.annualRevenue,
      })
        .unwrap()
        .catch(() => {
          message.error(t('revenue.form.save-error-message'))
        })

      form.resetFields()
    },
    [createRevenueValueMutation, form, revenue, t],
  )

  return (
    <>
      <ValueForm form={form} revenue={revenue} onFinish={onFinish} />
      <Space direction="horizontal" size={'small'} className={classes.paddingTop} wrap>
        <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
          {t('revenue.form.save')}
        </Button>

        <Button type="secondary" htmlType="reset" onClick={() => form.resetFields()}>
          {t('revenue.form.clear')}
        </Button>
      </Space>
    </>
  )
}
