export const SLICE_NAME_BUSINESS_UNITS = 'businessUnits'

export const TAG_BUSINESS_UNITS = 'BUSINESS_UNITS'
export const TAG_PAGINATED_BUSINESS_UNITS = 'BUSINESS_UNITS'
export const TAG_ACTIVE_BUSINESS_UNITS = 'BUSINESS_UNITS'
export const TAG_ORGANIGRAM = 'ORGANIGRAM'
export const TAG_BUSINESS_UNIT_TREE = 'BUSINESS_UNIT_TREE'

export default [
  TAG_BUSINESS_UNITS,
  TAG_ACTIVE_BUSINESS_UNITS,
  TAG_PAGINATED_BUSINESS_UNITS,
  TAG_ORGANIGRAM,
  TAG_BUSINESS_UNIT_TREE,
]
