/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Collapse, Drawer, Form, Space } from 'antd/es'

import Divider from '@/atoms/Divider'

import classes from './FilterDrawerForm.module.less'
import { FilterDrawerFormInput } from './FilterDrawerFormInput'
import {
  FilterValueType,
  FilterValues,
  FormInput,
  SearchSelectFiltersOptions,
  SearchSelectFiltersOptionsLoading,
  SearchSelectFiltersOptionsOnSearch,
} from './types'

export type FilterDrawerFormProps<
  TSearchSelectFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TDateRangePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
  TSingleSelectFiltersValuesKey extends string,
> = {
  open: boolean
  onClose: () => void
  value: FilterValues<
    TSearchSelectFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TDateRangePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey,
    TSingleSelectFiltersValuesKey
  >
  onChange: (
    value: FilterValues<
      TSearchSelectFiltersValuesKey,
      TSearchLogEntryDataQualitySelectFiltersValuesKey,
      TDatePickerFiltersValuesKey,
      TDateRangePickerFiltersValuesKey,
      TTextFiltersValuesKey,
      TLogicOperatorFiltersValuesKey,
      TSingleSelectFiltersValuesKey
    >,
  ) => void
  onSubmit: (
    value: FilterValues<
      TSearchSelectFiltersValuesKey,
      TSearchLogEntryDataQualitySelectFiltersValuesKey,
      TDatePickerFiltersValuesKey,
      TDateRangePickerFiltersValuesKey,
      TTextFiltersValuesKey,
      TLogicOperatorFiltersValuesKey,
      TSingleSelectFiltersValuesKey
    >,
  ) => void
  inputs: FormInput<
    TSearchSelectFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TDateRangePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey,
    TSingleSelectFiltersValuesKey
  >[]
  defaultValues?: FilterValues<
    TSearchSelectFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TDateRangePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey,
    TSingleSelectFiltersValuesKey
  >
  options: SearchSelectFiltersOptions<TSearchSelectFiltersValuesKey>
  isLoading?: SearchSelectFiltersOptionsLoading<TSearchSelectFiltersValuesKey>
  onSearch?: SearchSelectFiltersOptionsOnSearch<TSearchSelectFiltersValuesKey>
  visibleColumnsKeys?: string[]
}

export const FilterDrawerForm = <
  TSearchSelectFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TDateRangePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
  TSingleSelectFiltersValuesKey extends string,
>({
  onClose,
  open,
  value: formValue,
  onChange,
  onSubmit,
  inputs: allInputs,
  defaultValues = {},
  options,
  isLoading,
  onSearch,
  visibleColumnsKeys = [],
}: FilterDrawerFormProps<
  TSearchSelectFiltersValuesKey,
  TSearchLogEntryDataQualitySelectFiltersValuesKey,
  TDatePickerFiltersValuesKey,
  TDateRangePickerFiltersValuesKey,
  TTextFiltersValuesKey,
  TLogicOperatorFiltersValuesKey,
  TSingleSelectFiltersValuesKey
>): React.ReactElement => {
  const { t } = useTranslation()

  const saveFilters = useCallback(() => {
    onSubmit(formValue)
    onClose()
  }, [formValue, onClose, onSubmit])

  const resetFilters = useCallback(() => {
    onChange(defaultValues)
  }, [onChange, defaultValues])

  const handleSelectChange = useCallback(
    (
      value: FilterValueType | undefined,
      key: keyof FilterValues<
        TSearchSelectFiltersValuesKey,
        TSearchLogEntryDataQualitySelectFiltersValuesKey,
        TDatePickerFiltersValuesKey,
        TTextFiltersValuesKey,
        TLogicOperatorFiltersValuesKey,
        TSingleSelectFiltersValuesKey,
        TDateRangePickerFiltersValuesKey
      >,
    ): void => {
      onChange({ ...formValue, [key]: value })
    },
    [formValue, onChange],
  )

  const handleDrawerClose = useCallback(() => {
    onClose()
  }, [onClose])

  const [inputs, hiddenInputs] = React.useMemo(() => {
    const getMappedKey = (key: string): string => {
      if (['inputValue', 'inputUnit'].includes(key)) {
        return 'input1Value'
      }

      return key
    }

    return [
      allInputs
        .filter((input) => visibleColumnsKeys.includes(getMappedKey(input.key)))
        .sort(
          (a, b) =>
            visibleColumnsKeys.indexOf(getMappedKey(a.key)) -
            visibleColumnsKeys.indexOf(getMappedKey(b.key)),
        ),
      allInputs.filter((input) => !visibleColumnsKeys.includes(getMappedKey(input.key))),
    ]
  }, [allInputs, visibleColumnsKeys])

  return (
    <Drawer
      title={t('act.analytics.hotspotAnalysis.filters.title')}
      onClose={handleDrawerClose}
      open={open}
      footer={
        <Space>
          <Button onClick={resetFilters} data-cy="drawer-filters-clear">
            {t('log.filter.clear')}
          </Button>

          <Button type="primary" onClick={() => saveFilters()} data-cy="drawer-filters-apply">
            {t('log.filter.save')}
          </Button>
        </Space>
      }
      footerStyle={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      <Form layout="vertical">
        {inputs.map((input, index) => {
          return (
            <FilterDrawerFormInput
              key={index}
              input={input}
              onChange={(value) => handleSelectChange(value, input.key)}
              isLoading={isLoading}
              onSearch={onSearch}
              options={options}
              value={formValue}
            />
          )
        })}
        {hiddenInputs.length > 0 && (
          <>
            <Divider color="light" />
            <Collapse ghost className={classes.hiddenColumnsCollapse}>
              <Collapse.Panel header={t('log.log-entries-drawer.hidden-filters')} key="1">
                {hiddenInputs.map((input, index) => {
                  return (
                    <FilterDrawerFormInput
                      key={index}
                      input={input}
                      onChange={(value) => handleSelectChange(value, input.key)}
                      isLoading={isLoading}
                      onSearch={onSearch}
                      options={options}
                      value={formValue}
                    />
                  )
                })}
              </Collapse.Panel>
            </Collapse>
          </>
        )}
      </Form>
    </Drawer>
  )
}
