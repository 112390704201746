import { useEffect, useState } from 'react'
import { Location, useNavigate, useParams } from 'react-router-dom'

import { message } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

import { FactorRequestResponseFileDto } from '@cozero/dtos'
import { FactorRequestDetailsView, Organization } from '@cozero/models'
import { routes } from '@cozero/utils'

import { ISignedFilesUrl } from '@/pages/Share/SupplierEngagement/Cbam/components/FileUploader/FileUploader'

import { useFactorContext } from '@/contexts/factor'
import { useAppSelector } from '@/redux'
import { selectUser, selectUserOrganization } from '@/redux/auth'
import { AuthState } from '@/redux/auth/slice'
import {
  useFindFactorRequestByIdQuery,
  useUpdateOneFactorRequestMutation,
} from '@/redux/factors-requests'
import { useUpdateOneFactorRequestResponseMutation } from '@/redux/factors-requests-responses'
import { useUploadFileToSignedUrlMutation } from '@/redux/files'
import { useGetSignedUrlMutation } from '@/redux/files'

import { useCbamOnboarding } from './useCbamOnboarding'
import { UseGhgOnboardingReturn, useGhgOnboarding } from './useGhgOnboarding'

export interface IProductLifecycleEmission {
  key: string
  value: undefined | number
  id: number
}
type StepType = 'STEP_1' | 'STEP_2'

export interface UseSupplierOnboardingArgs {
  location: Location
}

export interface CommonSupplierOnboardingProps {
  goToCreatePage: () => Promise<void>
  onSubmit: () => Promise<void>
}

export type UseSupplierOnboardingReturn = CommonSupplierOnboardingProps &
  UseGhgOnboardingReturn & {
    user: AuthState['user']
    organization: Organization | undefined
    factorRequest: FactorRequestDetailsView | undefined
    isSuccess: boolean
    showProductCreatedModal: boolean
    currentStep: StepType
    comment: string
    fileList: UploadFile[]
    factorRequestIsSubmitting: boolean
    closeShareModal: () => void
    setComment: (value: string) => void
    onUpload: ({ fileList }: UploadChangeParam<UploadFile<unknown>>) => void
    handleDeleteAttachment: (params: UploadChangeParam<UploadFile<unknown>>) => void
    setShowProductCreatedModal: (value: boolean) => void
  }

const useSupplierOnboarding = ({
  location,
}: UseSupplierOnboardingArgs): UseSupplierOnboardingReturn => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [comment, setComment] = useState<string>('')

  const [showProductCreatedModal, setShowProductCreatedModal] = useState(false)
  const [getSignedUrl, { isLoading: isFetchingSignedUrl }] = useGetSignedUrlMutation()

  const user = useAppSelector(selectUser)
  const organization = useAppSelector(selectUserOrganization)

  const [saveFactorRequest, { isSuccess: hasUpdated, isLoading: factorRequestIsUpdating }] =
    useUpdateOneFactorRequestMutation()
  const [uploadFileToSignedUrl, { isLoading: fileIsUploading }] = useUploadFileToSignedUrlMutation()
  const [updateFactorRequestResponse, { isLoading: responseIsUpdating }] =
    useUpdateOneFactorRequestResponseMutation()

  const { data: factorRequest } = useFindFactorRequestByIdQuery(id as string, {
    skip: !id,
  })
  const { setFactorRequest } = useFactorContext()
  useEffect(() => {
    if (factorRequest) {
      setFactorRequest(factorRequest)
    }
  }, [factorRequest])

  const [fileList, setFileList] = useState<(UploadFile & { path?: string | null })[]>([])

  useEffect(() => {
    const initialTemplates = factorRequest?.factorRequestResponse?.files ?? []

    setFileList(
      initialTemplates.map((t) => ({
        uid: t.id.toString(),
        name: t.name ?? '',
        url: t.url ?? '',
        path: t.path,
      })),
    )
  }, [factorRequest])

  const getInitialStep = (): StepType => {
    if (location.state && 'step' in location.state) {
      return location.state.step as StepType
    }

    return 'STEP_1'
  }

  const factorRequestIsSubmitting =
    isFetchingSignedUrl || responseIsUpdating || factorRequestIsUpdating || fileIsUploading

  const [currentStep, setCurrentStep] = useState<StepType>(getInitialStep)
  useEffect(() => {
    if (!location.state?.step) {
      setCurrentStep(factorRequest?.stepsCompleted ? 'STEP_2' : 'STEP_1')
    }
  }, [factorRequest?.stepsCompleted, location.state])

  const getFilesSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<ISignedFilesUrl[]> => {
    const urls = await getSignedUrl(newFileList.map(({ name }) => name)).unwrap()

    return newFileList.map((x, index) => ({
      name: x.name,
      fileObj: x.originFileObj,
      signedUrl: urls[index].signedUrl,
      path: urls[index].path,
    }))
  }

  const uploadAttachments = async (): Promise<ISignedFilesUrl[]> => {
    try {
      let attachments: ISignedFilesUrl[] = []

      if (factorRequest && fileList.length > 0) {
        attachments = await getFilesSignedUrl(fileList)

        await Promise.all(attachments.map(async (file) => await uploadFileToSignedUrl(file)))
      }

      return attachments
    } catch (error) {
      message.error('share.supplier-engagement.share-step.errors.attachments')
    }

    return []
  }

  const handleDeleteAttachment: UseSupplierOnboardingReturn['handleDeleteAttachment'] = async ({
    file,
  }) => {
    const deletedAttachment = factorRequest?.factorRequestResponse?.files.find(
      (f) => f.url === file.url,
    )

    const newList = fileList.filter((f) => f.url !== file.url)
    if (deletedAttachment && factorRequest?.factorRequestResponse) {
      await updateFactorRequestResponse({
        id: factorRequest?.factorRequestResponse?.id,
        data: {
          attachments: newList.reduce<Array<FactorRequestResponseFileDto>>(
            (attachments, { name, path }) => {
              if (path) {
                attachments.push({ name, path })
              }

              return attachments
            },
            [],
          ),
        },
      })
    }

    setFileList(newList)
  }

  const ghgStore = useGhgOnboarding({
    location,
    organization,
    factorRequest,
    fileList,
    comment,
    uploadAttachments,
    saveFactorRequest,
  })

  const cbamStore = useCbamOnboarding({
    factorRequest,
    comment,
    updateFactorRequestResponse,
    saveFactorRequest,
    uploadAttachments,
  })

  const closeShareModal = (): void => navigate(routes.log.factors.requestsPage.incomingRequest)

  const onUpload = ({ fileList: newFileList }: UploadChangeParam<UploadFile<unknown>>): void => {
    setFileList(newFileList)
  }

  useEffect(() => {
    if (location.state && (location.state as { showCompleteModal: boolean }).showCompleteModal) {
      window.history.replaceState({}, document.title)
      setShowProductCreatedModal(
        (location.state as { showCompleteModal: boolean }).showCompleteModal,
      )
    }
  }, [location.state])

  return {
    user,
    factorRequest,
    organization,
    currentStep,
    isSuccess: hasUpdated,
    showProductCreatedModal,
    setShowProductCreatedModal,
    comment,
    setComment,
    fileList,
    onUpload,
    closeShareModal,
    handleDeleteAttachment,
    ...ghgStore,
    factorRequestIsSubmitting,
    goToCreatePage:
      factorRequest?.type === 'CBAM' ? cbamStore.goToCreatePage : ghgStore.goToCreatePage,
    onSubmit: factorRequest?.type === 'CBAM' ? cbamStore.onSubmit : ghgStore.onSubmit,
  }
}

export default useSupplierOnboarding
