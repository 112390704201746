import {
  CreateRevenueDto,
  CreateRevenueValueDto,
  RevenueDto,
  RevenueValueDto,
  UpdateRevenueValueDto,
} from '@cozero/dtos'
import { centralApiGatewayClient } from '@cozero/uris'

import { TAG_REVENUE } from '@/redux/revenues/tags'

import apiSlice from '../api'

const revenuesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRevenue: builder.query<RevenueDto, void>({
      query: () => ({
        url: centralApiGatewayClient.revenue.GET,
        method: 'GET',
      }),
      providesTags: [TAG_REVENUE],
    }),
    createRevenue: builder.mutation<RevenueDto, CreateRevenueDto>({
      query: (data) => ({
        url: centralApiGatewayClient.revenue.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG_REVENUE],
    }),
    createRevenueValue: builder.mutation<RevenueValueDto, CreateRevenueValueDto>({
      query: (data) => ({
        url: centralApiGatewayClient.revenue.CREATE_VALUE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG_REVENUE],
    }),
    updateRevenueValue: builder.mutation<RevenueValueDto, UpdateRevenueValueDto>({
      query: (data) => ({
        url: centralApiGatewayClient.revenue.EDIT_VALUE.replace(':id', data.id.toString()),
        method: 'PUT',
        data,
      }),
      invalidatesTags: [TAG_REVENUE],
    }),
    deleteRevenueValue: builder.mutation<RevenueValueDto, number>({
      query: (id) => ({
        url: centralApiGatewayClient.revenue.DELETE_VALUE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_REVENUE],
    }),
  }),
})

export const {
  useGetRevenueQuery,
  useCreateRevenueMutation,
  useCreateRevenueValueMutation,
  useUpdateRevenueValueMutation,
  useDeleteRevenueValueMutation,
} = revenuesApiSlice

export default revenuesApiSlice
