import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Drawer, Space } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'

import { random } from 'lodash-es'

import { LogType, PageFilter, PageType, RelationshipFilter } from '@cozero/models'

import Alert from '@/atoms/Alert'
import CloseIcon from '@/atoms/CloseIcon'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppDispatch } from '@/redux'
import {
  resetFactorsFilters,
  resetLocationListFilters,
  resetLocationsFilters,
  resetProductsFilters,
  setProductsFilters,
} from '@/redux/filters/slice'

import ClimateActionsFiltersForm from './ClimateActionsFiltersForm'
import EmissionFactorsFiltersForm from './EmissionFactorsFiltersForm'
import LocationFiltersForm from './LocationFiltersForm'
import LocationListFiltersForm from './LocationListFilters'
import ProductFiltersForm from './ProductFiltersForm'
import QuantitiesFiltersForm from './QuantitiesFiltersForm'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

export type FilterValue = {
  [FilterKey.CATEGORY]: number[]
  [FilterKey.LOCATION]: number[]
  [FilterKey.BUSINESS_UNIT]: number[]
  [FilterKey.PRODUCT]: number[]
  [FilterKey.PRODUCT_TAGS]: string[]
  [FilterKey.PRODUCT_TYPE]: number[]
  [FilterKey.RESPONSIBLE]: number[]
  [FilterKey.TAG]: string[]
  [FilterKey.CUSTOMER]: number[]
  [FilterKey.SUPPLIER]: number[]
  [FilterKey.DESCRIPTION]: string
  [FilterKey.SCOPE]: number[]
  [FilterKey.ACTION_AREA]: number[]
  [FilterKey.QUANTITY_TAGS]: string[]
  [FilterKey.EXTERNAL_ID]: string[]
  [FilterKey.ACTIVITY_DATA_SOURCE]: number[]
  [FilterKey.TERRITORY]: number[]
  [FilterKey.UNIT]: number[]
  [FilterKey.FACTOR_ORIGIN]: number[]
  [FilterKey.ACTIVE]: boolean
}

export enum FilterKey {
  CATEGORY = 'categoryId',
  PRODUCT_TYPE = 'productTypeId',
  EXTERNAL_ID = 'externalId',
  PRODUCT_TAGS = 'tags',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  PRODUCT = 'productId',
  RESPONSIBLE = 'ownerId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DESCRIPTION = 'description',
  TAG = 'tag',
  QUANTITY_TAGS = 'quantityTags',
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
  SCOPE = 'scope',
  ACTION_AREA = 'actionArea',
  TERRITORY = 'territory',
  UNIT = 'unit',
  ACTIVITY_DATA_SOURCE = 'activityDataSource',
  FACTOR_ORIGIN = 'origin',
  ACTIVE = 'active',
}

const MAX_FILTERS_ALLOWED = 2

const defaultFilterDrawerValues = {
  [FilterKey.CATEGORY]: [],
  [FilterKey.LOCATION]: [],
  [FilterKey.BUSINESS_UNIT]: [],
  [FilterKey.PRODUCT]: [],
  [FilterKey.PRODUCT_TYPE]: [],
  [FilterKey.PRODUCT_TAGS]: [],
  [FilterKey.RESPONSIBLE]: [],
  [FilterKey.TAG]: [],
  [FilterKey.CUSTOMER]: [],
  [FilterKey.SUPPLIER]: [],
  [FilterKey.DESCRIPTION]: '',
  [FilterKey.SCOPE]: [],
  [FilterKey.ACTION_AREA]: [],
  [FilterKey.QUANTITY_TAGS]: [],
  [FilterKey.EXTERNAL_ID]: [],
  [FilterKey.ACTIVITY_DATA_SOURCE]: [],
  [FilterKey.TERRITORY]: [],
  [FilterKey.UNIT]: [],
  [FilterKey.FACTOR_ORIGIN]: [],
  [FilterKey.ACTIVE]: true,
}

export interface FiltersDrawerProps {
  visible: boolean
  search: (filters: PageFilter[]) => void
  onClose: () => void
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  filters?: (PageFilter & { key: FilterKey })[]
  pageType?: LogType | PageType
  setFilterCount?: (count: number) => void
  isGraphLevel?: boolean
}

const FiltersDrawer = ({
  visible,
  filterOptions,
  pageType,
  filters: initialFilters,
  search,
  onClose,
  setFilterCount,
  isGraphLevel = false,
}: FiltersDrawerProps): ReactElement => {
  const { t } = useTranslation('')
  const [filters, setFilters] = useState<(PageFilter & { key: FilterKey })[]>(initialFilters || [])
  const [currentValues, setCurrentValues] = useState<FilterValue>(defaultFilterDrawerValues)
  const dispatch = useAppDispatch()
  const { isFeatureEnabled } = usePricingFeature()
  const showMultipleFiltersNotAllowedError =
    !isFeatureEnabled('multiple-filtering') && filters.length > MAX_FILTERS_ALLOWED

  useEffect(() => {
    if (initialFilters && visible) {
      setFilters(initialFilters)
    }
  }, [initialFilters, visible])

  const onChangeCondition = (
    filter: RelationshipFilter & { key: FilterKey },
    value: Select.value | string | string[],
  ): void => {
    let index = filters.findIndex((x) => x.key === filter.key)

    const selectedCondition = filter.conditions?.find((obj) => obj.key === value)
    const newFilter = {
      ...filter,
      selectedCondition,
    }
    if (index === -1) {
      newFilter.id = random(10000)
      index = filters.length
    }
    const newFilters = [...filters]
    newFilters[index] = newFilter
    return setFilters(newFilters)
  }

  const onChange = (
    filter: PageFilter & { key: FilterKey },
    value: Select.value | string | string[],
  ): void => {
    let index = filters.findIndex((x) => x.key === filter.key)
    const existingFilter = filters.find((x) => x.key === filter.key)

    // Delete filter if value is empty
    if (value.length === 0 && index !== -1) {
      setCurrentValues({ ...currentValues, [filter.key]: value })
      setFilters(filters.filter((x) => x.key !== filter.key))
      return
    }
    const selectedCondition =
      typeof value === 'string'
        ? { key: 'contains', label: 'Contains' }
        : existingFilter?.selectedCondition || {
            key: 'in',
            label: 'Is',
          }
    const newFilter = {
      ...filter,
      selectedCondition,
      pageType,
      value: value as never,
    }
    if (index === -1) {
      newFilter.id = random(10000)
      index = filters.length
    }

    const dateFilters = filters.filter(
      (filter) => filter.type === 'date' || filter.type === 'dateRange',
    )

    let newFilters = [...filters]

    newFilters[index] = { ...newFilter, logType: pageType as LogType }

    newFilters = [
      ...newFilters.filter((filter) => filter.type !== 'date' && filter.type !== 'dateRange'),
    ]

    if (setFilterCount) {
      setFilterCount(newFilters.length)
    }

    return setFilters([...newFilters.filter(Boolean), ...dateFilters])
  }

  const resetFilters = (): void => {
    setCurrentValues({
      [FilterKey.CATEGORY]: [],
      [FilterKey.PRODUCT_TYPE]: [],
      [FilterKey.PRODUCT_TAGS]: [],
      [FilterKey.LOCATION]: [],
      [FilterKey.BUSINESS_UNIT]: [],
      [FilterKey.PRODUCT]: [],
      [FilterKey.RESPONSIBLE]: [],
      [FilterKey.TAG]: [],
      [FilterKey.QUANTITY_TAGS]: [],
      [FilterKey.CUSTOMER]: [],
      [FilterKey.SUPPLIER]: [],
      [FilterKey.DESCRIPTION]: '',
      [FilterKey.SCOPE]: [],
      [FilterKey.ACTION_AREA]: [],
      [FilterKey.EXTERNAL_ID]: [],
      [FilterKey.ACTIVITY_DATA_SOURCE]: [],
      [FilterKey.TERRITORY]: [],
      [FilterKey.UNIT]: [],
      [FilterKey.FACTOR_ORIGIN]: [],
      [FilterKey.ACTIVE]: true,
    })
  }

  useEffect(() => {
    if (filters.length === 0) {
      resetFilters()
      return
    }

    setCurrentValues((prevValues) => {
      const newValues = filters.reduce((acc, filter) => {
        if (
          filter.key !== FilterKey.START_DATE &&
          filter.key !== FilterKey.END_DATE &&
          filter.type !== 'dateRange'
        ) {
          switch (filter.key) {
            case FilterKey.DESCRIPTION:
              acc[filter.key] = filter.value as string
              break
            case FilterKey.TAG:
            case FilterKey.PRODUCT_TAGS:
            case FilterKey.QUANTITY_TAGS:
              acc[filter.key] = filter.value as string[]
              break
            case FilterKey.ACTIVE:
              acc[filter.key] = filter.value as boolean
              break
            default:
              if (Array.isArray(filter.value)) {
                acc[filter.key] = (filter.value as (number | string)[]).map((x) =>
                  typeof x === 'number' && !isNaN(x) ? +x : x,
                ) as number[] & string[]
              }
          }
        }
        return acc
      }, {} as Partial<FilterValue>)

      // Merge with prevValues, resetting keys not in filters
      return Object.keys(prevValues).reduce<FilterValue>(
        (acc, key) => {
          const typedKey = key as keyof FilterValue
          if (typedKey in newValues) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[typedKey] = newValues[typedKey] as FilterValue[keyof FilterValue]
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[typedKey] = (
              Array.isArray(prevValues[typedKey])
                ? []
                : typeof prevValues[typedKey] === 'boolean'
                ? true
                : ''
            ) as FilterValue[keyof FilterValue]
          }
          return acc
        },
        { ...prevValues } as FilterValue,
      )
    })
  }, [filters])

  const saveFilters = (): void => {
    if (showMultipleFiltersNotAllowedError) {
      return
    }
    search && search([...filters])
    if (!isGraphLevel) {
      switch (pageType) {
        case PageType.PRODUCT:
          dispatch(setProductsFilters(filters))
          break
        case PageType.FACTOR:
          dispatch(setProductsFilters(filters))
          break
        case PageType.LOCATION:
          dispatch(setProductsFilters(filters))
          break
        case PageType.LOCATION_LIST:
          dispatch(setProductsFilters(filters))
          break

        default:
          break
      }
    }
    onClose()
  }

  const clearFilters = (): void => {
    switch (pageType) {
      case PageType.PRODUCT:
        dispatch(resetProductsFilters())
        break
      case PageType.FACTOR:
        dispatch(resetProductsFilters()) // dont ask me why...
        dispatch(resetFactorsFilters())
        break
      case PageType.LOCATION:
        dispatch(resetLocationsFilters())
        break
      case PageType.LOCATION_LIST:
        dispatch(resetLocationListFilters())
        break
      default:
        break
    }
    setFilters([])
    resetFilters()
    search && search([])
    onClose()
  }

  return (
    <>
      <Drawer
        title={t('log.filter.title')}
        placement="right"
        onClose={onClose}
        open={visible}
        closeIcon={<CloseIcon />}
        footer={
          <Space>
            <Button onClick={clearFilters}>{t('log.filter.clear')}</Button>
            <Button
              disabled={showMultipleFiltersNotAllowedError || filters.length === 0}
              type="primary"
              onClick={saveFilters}
            >
              {t('log.filter.save')}
            </Button>
          </Space>
        }
        footerStyle={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <>
          {showMultipleFiltersNotAllowedError && (
            <Alert
              type="error"
              title={t('log.filter.multiple-disabled-title')}
              message={t('log.filter.multiple-disabled')}
            />
          )}
          {pageType === PageType.PRODUCT ? (
            <ProductFiltersForm
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === PageType.LOCATION ? (
            <LocationFiltersForm
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
              onChangeCondition={(...args) => onChangeCondition(...args)}
            />
          ) : pageType === PageType.DASHBOARD ? (
            <LocationFiltersForm
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
              onChangeCondition={(...args) => onChangeCondition(...args)}
              hideFilters={[FilterKey.RESPONSIBLE]}
            />
          ) : pageType === PageType.FACTOR ? (
            <EmissionFactorsFiltersForm
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === PageType.LOCATION_LIST ? (
            <LocationListFiltersForm
              filters={filters}
              values={currentValues}
              onChange={(...args) => onChange(...args)}
            />
          ) : pageType === 'climateActions' ? (
            <ClimateActionsFiltersForm
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          ) : (
            <QuantitiesFiltersForm
              filters={filters}
              values={currentValues}
              filterOptions={filterOptions}
              onChange={(...args) => onChange(...args)}
            />
          )}
        </>
      </Drawer>
    </>
  )
}

export default React.memo(FiltersDrawer)
