import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDuplicate, HiOutlineExclamation, HiOutlineTrash } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Image, Popconfirm, Space, Typography } from 'antd/es'

import moment from 'moment'

import { LogSorter, Product, ProductTag, TableViewProductLog } from '@cozero/models'
import { routes } from '@cozero/utils'

import DotsMenu, { DotsMenuItem } from '@/molecules/DotsMenu'

import Tag from '@/atoms/Tag'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import useGenerateData, { GenerateColumn } from '@/hooks/useGenerateData'
import useLog from '@/hooks/useLog'
import useProducts from '@/hooks/useProducts'
import i18n from '@/i18n'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

const useProductTableColumns = ({
  tableSorter,
}: {
  tableSorter: LogSorter[] | undefined
  businessUnitsAllowed: boolean
}): { columnsData: GenerateColumn<TableViewProductLog>[]; deletedProduct: Product | undefined } => {
  const { t } = useTranslation('common')
  const { getInitialOrder } = useGenerateData()
  const [deletedProduct, setDeletedProduct] = useState<Product | undefined>()
  const { deleteProduct: deleteProductApi, duplicateProduct: duplicateProductApi } = useProducts()
  const navigate = useNavigate()
  const { getProductTags } = useLog()
  const [productTags, setProductTags] = useState<Map<number, ProductTag>>(new Map())

  useEffect(() => {
    getProductTags('').then((productTagsResult) => {
      const newMap = new Map<number, ProductTag>()
      productTagsResult.forEach((tag) => {
        newMap.set(tag.id, tag)
      })

      setProductTags(newMap)
    })
  }, [])

  const deleteProduct = async (productId: number): Promise<void> => {
    const product = await deleteProductApi(productId)
    setDeletedProduct(product)
  }

  const duplicateProduct = async (productId: number): Promise<void> => {
    const product = await duplicateProductApi(productId)

    if (product) {
      const url = routes.log.products.productDetails.replace(':productId', product.id.toString())

      navigate(url)
    }
  }

  const productTypeNameField = `productTypeName${
    i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)
  }`

  const columnsData: GenerateColumn<TableViewProductLog>[] = useMemo(
    () => [
      {
        title: () => <span className={classes.columnTitle}>{t('product.productName')}</span>,
        dataIndex: 'product.name',
        key: 'name',
        sorter: true,
        sortOrder: getInitialOrder('name', tableSorter),
        width: 200,
        render(_, product) {
          return (
            <span className={classes.row}>
              {product.image?.url && (
                <Image
                  width={30}
                  height={30}
                  src={product.image?.url}
                  preview={false}
                  className={classes.icon}
                />
              )}
              <Typography.Text className={classes.tableContent}>
                {truncate(product.name as string, 20)}
              </Typography.Text>
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.product-code')}</span>,
        dataIndex: 'product.code',
        key: 'code',
        sorter: true,
        sortOrder: getInitialOrder('code', tableSorter),
        width: 150,

        render(_, product) {
          return (
            <span className={classes.row}>
              {product.code && (
                <Tag truncateText size="sm" className={classes.tableContent}>
                  {product.code as string}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.product-version')}</span>,
        dataIndex: 'product.version',
        key: 'version',
        sorter: true,
        sortOrder: getInitialOrder('version', tableSorter),
        width: 150,

        render(_, product) {
          return (
            <span className={classes.row}>
              {product.version && (
                <Tag truncateText size="sm" className={classes.tableContent}>
                  {product.version as string}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.productType')}</span>,
        dataIndex: `product.${productTypeNameField}`,
        key: productTypeNameField,
        sorter: true,
        sortOrder: getInitialOrder(productTypeNameField, tableSorter),
        width: 150,
        render(_, product: TableViewProductLog) {
          const productTypeName = productTypeNameField.endsWith('De')
            ? product.productTypeNameDe
            : product.productTypeNameEn

          return (
            <span className={classes.row}>
              {productTypeName && (
                <Tag className={classes.tableContent} truncateText size="sm">
                  {productTypeName}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('product.productTag')}</span>,
        dataIndex: 'tags',
        key: 'productTagIds',
        sorter: false,
        sortOrder: getInitialOrder('product.productTagIds', tableSorter),
        width: 300,

        render(_, product) {
          if (!product.productTagIds?.length) {
            return <></>
          }
          return (
            <span className={classes.row}>
              {product.productTagIds.slice(0, 3).map((id: number) => (
                <Tag className={classes.tag} truncateText size="xs" key={id}>
                  {productTags.get(id)?.name}
                </Tag>
              ))}
              {product.productTagIds.length > 3 && (
                <Tag className={classes.tag} truncateText size="xs">
                  +{product.productTagIds.length - 3}
                </Tag>
              )}
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.period')}</span>,
        dataIndex: 'period',
        key: 'endDate',
        sorter: true,
        sortOrder: getInitialOrder('endDate', tableSorter),
        width: 320,
        render(_, product) {
          return product?.startDate && product?.endDate ? (
            <Space className={classes.row}>
              <Tag className={classes.tableContent} size="xs">
                {moment(product?.startDate).utc().format('LL')}
              </Tag>
              <Typography className={classes.periodDivisor}>{t('to')}</Typography>
              <Tag className={classes.tableContent} size="xs">
                {moment(product?.endDate).utc().format('LL')}
              </Tag>
            </Space>
          ) : (
            <></>
          )
        },
      },
      {
        title: t('log.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 300,
        sorter: true,
        sortOrder: getInitialOrder('createdAt', tableSorter),
        render(_, record) {
          return <Tag className={classes.tag}>{moment(record.createdAt).format('LLL')}</Tag>
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.emissions-per-unit')}</span>,
        dataIndex: 'emissionsPerUnit',
        sorter: true,
        sortOrder: getInitialOrder('emissionsPerUnit', tableSorter),
        key: 'emissionsPerUnit',
        width: 150,
        render(value) {
          return (
            <span>
              <Typography.Text className={classes.emissions}>
                {formatNumber(convertTonnesToGrams((value as number) ?? 0))}
              </Typography.Text>
            </span>
          )
        },
      },
      {
        title: () => <span className={classes.columnTitle}>{t('log.total-emissions')}</span>,
        dataIndex: 'totalEmissions',
        sorter: true,
        sortOrder: getInitialOrder('totalEmissions', tableSorter),
        fixed: 'right',
        width: 150,
        key: 'totalEmissions',
        render(value, product) {
          const warningIcon = product.hasIssues ? (
            <Tooltip title={t('product.errors.hasIssues')}>
              <HiOutlineExclamation className={classes.warning} />
            </Tooltip>
          ) : null
          return (
            <div className={classes.emissionsWrapper}>
              <span className={classes.emissions}>{formatNumber((value as number) ?? 0)}</span>
              {warningIcon}
            </div>
          )
        },
      },
      {
        title: t('log.options-column'),
        key: 'action',
        fixed: 'right',
        width: 100,
        render(_, product) {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <DotsMenu
                dotType="vertical"
                placement="bottomRight"
                menu={{
                  className: classes.menu,
                  items: [
                    /*
                    // Code is commented until fix to avoid wrong data - Revert this change after fixing [IS-3546]
                    {
                      className: classes.menuGroup,
                      key: 'duplicate',
                      label: (
                        <DotsMenuItem
                          category={AnalyticsCategories.PRODUCT_LOGS}
                          action="duplicate"
                          icon={<HiOutlineDuplicate size={16} />}
                          onClick={() => duplicateProduct(product.id)}
                        >
                          {t('product.duplicate.title')}
                        </DotsMenuItem>
                      ),
                    },
                    */
                    {
                      className: classes.menuGroup,
                      key: 'delete',
                      label: (
                        <Popconfirm
                          placement="bottom"
                          title={t('product.delete.warning')}
                          onConfirm={() => deleteProduct(product.id)}
                          okText={t('yes')}
                          cancelText={t('no')}
                        >
                          <DotsMenuItem
                            category={AnalyticsCategories.PRODUCT_LOGS}
                            action="delete"
                            icon={<HiOutlineTrash size={16} />}
                            danger
                          >
                            {t('product.delete.title')}
                          </DotsMenuItem>
                        </Popconfirm>
                      ),
                    },
                  ],
                }}
              />
            </div>
          )
        },
      },
    ],
    [JSON.stringify(tableSorter), productTags],
  )

  return {
    columnsData,
    deletedProduct,
  }
}

export default useProductTableColumns
