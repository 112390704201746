import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router'

import { FormInstance, Row, message } from 'antd'

import { FactorRequestType } from '@prisma/client'
import _ from 'lodash'
import moment from 'moment'

import { SupplierContact } from '@cozero/models'
import { routes } from '@cozero/utils'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import FactorRequestForm from '@/organisms/FactorRequestForm'
import {
  FactorRequestUrlState,
  FormValues,
  InitialFormData,
  ParsedFormValues,
} from '@/organisms/FactorRequestForm/types'
import RequestFactorSuccessModal from '@/organisms/RequestFactorSuccessModal'

import LayoutFooter from '@/molecules/LayoutFooter'

import Form from '@/atoms/Form'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppContext } from '@/contexts/app'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useCreateOneFactorRequestMutation } from '@/redux/factors-requests'

import classes from './classes.module.less'

function FactorRequestFormPage(): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { type: requestTypeFromUrl } = useParams()
  const requestType = useMemo(
    () => requestTypeFromUrl?.toUpperCase() as FactorRequestType,
    [requestTypeFromUrl],
  )

  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { organizationSuppliers } = useAppContext()
  const user = useAppSelector(selectUser)

  const { isFeatureEnabled } = usePricingFeature()
  useEffect(() => {
    if (
      (requestType === 'CBAM' && !isFeatureEnabled('cbam')) ||
      (requestType && !Object.values(FactorRequestType).includes(requestType))
    ) {
      navigate(`${routes.log.factors.base}/404`)
    }
  }, [requestType])

  const location = useLocation()
  useEffect(() => {
    const { state } = location as { state: FactorRequestUrlState }
    if (state) {
      populateForm(state)
    }
  }, [location])

  const [initialValues, setInitialValues] = useState<InitialFormData[]>([
    {
      name: 'locale',
      value: 'en',
    },
  ])
  const [form, setForm] = useState<FormValues>()
  const [factorRequestForm]: FormInstance<FormValues>[] = Form.useForm()
  const [showSubmitModal, setShowSubmitModal] = useState(false)

  const [createFactorRequest, { isLoading: isSubmitting }] = useCreateOneFactorRequestMutation()

  const populateForm = ({ logEntry, emission }: FactorRequestUrlState): void => {
    const { supplierId, activityDataSourceId, territory } = logEntry
    const initialValuesObj: InitialFormData[] = [
      { name: 'supplierId', value: supplierId as number },
      { name: 'activityDataSourceId', value: [activityDataSourceId] },
      {
        name: 'territoryId',
        value:
          territory && territory.continentId ? [territory.continentId, territory.id] : undefined,
      },
      {
        name: 'denominatorUnitId',
        value: emission?.factor?.denominatorUnitId,
      },
      {
        name: 'year',
        value: moment(emission?.factor?.validityStartDate),
      },
    ]
    setInitialValues((prev) => [...prev, ...initialValuesObj])
  }

  const getFormValues = (values: FormValues): void => {
    setForm(values)
  }

  const parseForm = (form: FormValues): ParsedFormValues => {
    const parsedForm = _.cloneDeep({
      ...form,
      activityDataSourceId: 0,
      territoryId: 0,
      type: requestType?.toUpperCase() as FactorRequestType,
    })
    parsedForm.activityDataSourceId = form.activityDataSourceId.at(-1) as number
    if (form.territoryId?.length) {
      parsedForm.territoryId = form.territoryId.at(-1) as number
    }
    return parsedForm
  }

  const handleSave = async (): Promise<void> => {
    try {
      await factorRequestForm.validateFields()
      setShowSubmitModal(true)
    } catch (error) {
      if (error?.errorFields?.length) {
        message.error(
          t('suppliers.factor.form.validation-errors.default', { n: error.errorFields.length }),
        )
      }
    }
  }

  const handleModalSendPersonalEmail = (): void => {
    if (form) {
      // We assume that `organizationSuppliers` were loaded by `FactorRequestForm` and that
      // `form.supplierId` points into that list.
      const supplier = organizationSuppliers.find((supplier) => supplier.id === form.supplierId)!
      const supplierContacts = supplier.contact as SupplierContact[]
      const supplierContact = supplierContacts.find((contact) => contact.email == form.email)!

      window.open(
        `mailto:${form.email}?subject=${t('suppliers.factor.request-modal.email-subject', {
          lng: form.locale,
        })}&body=${t('suppliers.factor.request-modal.email-body.title', {
          supplier:
            supplierContact.name ??
            t('factors.supplier', {
              lng: form.locale,
            }),
          lng: form.locale,
        })}%0D%0A%0D%0A ${t('suppliers.factor.request-modal.email-body.paragraph-1', {
          organizationName: user?.organization?.name,
          supplierCompany: supplier.name,
          lng: form.locale,
        })}%0D%0A%0D%0A ${t('suppliers.factor.request-modal.email-body.paragraph-2', {
          lng: form.locale,
        })}%0D%0A%0D%0A${t('suppliers.factor.request-modal.email-body.paragraph-3', {
          lng: form.locale,
        })}
        %0D%0A%0D%0A${t('suppliers.factor.request-modal.email-body.signature', {
          user:
            user?.firstName && user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : user?.username,
          lng: form.locale,
        })}`,
      )
    }
  }

  const handleModalSendRequest = async (): Promise<void> => {
    if (form && selectedBusinessUnit) {
      try {
        await createFactorRequest({
          ...parseForm(form),
          businessUnitId: selectedBusinessUnit.id,
        }).unwrap()
        navigate(-2)
      } catch (error) {
        message.error(error.message)
      }
    }
  }

  const handleModalClose = (): void => {
    setShowSubmitModal(false)
  }

  return (
    <div className={classes.container}>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('suppliers.factor.request-general', {
              requestType: requestType?.toUpperCase(),
            })}
            onClose={() => navigate(-2)}
            goBack={() => navigate(-1)}
          />
        </div>
        <Row className={classes.content}>
          <LifecycleModalTitle
            image={productConfiguration}
            title={t('suppliers.factor.request-info.title')}
            description={t('suppliers.factor.request-info.description')}
          />
          <FactorRequestForm
            initialValues={initialValues}
            form={factorRequestForm}
            getFormValues={getFormValues}
            requestType={requestType}
          />
        </Row>
        <LayoutFooter
          hideCancelButton={false}
          categoryOkButton={AnalyticsCategories.FACTOR_REQUESTS}
          categoryCancelButton={AnalyticsCategories.FACTOR_REQUESTS}
          isDataValid={true}
          okButtonIcon={<HiOutlineMail />}
          onCancelClick={() => {
            navigate(-1)
          }}
          isLoading={isSubmitting}
          onSaveClick={handleSave}
          saveButtonText={t('suppliers.factor.send')}
          cancelButtonText={t('actions.back')}
          saveButtonDisabled={isSubmitting}
        />
      </div>
      <RequestFactorSuccessModal
        visible={showSubmitModal}
        onSendPersonalEmail={handleModalSendPersonalEmail}
        onSendRequest={handleModalSendRequest}
        handleModalClose={handleModalClose}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default FactorRequestFormPage
