import { SearchBusinessUnitsDto } from '@cozero/dtos'
import { BusinessUnit, BusinessUnitTreeNode } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_ACTIVE_BUSINESS_UNITS, TAG_BUSINESS_UNITS, TAG_BUSINESS_UNIT_TREE } from './constants'

const businessUnitsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessUnit: builder.query<BusinessUnit, { id: string; active?: boolean }>({
      query: ({ id, active }) => ({
        url: centralApiGatewayClient.businessUnits.GET_ONE.replace(':id', id),
        method: 'GET',
        params: {
          active,
        },
      }),
      providesTags: (result) => [{ type: TAG_BUSINESS_UNITS, id: result?.id }],
    }),
    getBusinessUnits: builder.query<BusinessUnit[], SearchBusinessUnitsDto>({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.SEARCH,
        method: 'POST',
        data,
        params: {
          active: data.active,
        },
      }),
      providesTags: (result) =>
        providesList<BusinessUnit[], typeof TAG_BUSINESS_UNITS>(result, TAG_BUSINESS_UNITS),
    }),
    getActiveBusinessUnits: builder.query<BusinessUnit[], SearchBusinessUnitsDto>({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.SEARCH,
        method: 'POST',
        data: {
          ...data,
          active: true,
        },
      }),
      providesTags: (result) =>
        providesList<BusinessUnit[], typeof TAG_ACTIVE_BUSINESS_UNITS>(
          result,
          TAG_ACTIVE_BUSINESS_UNITS,
        ),
    }),
    getBusinessUnitScope: builder.query<
      { selectedBusinessUnit: BusinessUnit; businessUnitTree: BusinessUnit[] },
      BusinessUnit['id']
    >({
      query: (id) => ({
        url: centralApiGatewayClient.businessUnits.GET_SCOPE,
        method: 'POST',
        params: {
          id,
        },
      }),
    }),
    createBusinessUnit: builder.mutation<BusinessUnit, Partial<BusinessUnit>>({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
      extraOptions: { maxRetries: 0 },
    }),

    editBusinessUnit: builder.mutation<BusinessUnit, { id: string; data: Partial<BusinessUnit> }>({
      query: ({ id, data }) => ({
        url: centralApiGatewayClient.businessUnits.UPDATE_ONE.replace(':id', id ?? ''),
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => [
        { type: TAG_BUSINESS_UNITS, id: result?.id },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: result?.id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    transferBusinessUnit: builder.mutation<
      BusinessUnit,
      { businessUnitId: BusinessUnit['id']; newParentId: BusinessUnit['id'] }
    >({
      query: ({ businessUnitId, newParentId }) => ({
        url: centralApiGatewayClient.businessUnits.TRANSFER.replace(
          ':id',
          `${businessUnitId}`,
        ).replace(':parentId', `${newParentId}`),
        method: 'POST',
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
    }),
    deleteBusinessUnit: builder.mutation<BusinessUnit, string>({
      query: (id) => ({
        url: centralApiGatewayClient.businessUnits.DELETE_ONE.replace(':id', id ?? ''),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    disableBusinessUnit: builder.mutation<BusinessUnit, string>({
      query: (id) => ({
        url: centralApiGatewayClient.businessUnits.DISABLE.replace(':id', id ?? ''),
        method: 'PUT',
      }),
      invalidatesTags: (result) => [
        { type: TAG_BUSINESS_UNITS, id: result?.id },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: result?.id },
        TAG_BUSINESS_UNIT_TREE,
      ],
    }),
    getUserBusinessUnitsForest: builder.query<BusinessUnitTreeNode[], void>({
      query: () => ({
        url: centralApiGatewayClient.businessUnits.GET_USER_BUSINESS_UNITS_FOREST,
        method: 'GET',
      }),
      providesTags: [TAG_BUSINESS_UNIT_TREE],
    }),
  }),
})

export const {
  useGetBusinessUnitQuery,
  useLazyGetBusinessUnitQuery,
  useGetBusinessUnitsQuery,
  useGetActiveBusinessUnitsQuery,
  useLazyGetActiveBusinessUnitsQuery,
  useCreateBusinessUnitMutation,
  useEditBusinessUnitMutation,
  useDeleteBusinessUnitMutation,
  useDisableBusinessUnitMutation,
  useTransferBusinessUnitMutation,
  usePrefetch,
  useGetUserBusinessUnitsForestQuery,
} = businessUnitsApiSlice

export default businessUnitsApiSlice
