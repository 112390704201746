import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PageFilter } from '@cozero/models'

import { useAppContext } from '@/contexts/app'
import { useLogContext } from '@/contexts/log'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import useTerritories from '@/hooks/useTerritories'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useGetOrganizationUsersFilteredByBusinessUnitQuery } from '@/redux/organizations'
import { createFilterOptions } from '@/utils/filters'

export const useFilters = (): {
  filterOptions: PageFilter[]
  featuresAllowed: string[]
} => {
  const { t } = useTranslation()
  const { featuresAllowed } = usePricingFeature()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { products, suppliers } = useAppContext()

  const { customers, tags, productTags } = useLogContext()
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { data: locations } = useGetActiveLocationsQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { data: categories } = useGetCategoriesQuery()
  const { data: users } = useGetOrganizationUsersFilteredByBusinessUnitQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit?.id },
  )
  const { territories } = useTerritories()
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(
    createFilterOptions({
      type: 'location',
      categories,
      users,
      businessUnits,
      locations,
      products,
      customers,
      suppliers,
      tags,
      t,
      featuresAllowed,
      territories,
      productTags,
      shownOptions: [
        'locations',
        'businessUnits',
        'owners',
        'categories',
        'descriptions',
        'customers',
        'suppliers',
        'tags',
        'territories',
        'productTags',
      ],
      page: 'reports',
    }) ?? [],
  )

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'location',
        categories,
        users,
        businessUnits,
        locations,
        products,
        tags,
        customers,
        suppliers,
        t,
        productTags,
        territories,
        featuresAllowed,
        shownOptions: [
          'locations',
          'businessUnits',
          'owners',
          'categories',
          'descriptions',
          'products',
          'customers',
          'suppliers',
          'tags',
          'territories',
          'productTags',
        ],
        page: 'reports',
      }) ?? [],
    )
  }, [
    categories,
    users,
    businessUnits,
    locations,
    selectedBusinessUnit?.key,
    products,
    tags,
    customers,
    suppliers,
    territories,
  ])

  return { filterOptions, featuresAllowed }
}
