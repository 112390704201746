/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamationCircle, HiOutlineTrash } from 'react-icons/hi'

import { message } from 'antd'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'

import { useDeleteRevenueValueMutation } from '@/redux/revenues'

import classes from './ValueDeletion.module.less'

export const ValueDeletion = ({ revenueValueId }: { revenueValueId: number }): ReactElement => {
  const { t } = useTranslation('common')
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)

  const [deleteRevenueValueMutation] = useDeleteRevenueValueMutation()

  return (
    <>
      <Button
        className={classes.button}
        title={t('revenue.table.delete.title')}
        action="Delete revenue"
        type="text"
        iconClass={classes.btnIcon}
        prefixIcon={<HiOutlineTrash size={16} />}
        onClick={() => setShowConfirmationModal(true)}
      />
      <Modal
        wrapClassName={classes.modal}
        centered
        open={showConfirmationModal}
        title={t('revenue.table.delete.confirmation.title')}
        okText={t('revenue.table.delete.confirmation.button')}
        okButtonProps={{ color: 'danger' }}
        onCancel={() => setShowConfirmationModal(false)}
        onOk={async () => {
          await deleteRevenueValueMutation(revenueValueId)
            .unwrap()
            .catch(() => {
              message.error(t('revenue.table.delete.error-message'))
            })
          setShowConfirmationModal(false)
        }}
      >
        <div className={classes.center}>
          <HiOutlineExclamationCircle size={18} className={classes.icon} />
          <span>{t('revenue.table.delete.confirmation.text')}</span>
        </div>
      </Modal>
    </>
  )
}
