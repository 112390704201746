import {
  BulkLogEntryAddTagsRequestDto,
  BulkLogEntryAddTagsResponseDto,
  BulkLogEntryDeletionRequestDto,
  BulkLogEntryDeletionResponseDto,
  OrganizationCarbonFootprintInputsSumResponseDto,
  OrganizationCarbonFootprintSearchDto,
  OrganizationCarbonFootprintSearchFiltersDto,
  OrganizationCarbonFootprintSumResponseDto,
  SearchLogEntryDto,
} from '@cozero/dtos'
import { LogEntriesSearchPage, LogEntriesTableColumnKey, LogEntry, Page } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  TAG_LOG_ENTRY,
  TAG_PAGINATED_LOG_ENTRIES,
  TAG_PAGINATED_LOG_ENTRIES_EMISSIONS,
} from './tags'

export type SearchDto = SearchLogEntryDto & {
  filters: Partial<OrganizationCarbonFootprintSearchFiltersDto>
} & {
  selectedBusinessUnitId: number
}

const logEntriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogEntry: builder.query<LogEntry, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logEntries.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG_ENTRY, id: result?.id }],
    }),
    getPaginatedLogEntries: builder.query<
      Page<LogEntry>,
      {
        logIds: number[]
        pageNumber?: number
        pageSize?: number
      }
    >({
      query: (logEntry) => ({
        url: logApiGatewayClient.logEntries.GET_MANY,
        method: 'GET',
        params: logEntry,
      }),
      providesTags: (result) =>
        providesList<LogEntry[], typeof TAG_PAGINATED_LOG_ENTRIES>(
          result?.data,
          TAG_PAGINATED_LOG_ENTRIES,
          PARTIAL_LIST,
        ),
    }),
    getSearchLogEntries: builder.query<
      LogEntriesSearchPage<OrganizationCarbonFootprintSearchDto>,
      SearchDto
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.SEARCH,
        method: 'POST',
        params,
        data: filters,
      }),
      extraOptions: {
        retryCondition: (error, queryArgs, extra) => error.status === 504 && extra.attempt < 3,
      },
      providesTags: [TAG_PAGINATED_LOG_ENTRIES_EMISSIONS],
    }),
    exportLogEntries: builder.query<
      undefined,
      SearchDto & {
        type: 'csv' | 'xlsx'
        visibleColumns: LogEntriesTableColumnKey[] | undefined
      }
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.EXPORT,
        method: 'POST',
        params,
        data: filters,
      }),
    }),
    deleteManyLogEntries: builder.mutation<
      BulkLogEntryDeletionResponseDto,
      BulkLogEntryDeletionRequestDto & { onlyValidate?: boolean }
    >({
      query: ({ onlyValidate = false, ...data }) => ({
        url: logApiGatewayClient.logEntries.DELETE_MANY,
        method: 'DELETE',
        data,
        params: {
          onlyValidate,
        },
      }),
      invalidatesTags: (result) => (!result?.success ? [] : [TAG_PAGINATED_LOG_ENTRIES_EMISSIONS]),
    }),
    addTagsToLogEntries: builder.mutation<
      BulkLogEntryAddTagsResponseDto,
      BulkLogEntryAddTagsRequestDto
    >({
      query: (data) => ({
        url: logApiGatewayClient.logEntries.ADD_TAGS,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) => (!result?.success ? [] : [TAG_PAGINATED_LOG_ENTRIES_EMISSIONS]),
    }),
    getLogEntryOverviewRowsSums: builder.query<
      OrganizationCarbonFootprintSumResponseDto,
      SearchDto
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.GET_ROWS_SUMS,
        method: 'POST',
        params,
        data: filters,
      }),
    }),
    getLogEntryOverviewInputsSums: builder.query<
      OrganizationCarbonFootprintInputsSumResponseDto,
      SearchDto
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.GET_INPUTS_SUMS,
        method: 'POST',
        params,
        data: filters,
      }),
    }),
  }),
})

export const {
  useGetPaginatedLogEntriesQuery,
  useGetSearchLogEntriesQuery,
  useLazyExportLogEntriesQuery,
  useDeleteManyLogEntriesMutation,
  useAddTagsToLogEntriesMutation,
  useGetLogEntryOverviewRowsSumsQuery,
  useGetLogEntryOverviewInputsSumsQuery,
} = logEntriesApiSlice

export default logEntriesApiSlice
