import {
  ComputedReportTableQueryDto,
  ComputedReportTypeDto,
  ComputedReportWithDataDto,
  ComputedReportWithoutDataDto,
  CreateComputedReportDto,
  PaginatedQueryResult,
  UpdateComputedReportDto,
} from '@cozero/dtos'
import { reportsApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  CREATE_COMPUTED_REPORT,
  DELETE_ONE_COMPUTED_REPORT,
  GET_COMPUTED_REPORT_BY_ID,
  GET_COMPUTED_REPORT_TYPES,
  GET_LATEST_COMPUTED_REPORT_DATA,
  GET_MANY_COMPUTED_REPORTS,
  TAG_MANY_COMPUTED_REPORTS,
  TAG_ONE_COMPUTED_REPORT,
  UPDATE_COMPUTED_REPORT,
} from './constants'
import { TAG_COMPUTED_REPORT_TYPES } from './tags'

export type GetLatestComputedReportParams = {
  businessUnitId: number
  computedReportTypeKey: string
  baseYearStart: string
  baseYearEnd: string
  reportingYearStart: string
  reportingYearEnd: string
  name: string
}

const ComputedReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_COMPUTED_REPORT_TYPES]: builder.query<ComputedReportTypeDto[], void>({
      query: () => ({
        url: reportsApiGatewayClient.computedReports.GET_ALL_TYPES,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<ComputedReportTypeDto[], typeof TAG_COMPUTED_REPORT_TYPES>(
          result,
          TAG_COMPUTED_REPORT_TYPES,
        ),
    }),
    [GET_LATEST_COMPUTED_REPORT_DATA]: builder.query<
      ComputedReportWithDataDto | null,
      GetLatestComputedReportParams
    >({
      query: (params) => ({
        url: reportsApiGatewayClient.computedReports.GET_LAST,
        method: 'GET',
        params,
      }),
      transformErrorResponse: (res) => res,
      providesTags: (result, _, params) => [
        { type: TAG_ONE_COMPUTED_REPORT, id: result?.id },
        { type: TAG_MANY_COMPUTED_REPORTS, id: JSON.stringify(params) },
      ],
    }),
    [GET_COMPUTED_REPORT_BY_ID]: builder.query<ComputedReportWithDataDto | null, string>({
      query: (id) => ({
        url: reportsApiGatewayClient.computedReports.GET_ONE.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (_result, _, id) => [{ type: TAG_ONE_COMPUTED_REPORT, id }],
    }),
    [GET_MANY_COMPUTED_REPORTS]: builder.query<
      PaginatedQueryResult<Array<ComputedReportWithoutDataDto>>,
      ComputedReportTableQueryDto
    >({
      query: (params) => ({
        url: reportsApiGatewayClient.computedReports.GET_MANY,
        method: 'GET',
        params,
      }),
      providesTags: (result, _err, params) => [
        { type: TAG_MANY_COMPUTED_REPORTS, id: JSON.stringify(params) },
        ...(result
          ? result.data.map((report) => ({ type: TAG_ONE_COMPUTED_REPORT, id: report.id }))
          : []),
      ],
    }),
    [CREATE_COMPUTED_REPORT]: builder.mutation<ComputedReportWithDataDto, CreateComputedReportDto>({
      query: (data) => ({
        url: reportsApiGatewayClient.computedReports.CREATE,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [TAG_MANY_COMPUTED_REPORTS],
      extraOptions: { maxRetries: 0 },
    }),
    [DELETE_ONE_COMPUTED_REPORT]: builder.mutation<ComputedReportWithDataDto, number>({
      query: (id) => ({
        url: reportsApiGatewayClient.computedReports.DELETE_ONE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _err, id) => [
        TAG_MANY_COMPUTED_REPORTS,
        { type: TAG_ONE_COMPUTED_REPORT, id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [UPDATE_COMPUTED_REPORT]: builder.mutation<
      ComputedReportWithDataDto,
      { id: number; body: UpdateComputedReportDto }
    >({
      query: ({ id, body }) => ({
        url: reportsApiGatewayClient.computedReports.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        TAG_MANY_COMPUTED_REPORTS,
        { type: TAG_ONE_COMPUTED_REPORT, id },
      ],
    }),
  }),
})

export const {
  useGetComputedReportTypesQuery,
  useLazyGetComputedReportByIdQuery,
  useGetLatestComputedReportDataQuery,
  useCreateComputedReportMutation,
  useLazyGetLatestComputedReportDataQuery,
  useGetComputedReportByIdQuery,
  useGetManyComputedReportsDataQuery,
  useDeleteOneComputedReportMutation,
  useUpdateComputedReportMutation,
} = ComputedReportsApiSlice

export default ComputedReportsApiSlice
