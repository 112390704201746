import {
  OrganizationCarbonFootprintInputsSumResponseDto,
  OrganizationCarbonFootprintSumResponseDto,
} from '@cozero/dtos'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits/selectors'
import {
  SearchDto,
  useGetLogEntryOverviewInputsSumsQuery,
  useGetLogEntryOverviewRowsSumsQuery,
} from '@/redux/logEntries/api'

import { TableColumns } from './useTableColumns'

type UseSumsRowReturn = {
  rowSums: OrganizationCarbonFootprintSumResponseDto | undefined
  inputsSums: OrganizationCarbonFootprintInputsSumResponseDto | undefined
  isRowSumsLoading: boolean
  isInputsSumsLoading: boolean
}

type UseSumsRowProps = {
  rowsSumQuery: SearchDto
  columns: TableColumns
}

export const useSumsRow = ({ rowsSumQuery, columns }: UseSumsRowProps): UseSumsRowReturn => {
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const hasInputsColumns = columns.some((column) => column.key.match(/input/))

  const { data: rowSums, isFetching: isRowSumsLoading } = useGetLogEntryOverviewRowsSumsQuery(
    rowsSumQuery,
    {
      skip: !selectedBusinessUnit?.id,
    },
  )

  const { data: inputsSums, isFetching: isInputsSumsLoading } =
    useGetLogEntryOverviewInputsSumsQuery(rowsSumQuery, {
      skip: !selectedBusinessUnit?.id || !hasInputsColumns,
    })

  return { rowSums, inputsSums, isRowSumsLoading, isInputsSumsLoading }
}
