import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiCloudUpload, HiOutlineInformationCircle } from 'react-icons/hi'
import { useSelector } from 'react-redux'

import { Cascader, Col, Form, FormInstance, Modal, Row, Select, Spin, Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface'

import { skipToken } from '@reduxjs/toolkit/query/react'
import moment, { Moment } from 'moment'

import { Product, ProductTag, ProductTypeWithExtraInfo, Territory } from '@cozero/models'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'
import { Step } from '@/pages/GenericLifecycleSteps/hooks/steps'
import { ProductConfigurationValues } from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import ProductTypeSelect from '@/molecules/ProductTypeSelect'
import TagInput from '@/molecules/TagInput'
import { TerritoryCascader } from '@/molecules/TerritoryCascader'
import UnitDropdownCombinedInput, { Unit } from '@/molecules/UnitDropdownCombinedInput'

import Alert from '@/atoms/Alert'
import DateRangePicker from '@/atoms/DateRangePicker'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import { useLifecycleContext } from '@/contexts/lifecycle'
import { useLogContext } from '@/contexts/log'
import useProducts from '@/hooks/useProducts'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { useGetUnitsQuery } from '@/redux/units/api'
import { GRAY_400 } from '@/styles/variables'
import { CascaderNode } from '@/types/general'

import classes from './UpsertProduct.module.less'

interface UpsertProductProps {
  form: FormInstance
  product?: Product
  territories: Territory[]
  STEPS: Step[]
  onSubmitNewProductData: (data: ProductConfigurationValues) => Promise<void>
}

const codeValidationRegex = new RegExp(/^[a-zA-Z\d\-.]+$/)

const UpsertProduct = ({
  form,
  product,
  territories,
  STEPS,
  onSubmitNewProductData,
}: UpsertProductProps): JSX.Element => {
  const { t } = useTranslation('common')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [unit, setUnit] = useState<Unit>()
  const [unitAmount, setUnitAmount] = useState<number>(0)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [isImageTouched, setIsImageTouched] = useState(false)
  const [tagOptions, setTagOptions] = useState<ProductTag[]>([])
  const { getProductTypes, productTypes, getSignPutProductConfigurationUrl, parseData } =
    useProducts()
  const { getProductTags, createProductTag, deleteProductTag } = useLogContext()
  const { data: units, isLoading: isLoadingUnits } = useGetUnitsQuery({ excludeFromPCF: true })

  const businessUnit = useSelector(selectSelectedBusinessUnit)
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnitId: businessUnit?.id } ?? skipToken,
  )
  const [loading, setLoading] = useState(true)
  const { selectedSteps } = useLifecycleContext()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      await getProductTypes()
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchTags = async (): Promise<void> => {
      const tags = await getProductTags('')
      setTagOptions(tags)
    }
    fetchTags()
  }, [])

  useEffect(() => {
    if (isLoadingUnits) {
      return
    } // Exit early if unit fetching is not complete

    if (product && territories) {
      const parsedData = parseData(product)
      populateData(parsedData)
    }

    if (product?.massUnitId) {
      const selectedUnit = units?.find((u) => u.id === product.massUnitId)
      setUnit(selectedUnit)
    }
  }, [product, territories, units])

  const continents = useMemo(
    () =>
      territories
        .filter((territory) => territory.continent?.alpha3Code === 'GLO')
        .map((territory) => {
          return { value: territory.id, label: territory.name, children: [] }
        }),
    [territories],
  )

  const populateData = (data: ProductConfigurationValues): void => {
    data.photo &&
      setFileList([
        {
          uid: '0',
          name: 'Product image',
          status: 'done',
          url: data.photo as string,
        },
      ])
    if (continents) {
      const territory = territories.find((x) => x.id === data.territory)

      form.setFieldsValue({
        ...data,
        territory: territory?.id ? [territory?.continentId, territory?.id] : [],
        tags: data.tags?.map((x) => (x as ProductTag).name || x),
        dateInterval: data.startDate ? [moment(data.startDate), moment(data.endDate)] : [],
      })
    }
  }

  const getSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<{ name: string; fileObj?: RcFile; signedUrl: string; path: string }[]> => {
    const urls = await getSignPutProductConfigurationUrl(newFileList.map(({ name }) => name))
    return [
      ...newFileList.map((x, index) => ({
        name: x.name,
        fileObj: x.originFileObj,
        signedUrl: urls[index].signedUrl,
        path: urls[index].path,
      })),
    ]
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      newFileList.shift()
    }
    if (!newFileList.length) {
      newFileList.pop()
    } else {
      newFileList[0].status = 'done'
    }

    setIsImageTouched(true)
    setFileList(newFileList)
  }

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('photo.upload-format'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('photo.upload-size'))
    }
    setIsImageTouched(true)
    return isJpgOrPng && isLt2M
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const onPreview = async (file: UploadFile<unknown>): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const mapTags = (tags: (string | ProductTag)[]): ProductTag[] => {
    return tags.map((tag) =>
      typeof tag === 'string' ? tagOptions.find((option) => option.name === tag) : tag,
    ) as ProductTag[]
  }

  const onSubmit = async (
    values: ProductConfigurationValues & { territory: number[]; dateInterval?: [Moment, Moment] },
  ): Promise<void> => {
    const selectedStepsKeys = selectedSteps.map((x) => STEPS[x.index].stepKey)
    const data: ProductConfigurationValues & {
      territoryId?: number
      dateInterval?: [Moment, Moment]
    } = {
      ...values,
      massUnit: Number(unit?.id !== undefined ? unit.id : 0),
      startDate: values.dateInterval?.[0].toDate(),
      endDate: values.dateInterval?.[1].toDate(),
      mass: Number(values.mass),
      selectedSteps: selectedStepsKeys || [],
      territoryId: values.territory?.length
        ? values.territory[values.territory.length - 1]
        : undefined,
      productType:
        typeof values.productType === 'number'
          ? values.productType
          : (
              productTypes.find(
                (type) => type.name === values.productType,
              ) as ProductTypeWithExtraInfo
            )?.id,
      tags: values.tags ? mapTags(values.tags as string[]) : [],
    }
    const files = fileList
    if (isImageTouched) {
      const filesToUpload = files.length ? await getSignedUrl(files) : []
      data.photo = filesToUpload[0] || null
    }

    // We've limited the available units, any existing products using unavailable units should not be saved.
    const hasBadUnit = !units?.find(({ id }) => data.massUnit === id)
    if (hasBadUnit) {
      return
    }

    delete data.territory
    delete data.dateInterval
    await onSubmitNewProductData(data)
    form.resetFields()
  }

  const handleCancel = (): void => setPreviewVisible(false)

  const dummyRequest: UploadProps['customRequest'] = ({ file, onSuccess }): void => {
    onSuccess && onSuccess(file)
  }

  return (
    <>
      <Spin spinning={loading || isLoadingUnits}>
        <Row>
          <LifecycleModalTitle
            image={productConfiguration}
            title={t('product.lifecycle-steps.configuration.title')}
            description={t('product.lifecycle-steps.configuration.subtitle')}
          />
          <Form className={classes.fullWidth} layout="vertical" form={form} onFinish={onSubmit}>
            <Form.Item name="upload">
              <ImgCrop rotate minZoom={0.1}>
                <Upload
                  className={classes.photo}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={onPreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  customRequest={dummyRequest}
                >
                  <Row>
                    <Col span={24}>
                      <HiCloudUpload />
                    </Col>
                    <Col span={24}>{t('product.lifecycle-steps.configuration.upload-picture')}</Col>
                  </Row>
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Row className={classes.formRow}>
              <Col span={7}>
                <Form.Item
                  name="name"
                  label={t('product.lifecycle-steps.configuration.product-name.label')}
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'product.lifecycle-steps.configuration.product-name.label',
                      )} ${t('share.success.form.required')}`,
                    },
                  ]}
                >
                  <InputField
                    placeholder={t(
                      'product.lifecycle-steps.configuration.product-name.placeholder',
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="code"
                  label={t('product.lifecycle-steps.configuration.product-code.label')}
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'product.lifecycle-steps.configuration.product-code.label',
                      )} ${t('share.success.form.required')}`,
                    },
                    {
                      pattern: codeValidationRegex,
                      message: `${t('product.lifecycle-steps.configuration.validation.regex')}`,
                    },
                    {
                      max: 255,
                      type: 'string',
                      message: `${t(
                        'product.lifecycle-steps.configuration.validation.max-length',
                      )}`,
                    },
                  ]}
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.product-code.tooltip'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                >
                  <InputField
                    placeholder={t(
                      'product.lifecycle-steps.configuration.product-code.placeholder',
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="version"
                  label={t('product.lifecycle-steps.configuration.product-version.label')}
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'product.lifecycle-steps.configuration.product-version.label',
                      )} ${t('share.success.form.required')}`,
                    },
                    {
                      pattern: codeValidationRegex,
                      message: `${t('product.lifecycle-steps.configuration.validation.regex')}`,
                    },
                    {
                      max: 255,
                      type: 'string',
                      message: `${t(
                        'product.lifecycle-steps.configuration.validation.max-length',
                      )}`,
                    },
                  ]}
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.product-version.tooltip'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                >
                  <InputField
                    placeholder={t(
                      'product.lifecycle-steps.configuration.product-version.placeholder',
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col span={7}>
                <UnitDropdownCombinedInput
                  unit={unit?.name}
                  onUnitChange={setUnit}
                  amount={unitAmount}
                  onAmountChange={setUnitAmount}
                  units={units || []}
                  required={true}
                  touched={form.isFieldTouched('mass')}
                  requiredMessage={`${t(
                    'product.lifecycle-steps.configuration.product-measurement-unit.label-error',
                  )} ${t('share.success.form.required-plural')}`}
                />
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  className={classes.unitFields}
                  name="dateInterval"
                  label={t('product.lifecycle-steps.configuration.date-interval.label')}
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'product.lifecycle-steps.configuration.date-interval.label',
                      )} ${t('share.success.form.required')}`,
                    },
                  ]}
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.date-interval.tooltip'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                >
                  <DateRangePicker style={{ width: '200%' }} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="productType"
                  label={`${t('product.lifecycle-steps.configuration.product-type.label')}`}
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.product-type.tooltip'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                >
                  <ProductTypeSelect
                    productTypes={productTypes}
                    defaultValue={product?.productTypeId ?? form.getFieldValue('productType')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col span={7}>
                <Form.Item
                  label={t('product.lifecycle-steps.configuration.responsible-user.label')}
                  name={'responsibleId'}
                >
                  <Select
                    placeholder={t(
                      'product.lifecycle-steps.configuration.responsible-user.placeholder',
                    )}
                    dropdownMatchSelectWidth={false}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                    }
                    options={users?.map((user) => ({
                      key: user.id,
                      value: user.id,
                      label: user.email,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="tags"
                  label={t('product.lifecycle-steps.configuration.tags.label')}
                >
                  <TagInput
                    placeholder={t('product.lifecycle-steps.configuration.tags.placeholder')}
                    options={tagOptions.map(({ name }) => name)}
                    onCreateTag={async (name: string) => {
                      const newTag = await createProductTag(name)
                      newTag && setTagOptions([...tagOptions, newTag])
                    }}
                    onDeleteTag={async (name: string) => {
                      const id = tagOptions.find((x) => x.name === name)?.id
                      id && (await deleteProductTag(id))
                      setTagOptions([...tagOptions.filter((x) => x.id !== id)])
                    }}
                    reservedOptions={tagOptions
                      .filter(({ organizationId }) => !organizationId)
                      .map(({ name }) => name)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={t('product.lifecycle-steps.configuration.product-origin.label')}
                  name="territory"
                >
                  <TerritoryCascader
                    options={territories}
                    size="middle"
                    placeholder={t(
                      'product.lifecycle-steps.configuration.product-origin.placeholder',
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Row>
      </Spin>
    </>
  )
}

export default UpsertProduct
