import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'

import moment from 'moment'

import { ClosedPeriod } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Alert from '@/atoms/Alert'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import useImpactedLogs from '@/hooks/useImpactedLogs'
import { useAppSelector } from '@/redux'
import { selectLocale } from '@/redux/auth'

import classes from './classes.module.less'

const ImpactedClosedPeriodsTable = (): ReactElement | null => {
  const {
    closedPeriodsPage,
    closedPeriodsPageSize,
    setClosedPeriodsPage,
    setClosedPeriodsPageSize,
    closedPeriods,
    loadingClosedPeriods,
    totalClosedPeriods,
  } = useImpactedLogs()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const locale = useAppSelector(selectLocale)
  const changeTable = (pagination: TablePaginationConfig): void => {
    if (pagination.current && pagination.current !== closedPeriodsPage) {
      setClosedPeriodsPage(pagination.current)
    }
  }

  const navigateToClosedPeriod = useCallback(
    (row: ClosedPeriod) => {
      navigate(routes.log.closedPeriods.editClosedPeriod.replace(':id', row.id.toString()))
    },
    [navigate],
  )

  const columns: ColumnsType<ClosedPeriod> = useMemo(
    () => [
      {
        key: 'name',
        title: t('calculation-changes.impacted-closed-period-column.name'),
        render(text: string, record: ClosedPeriod) {
          return <span>{record.name}</span>
        },
      },
      {
        key: 'description',
        title: t('calculation-changes.impacted-closed-period-column.description'),
        render(text: string, record: ClosedPeriod) {
          return <span>{record.description}</span>
        },
      },
      {
        key: 'period',
        title: t('calculation-changes.impacted-closed-period-column.period'),
        render(text: string, record: ClosedPeriod) {
          return (
            <>
              <Tag>
                {moment(record.startDate)
                  .locale(locale ?? 'en')
                  .format('ll')}
              </Tag>
              to
              <Tag>
                {moment(record.endDate)
                  .locale(locale ?? 'en')
                  .format('ll')}
              </Tag>
            </>
          )
        },
      },
      {
        key: 'organization-level',
        title: t('calculation-changes.impacted-closed-period-column.organization-level'),
        render(text: string, record: ClosedPeriod) {
          return <Tag>{record.organizationLevel}</Tag>
        },
      },
      {
        key: 'created-by',
        title: t('calculation-changes.impacted-closed-period-column.created-by'),
        render(text: string, record: ClosedPeriod) {
          return <Tag>{record.responsible?.firstName}</Tag>
        },
      },
      {
        key: 'business-unit',
        title: t('calculation-changes.impacted-closed-period-column.business-unit'),
        render(text: string, record: ClosedPeriod) {
          return <Tag>{record.businessUnit.title}</Tag>
        },
      },
    ],
    [closedPeriods],
  )

  if (!closedPeriods || closedPeriods.length === 0) {
    return null
  }

  return (
    <>
      <Row className={classes.tableWrapper}>
        <Text fontWeight="medium" size="lg" color="secondary">
          {t('calculation-changes.impact-modal.closed-periods-title')}
        </Text>
        <Alert
          message={t('calculation-changes.impact-modal.closed-periods-alert')}
          type="warning"
        />
        <Col span={24}>
          <Table
            dataSource={closedPeriods}
            loading={loadingClosedPeriods}
            rowKey="id"
            columns={columns}
            scroll={{ x: 'max-content' }}
            showWrapper={false}
            rowClassName={classes.tableRow}
            onRow={(row) => ({
              onClick: () => navigateToClosedPeriod(row),
            })}
            onChange={changeTable}
            pagination={{
              current: closedPeriodsPage,
              defaultPageSize: closedPeriodsPageSize,
              pageSize: closedPeriodsPageSize,
              total: totalClosedPeriods,
              onShowSizeChange: (_current, size) => {
                setClosedPeriodsPageSize(size)
              },
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default ImpactedClosedPeriodsTable
