import { RevenueValueDto } from '@cozero/dtos'

import { prettifyDate } from '@/utils/date'
import { formatCompact, formatNumber } from '@/utils/number'

export const getRevenueTooltipData = ({
  revenueValues,
  currency,
  periodStart,
  periodEnd,
}: {
  revenueValues: RevenueValueDto[]
  currency: string
  periodStart: string
  periodEnd: string
}): {
  pillColor: 'red' | 'green'
  yearVariationText: string
  value: string
  timePeriod: string
  isFirstDatapoint: boolean
} => {
  const sortedRevenueValues = [...revenueValues].sort((a, b) =>
    a.periodStart.localeCompare(b.periodStart),
  )
  const currentValueIndex = sortedRevenueValues.findIndex(
    (d) => d.periodStart === periodStart && d.periodEnd === periodEnd,
  )
  const currentValue = sortedRevenueValues[currentValueIndex]?.value
  const previousValue = sortedRevenueValues[currentValueIndex - 1]?.value

  const variationInPercentage = previousValue
    ? Math.round(((currentValue - previousValue) / previousValue) * 100)
    : 0
  const pillColor = variationInPercentage > 0 ? 'green' : 'red'
  const sign = variationInPercentage >= 0 ? '+' : ''
  const yearVariationText = `${sign}${formatCompact(variationInPercentage, {
    maximumFractionDigits: 2,
  })}%`
  const value = `${formatNumber(currentValue)} ${currency}`
  const timePeriod = formatPeriodLegend(periodStart, periodEnd)
  const isFirstDatapoint = currentValueIndex === 0

  return {
    pillColor,
    yearVariationText,
    value,
    timePeriod,
    isFirstDatapoint,
  }
}

const formatPeriodLegend = (periodStart: string, periodEnd: string): string => {
  const start = prettifyDate(periodStart, 'YYYY-MM', { useLocalTime: false })
  const end = prettifyDate(periodEnd, 'YYYY-MM', { useLocalTime: false })

  return `${start} → ${end}`
}

export const generateDatapoints = (
  revenueValues: RevenueValueDto[],
): { year: string; value: number; periodStart: string; periodEnd: string }[] => {
  return revenueValues
    .map((value, index, array) => ({
      year:
        index === array.length - 1
          ? prettifyDate(value.periodStart, 'YYYY-MM', { useLocalTime: false })
          : prettifyDate(value.periodEnd, 'YYYY-MM', { useLocalTime: false }),
      value: value.value,
      periodStart: value.periodStart,
      periodEnd: value.periodEnd,
    }))
    .sort((a, b) => a.year.localeCompare(b.year))
}
