import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter, HiOutlinePlus } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Col, Divider, Row, Typography, message } from 'antd/es'

import debounce from 'lodash/debounce'

import { PageFilter, Quantity } from '@cozero/models'

import { CreateQuantityModal } from '@/organisms/CreateQuantityModal'
import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import QuantitiesTable from '@/organisms/QuantitiesTable'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import FiltersDrawer from '@/molecules/FiltersDrawer'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppContext } from '@/contexts/app'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import useQuantities from '@/hooks/useQuantities'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly, selectUserOrganization } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { createQuantityFilterOptions } from '@/utils/filters'
import { createQueryFilter } from '@/utils/filters'

import classes from './Quantities.module.less'

enum FilterKey {
  PRODUCT = 'productId',
}

const QuantitiesLogs = (): JSX.Element => {
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { getProducts, products } = useAppContext()
  const [drawerOpened, setDrawerOpened] = useState(false)

  const { filters, sorters, pageNumber: currentPage, saveFilters } = useFiltersContext()

  const [quantitiesFilters, setQuantitiesFilters] = useState(
    filters?.filter((filter) => filter.logType === 'quantities' || !filter.logType),
  )
  const [quantitiesSorters, setQuantitiesSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'quantities' || !sorter.logType),
  )
  const {
    data: quantities,
    error: quantitiesError,
    loading: quantitiesLoading,
    deleteQuantity,
    mutate,
  } = useQuantities({ filters: quantitiesFilters, sorters: quantitiesSorters })

  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const { featuresAllowed } = usePricingFeature()

  const organization = useAppSelector(selectUserOrganization)
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { t } = useTranslation('common')
  const { editQuantity, createQuantity } = useQuantities({})
  const { error, customers, getCustomers, currencies, getCurrencies, reset } = useLogContext()
  const [modalOpen, setModalOpen] = useState<'config' | 'quantities' | null>()
  const navigate = useNavigate()
  const [quantityFilterOptions, setQuantityFilterOptions] = useState<PageFilter[]>(
    createQuantityFilterOptions({
      products,
      t,
    }),
  )

  const [quantity, setQuantity] = useState<Partial<Quantity> | undefined>()

  const queryProduct = new URLSearchParams(useLocation().search).get('product')

  useEffect(() => {
    if (error === 'LOG_LIMIT_EXCEEDED') {
      const limit = getLimit(organization, 'log')
      setSubscribeModalSettings({
        closable: true,
        title: t('subscription.upgrade-modal.title-logs-limit', {
          limit: limit?.max,
          item: t('product.logs.title'),
        }),
        visible: true,
      })
    } else if (error) {
      message.error(t('location.errors.log-creation'))
    }
  }, [error])

  const onCloseModal = (): void => {
    setModalOpen(null)
    setQuantity(undefined)
  }

  const onOpenModal = (type: 'config' | 'quantities'): void => {
    setModalOpen(type)
  }

  const createConfigLog = async (): Promise<void> => {
    onOpenModal('config')
  }

  async function onSearch(filters: PageFilter[]): Promise<void> {
    saveFilters(filters)
  }

  async function fetchData(): Promise<void> {
    await getProducts()
    await getCustomers()
    await getCurrencies()
  }

  const submitQuantity = async (
    quantity: Partial<Quantity> & { customerIds: number[] },
  ): Promise<void> => {
    onCloseModal()
    if (quantity.id) {
      await editQuantity(quantity)
    } else {
      await createQuantity(quantity)
    }
    await fetchData()
    mutate(quantities)
  }

  function onEditQuantity(quantity: Partial<Quantity>): void {
    setQuantity(quantity)
    setModalOpen('quantities')
  }

  const openFilters = (): void => {
    const deprecatedView = quantitiesFilters.some(
      (x) => x.selectedCondition?.key && x.selectedCondition?.key !== 'in',
    )
    if (!deprecatedView) {
      setDrawerOpened(true)
    } else {
      message.error(t('views.update.failure'))
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedBusinessUnit?.key])

  useEffect(() => {
    setQuantityFilterOptions(
      createQuantityFilterOptions({
        products,
        t,
      }),
    )
  }, [products])

  useEffect(() => {
    if (queryProduct && products?.length) {
      const foundProduct = products?.find((obj) => obj.id.toString() === queryProduct)
      if (foundProduct) {
        const queryFilter = createQueryFilter({
          value: [foundProduct.id.toString()],
          type: 'product',
          t,
          options: products,
        })
        saveFilters(queryFilter)
        navigate(location.pathname)
      }
    }
  }, [currentPage, queryProduct, products?.length, selectedBusinessUnit?.key])

  useEffect(() => {
    setQuantitiesFilters(
      filters?.filter((filter) => filter.logType === 'quantities' || !filter.logType),
    )
  }, [filters])

  useEffect(() => {
    setQuantitiesSorters(
      sorters?.filter((sorter) => sorter.logType === 'quantities' || !sorter.logType),
    )
  }, [sorters])

  useEffect(() => {
    reset()
    fetchData()
  }, [])

  const Wrapper = ({ children }: { children: ReactNode }): ReactElement => {
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>{children}</Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Wrapper>
        <FiltersDrawer
          search={debounce(onSearch, 500)}
          pageType={'quantities'}
          filters={[...quantitiesFilters] as (PageFilter & { key: FilterKey })[]}
          visible={drawerOpened}
          filterOptions={
            [...quantityFilterOptions] as (PageFilter & {
              options: {
                key?: string
                value: string
                label: string
              }[]
            })[]
          }
          onClose={() => setDrawerOpened(false)}
        />
        <Row>
          <Col span={24}>
            {products ? (
              <div>
                <Row>
                  <Col span={24}>
                    <Row justify="end">
                      {!userIsReadOnly && (
                        <Col>
                          <Button
                            type="primary"
                            onClick={() => onOpenModal('quantities')}
                            className={classes.button}
                            category={AnalyticsCategories.QUANTITIES_LOGS}
                            action="open-create-modal"
                            data-cy="new-quantity"
                            prefixIcon={<HiOutlinePlus />}
                          >
                            {t(`log.quantities.new`)}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <div className={classes.logsContainer}>
                  <Row>
                    <Col span={24}>
                      <Row gutter={[16, 16]} justify="end">
                        <Col>
                          <Button
                            onClick={() => openFilters()}
                            category={AnalyticsCategories.LOGS}
                            action={'open-filter'}
                            className={`${classes.buttonRow} ${
                              quantitiesFilters?.filter((filter) => filter.type !== 'date')
                                ?.length && classes.activeButton
                            }`}
                          >
                            <HiOutlineFilter />
                            <Typography
                              className={`${classes.buttonTitle} ${
                                quantitiesFilters?.length && classes.activeTitle
                              }`}
                            >
                              {t('log.filter.add')}
                            </Typography>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <QuantitiesTable
                    quantities={quantities}
                    filters={quantitiesFilters ?? []}
                    sorters={quantitiesSorters ?? []}
                    onEditQuantity={(quantity) => onEditQuantity(quantity)}
                    deleteQuantity={deleteQuantity}
                    error={quantitiesError}
                    loading={quantitiesLoading}
                  />
                </div>
              </div>
            ) : (
              <Row className={classes.logEmptyState}>
                <Col span={24}>
                  <EmptyStateCard
                    title={t('log.onboarding.product-title')}
                    description={t('log.onboarding.description')}
                    buttonText={t('log.onboarding.create-log')}
                    type={t('log.onboarding.log')}
                    onClick={createConfigLog}
                  />
                </Col>
                <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
              </Row>
            )}
          </Col>
        </Row>
      </Wrapper>
      <CreateQuantityModal
        visible={modalOpen === 'quantities'}
        handleCancel={onCloseModal}
        submitQuantity={submitQuantity}
        quantity={quantity}
        customers={customers}
        products={products}
        currencies={currencies}
      />
    </>
  )
}

export default QuantitiesLogs
