import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Col, Divider, Image, Row } from 'antd'

import moment from 'moment'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'
import InfoBanner from '@/pages/Log/Factors/CalculationView/InfoBanner'

import CustomHeader from '@/organisms/CustomModalHeader'
import ImpactedClosedPeriodsTable from '@/organisms/ImpactedClosedPeriodsTable'
import OrganisationImpactedLogsTable from '@/organisms/OrganisationImpactedLogsTable'
import OrganisationImpactedProductsTable from '@/organisms/OrganisationImpactedProductsTable'

import LayoutFooter from '@/molecules/LayoutFooter'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectLocale } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  selectCalculationChangelogRecord,
  useFindOneChangelogLogQuery,
} from '@/redux/methodologyChangelogs'
import { setSelectedCalculationChangelogRecord } from '@/redux/methodologyChangelogs/slice'
import { languages } from '@/utils/config'

import classes from './CalculationView.module.less'

const CalculationView = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const locale = useAppSelector(selectLocale)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: methodologyChangelog } = useFindOneChangelogLogQuery(
    {
      id: Number(id),
      businessUnitId: selectedBusinessUnit!.id,
      subcategoryId: parseInt(searchParams.get('subcategoryId') as string),
    },
    {
      skip: !selectedBusinessUnit,
    },
  )

  const [title, createdDate] = useMemo(() => {
    if (selectedCalculationChangelogRecord) {
      return [
        `${selectedCalculationChangelogRecord?.subcategory.name}`,
        moment(selectedCalculationChangelogRecord?.createdAt)
          .locale(locale ?? languages[0].id)
          .format('ll'),
      ]
    }
    return ['', '']
  }, [selectedCalculationChangelogRecord])

  const navigateBack = useCallback((): void => {
    navigate(-1)
  }, [])

  useEffect(() => {
    if (methodologyChangelog) {
      dispatch(setSelectedCalculationChangelogRecord(methodologyChangelog))
    }
  }, [methodologyChangelog])

  const image = selectedCalculationChangelogRecord?.subcategory?.category?.image?.url ?? ''

  return (
    <>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('calculation-changes.impact-modal.title')}
            onClose={navigateBack}
            goBack={navigateBack}
          />
        </div>
        <Row className={classes.content}>
          <Row gutter={[24, 0]}>
            <Col>
              <Image className={classes.categoryImage} preview={false} src={image} />
            </Col>
            <Col>
              <Row className={classes.subtitle}>
                <Col>{title}</Col>
              </Row>
              <span className={classes.description}>
                <Trans
                  i18nKey="calculation-changes.impact-modal.subtitle-first"
                  values={{ createdDate }}
                  components={{ bold: <strong /> }}
                />
                <br />
                {t('calculation-changes.impact-modal.subtitle-second')}
              </span>
            </Col>
          </Row>
          <Divider />

          {selectedCalculationChangelogRecord?.methodologyChangelogGlobal
            .changeReasonDescription && (
            <InfoBanner
              description={
                typeof selectedCalculationChangelogRecord?.methodologyChangelogGlobal
                  .changeReasonDescription === 'string'
                  ? selectedCalculationChangelogRecord?.methodologyChangelogGlobal
                      .changeReasonDescription
                  : ''
              }
            />
          )}
          <OrganisationImpactedLogsTable />
          <OrganisationImpactedProductsTable />
          <ImpactedClosedPeriodsTable />
        </Row>
        <LayoutFooter
          hideCancelButton
          categoryOkButton={AnalyticsCategories.FACTOR_REQUESTS}
          isDataValid={true}
          onCancelClick={navigateBack}
          onSaveClick={navigateBack}
          saveButtonDisabled={false}
          categoryCancelButton={AnalyticsCategories.CALCULATION_CHANGES}
          saveButtonText={t('calculation-changes.impact-modal.done-btn')}
        />
      </div>
    </>
  )
}

export default CalculationView
