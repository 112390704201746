/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import { CurrencySelectionCard } from '@/pages/Organization/Revenue/CurrencySelectionCard/CurrencySelectionCard'

import LoadingSpinner from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetRevenueQuery } from '@/redux/revenues'

import classes from './Revenue.module.less'
import { RevenueGraph } from './RevenueGraph/RevenueGraph'
import { RevenueTable } from './RevenueTable/RevenueTable'
import { ValueCreation } from './ValueCreation/ValueCreation'

const Revenue = (): ReactElement => {
  const { t } = useTranslation('common')
  const businessUnit = useAppSelector(selectSelectedBusinessUnit)

  const { data: revenue, isLoading } = useGetRevenueQuery()

  return (
    <>
      <Row className={classes.section}>
        <Col span={24}>
          <Title as="h1" size="sm">
            {t('revenue.title')}
          </Title>
        </Col>

        <Col xl={12} lg={16} span={24}>
          <Text size="xl" color="secondary" className={classes.paddingBottom}>
            {t('revenue.subtitle')}
          </Text>
        </Col>

        {isLoading && (
          <Col span={24}>
            <LoadingSpinner className={classes.loadingSpinner} />
          </Col>
        )}

        {!isLoading && !revenue && (
          <Col span={24}>
            <CurrencySelectionCard businessUnit={businessUnit} />
          </Col>
        )}
      </Row>

      {revenue && businessUnit && (
        <Row gutter={[64, 32]}>
          <Col span={24} lg={12}>
            <ValueCreation revenue={revenue} />
          </Col>

          <Col span={24} lg={12}>
            <RevenueGraph revenueData={revenue} />
          </Col>
        </Row>
      )}

      {revenue && (
        <Row className={classes.table}>
          <Col span={24}>
            <RevenueTable revenue={revenue} />
          </Col>
        </Row>
      )}
    </>
  )
}

export default Revenue
