import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiInformationCircle } from 'react-icons/hi'

import { Table } from 'antd'
import { Skeleton } from 'antd/es'

import classNames from 'classnames'
import { range } from 'lodash'

import {
  OrganizationCarbonFootprintInputsSumResponseDto,
  OrganizationCarbonFootprintSumResponseDto,
} from '@cozero/dtos'

import Tooltip from '@/atoms/Tooltip'

import { CINDER_BLUE_80, COZERO_BLUE_80 } from '@/styles/variables'
import { formatNumber } from '@/utils/number'

import { TableColumns } from '../../hooks/useTableColumns'

import classes from './SumsRow.module.less'

const LoadingPlaceholder = (): JSX.Element => (
  <Skeleton className={classes.loadingPlaceholder} paragraph={{ rows: 0 }} active />
)

const EmptyInputSumCell = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <span className={classes.emptyInput}>
      --
      <Tooltip
        showArrow
        placement="top"
        overlayClassName={classes.tooltip}
        color={CINDER_BLUE_80}
        content={t('log.log-entries-overview.sum-row.empty-input-tooltip')}
      >
        <HiInformationCircle className={classes.icon} size={14} color={COZERO_BLUE_80} />
      </Tooltip>
    </span>
  )
}

export const SumsRow = ({
  rowSums,
  isRowSumsLoading,
  isInputsSumsLoading,
  columns,
  inputsSums,
}: {
  rowSums: OrganizationCarbonFootprintSumResponseDto | undefined
  inputsSums: OrganizationCarbonFootprintInputsSumResponseDto | undefined
  isRowSumsLoading: boolean
  isInputsSumsLoading: boolean
  columns: TableColumns
}): JSX.Element | null => {
  const { t } = useTranslation()

  if (!isRowSumsLoading && rowSums?.rowsCount === 0) {
    return null
  }

  const columnsToAdd = columns.length

  return (
    <>
      <Table.Summary fixed>
        <Table.Summary.Row className={classes.row}>
          <Table.Summary.Cell index={-1}></Table.Summary.Cell>

          <Table.Summary.Cell index={0} className={classes.totalSumCell}>
            {isRowSumsLoading ? (
              <LoadingPlaceholder />
            ) : (
              t('log.log-entries-overview.sum-row.total', {
                count: rowSums?.rowsCount,
                totalRows: formatNumber(rowSums?.rowsCount ?? 0, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }),
              })
            )}
          </Table.Summary.Cell>
          {range(1, columnsToAdd).map((index) => {
            const key = columns[index].key
            const isInputColumn = /^input/.test(key)
            const isLastColumn = index === columnsToAdd - 1
            const isColumnLoading = isInputColumn ? isInputsSumsLoading : isRowSumsLoading

            const value =
              rowSums && key in rowSums
                ? rowSums?.[key as keyof OrganizationCarbonFootprintSumResponseDto]
                : inputsSums && key in inputsSums
                ? inputsSums?.[key as keyof OrganizationCarbonFootprintInputsSumResponseDto]
                : null

            if (isColumnLoading) {
              return (
                <Table.Summary.Cell
                  className={classNames({
                    'ant-table-cell-fix-right-first': isLastColumn,
                    [classes.lastCell]: isLastColumn,
                  })}
                  index={index}
                  key={key}
                >
                  {(isInputColumn || isLastColumn) && <LoadingPlaceholder />}
                </Table.Summary.Cell>
              )
            }

            if (!value && isInputColumn) {
              return (
                <Table.Summary.Cell index={index} key={key}>
                  <EmptyInputSumCell />
                </Table.Summary.Cell>
              )
            }

            if (!value) {
              return <Table.Summary.Cell index={index} key={key}></Table.Summary.Cell>
            }

            return (
              <Table.Summary.Cell
                index={index}
                key={key}
                className={classNames(classes.cell, {
                  [classes.lastCell]: isLastColumn,
                  'ant-table-cell-fix-right-first': isLastColumn,
                })}
              >
                {formatNumber(value, { maximumFractionDigits: 2 })}
              </Table.Summary.Cell>
            )
          })}
        </Table.Summary.Row>
      </Table.Summary>
    </>
  )
}
