import moment, { Moment } from 'moment'

export type DateRangeValue = [Moment | null, Moment | null]
export type DateRanges = Record<string, DateRangeValue>

const DEFAULT_YEARS_TO_SHOW = 5
export const getPredefinedDateRanges = (
  numberOfYearsToShow = DEFAULT_YEARS_TO_SHOW,
): DateRanges => {
  const predefinedDateRanges: DateRanges = {}
  for (let i = 0; i < numberOfYearsToShow; i++) {
    const yearMoment = moment().utc().subtract(i, 'years')
    const yearLabel = yearMoment.format('YYYY')
    predefinedDateRanges[yearLabel] = [
      yearMoment.clone().startOf('year').utc(),
      yearMoment.clone().endOf('year').utc(),
    ]
  }
  predefinedDateRanges.Clear = [null, null]
  return predefinedDateRanges
}

export const getDefaultDateRanges = (): DateRanges => {
  const rangesAux: DateRanges = {}
  rangesAux['Year-to-Date'] = [moment().utc().startOf('year'), moment().utc()]
  rangesAux['Last 12 months'] = [moment().utc().subtract(12, 'months'), moment().utc()]
  for (let i = 3; i >= 0; i--) {
    rangesAux[moment().subtract(i, 'year').year()] = [
      moment().utc().subtract(i, 'year').startOf('year').utc(),
      moment().utc().subtract(i, 'year').endOf('year').utc(),
    ]
  }
  return rangesAux
}

export const disableDatesNot12MonthsAfterTheStartDate = (
  current: Moment,
  startDate?: Moment | null,
  endDate?: Moment | null,
): boolean => {
  if (startDate) {
    const exactEndDate = startDate.clone().startOf('month').add(12, 'months').subtract(1, 'days')
    return !current.isSame(exactEndDate, 'day')
  }

  if (endDate) {
    const exactStartDate = endDate.clone().startOf('month').subtract(11, 'months')
    return !current.isSame(exactStartDate, 'day')
  }

  return false
}
