/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Form, FormInstance, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import moment from 'moment/moment'

import { RevenueDto } from '@cozero/dtos'

import {
  disableDatesNot12MonthsAfterTheStartDate,
  getPredefinedDateRanges,
} from '@/atoms/DateRangePicker/DateRangePicker.utils'
import { FormItem } from '@/atoms/FormItem/FormItem'
import InputField from '@/atoms/InputField'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

const { RangePicker } = DatePicker

export type ValueFormValues = {
  accountingPeriod: [moment.Moment, moment.Moment] | [null, null]
  annualRevenue: number
}

export const ValueForm = ({
  revenue,
  initialValues,
  onFinish,
  form,
}: {
  revenue: RevenueDto
  form: FormInstance<ValueFormValues>
  onFinish: (formValues: ValueFormValues) => void
  initialValues?: ValueFormValues
}): ReactElement => {
  const { t } = useTranslation('common')

  const businessUnit = useAppSelector(selectSelectedBusinessUnit)

  const accountingPeriod = useWatch('accountingPeriod', form)

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <FormItem
        label={t('revenue.form.business-level.label')}
        name="businessLevel"
        tooltip={t('revenue.form.business-level.tooltip')}
      >
        <Select
          disabled
          defaultValue={businessUnit?.id}
          options={[
            {
              label: t('revenue.form.business-level.entire-organization'),
              value: businessUnit?.id,
            },
          ]}
        />
      </FormItem>

      <FormItem
        label={t('revenue.form.accounting-period.label')}
        name="accountingPeriod"
        rules={[
          {
            required: true,
            message: t('revenue.form.accounting-period.required-error'),
          },
          {
            validator: async (_, accountingPeriod: [moment.Moment, moment.Moment] | null) => {
              const [periodStart, periodEnd] = accountingPeriod || []
              if (!periodStart || !periodEnd) {
                return
              }

              if (
                revenue?.revenueValues.some(
                  (revenueValue) =>
                    moment(revenueValue.periodStart) <= periodEnd &&
                    periodStart <= moment(revenueValue.periodEnd),
                )
              ) {
                throw new Error(t('revenue.form.accounting-period.error'))
              }
            },
          },
        ]}
        required={false}
      >
        <RangePicker
          picker="month"
          format="YYYY-MM"
          style={{ width: '100%' }}
          allowClear={true}
          onCalendarChange={(dates) => {
            form.setFieldsValue({ accountingPeriod: dates ?? [null, null] })
          }}
          disabledDate={(current) => {
            return disableDatesNot12MonthsAfterTheStartDate(
              current,
              accountingPeriod?.[0],
              accountingPeriod?.[1],
            )
          }}
          ranges={getPredefinedDateRanges()}
        />
      </FormItem>

      <FormItem
        label={t('revenue.form.annual-revenue.label')}
        name="annualRevenue"
        tooltip={t('revenue.form.annual-revenue.tooltip')}
        rules={[
          {
            required: true,
            message: t('revenue.form.annual-revenue.required-error'),
          },
        ]}
        required={false}
      >
        <InputField
          size="middle"
          type="number"
          format
          style={{ width: '100%' }}
          placeholder={t('revenue.form.annual-revenue.placeholder')}
          addonAfter={revenue?.currency}
        />
      </FormItem>
    </Form>
  )
}
