import React from 'react'
import { IconType } from 'react-icons/lib'

import { Radio } from 'antd'

import { CbamTemplateType } from '@prisma/client'
import classnames from 'classnames'

import { TemplateCardIcon } from '../SquaredIcon/SquaredIcon'

import classes from './TemplateCard.module.less'

export interface CbamTemplateItem {
  id: CbamTemplateType
  title: string
  icon: IconType
}

interface Props extends CbamTemplateItem {
  checked: boolean
  children: React.ReactElement
  onClick: (id: CbamTemplateType) => void
}

const _TemplateCard = ({
  checked,
  children,
  id,
  title,
  icon,
  onClick,
}: Props): React.ReactElement => (
  <div
    className={classnames(classes.templateCard, { [classes.selected]: !!checked })}
    onClick={() => onClick(id)}
  >
    <div className={classes.headerContainer}>
      <div className={classes.titleContainer}>
        <TemplateCardIcon icon={icon} />

        <span>{title}</span>
      </div>
      <Radio checked={checked} />
    </div>

    <div className={classes.cardContent}>{children}</div>
  </div>
)

export const TemplateCard = React.memo(_TemplateCard)
