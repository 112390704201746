import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

import { Col, Row, Skeleton } from 'antd'

import { routes } from '@cozero/utils'

import CompleteProductModal from '@/organisms/CompleteProductModal'
import SupplierShareModal from '@/organisms/SupplierShareModal'

import Alert from '@/atoms/Alert'
import Text from '@/atoms/Text'

import FaqSection from './FaqSection'
import StepRequest from './StepRequest/StepRequest'
import StepShare from './StepShare/StepShare'
import classes from './SupplierOnboarding.module.less'
import useSupplierOnboarding from './hooks/useSupplierOnboarding'

const SupplierOnboarding = (): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()

  const {
    factorRequest,
    isSuccess,
    showProductCreatedModal,
    fileList,
    currentStep,
    shareProductLifecycleSteps,
    totalProductEmissions,
    mass,
    selectedProduct,
    productLifecycleEmissions,
    comment,
    factorRequestIsSubmitting,
    setComment,
    closeShareModal,
    goToCreatePage,
    onSubmit,
    onUpload,
    setShowProductCreatedModal,
    setShareProductLifecycleSteps,
    handleDeleteAttachment,
  } = useSupplierOnboarding({ location })

  React.useEffect(() => {
    if (factorRequest && factorRequest.status !== 'REQUESTED' && currentStep !== 'STEP_2') {
      navigate(routes.log.factors.review.replace(':id', factorRequest.id.toString()), {
        state: { redirectAfterClose: routes.log.factors.requestsPage.base },
      })
    }
  }, [factorRequest, currentStep])

  if (!factorRequest) {
    return <Skeleton />
  }

  return (
    <div className={classes.mainWrapper}>
      <Row gutter={[0, 10]}>
        <Col md={24}>
          <Text mode="title" size="xs" fontWeight="medium">
            {factorRequest?.supplier.name}
            {t('onboarding.suppliers.preTitle')}
          </Text>
        </Col>
        <Col md={24}>
          <Text className={classes.suppliersTitle} mode="title" size="md" fontWeight="medium">
            <span className={classes.var}>{` ${
              factorRequest?.requester?.firstName
                ? factorRequest?.requester?.firstName
                : factorRequest?.requester.email
            } `}</span>
            <span>{t('onboarding.suppliers.titlePt1')}</span>
            <span className={classes.var}>{` ${factorRequest?.organization.name} `}</span>
            <span>{t('onboarding.suppliers.titlePt2')}</span>
          </Text>
        </Col>
        <Col md={24}>
          <Alert
            type="default"
            title={t('onboarding.suppliers.alert.title')}
            message={
              <Trans
                i18nKey="onboarding.suppliers.alert.text"
                values={{ organization: factorRequest?.organization.name }}
                components={[<a key={1} />, <a key={2} />, <a key={3} />]}
              />
            }
          />
        </Col>
      </Row>
      <div className={classes.container}>
        <Row gutter={[24, 14]}>
          <StepRequest
            mass={mass}
            step={currentStep}
            endedOnboarding={isSuccess}
            shareProductLifecycleSteps={shareProductLifecycleSteps}
            showShareModal={isSuccess}
            factorRequest={factorRequest}
            totalProductEmissions={totalProductEmissions}
            goToCreatePage={goToCreatePage}
          />
          <StepShare
            endedOnboarding={isSuccess}
            onSubmit={onSubmit}
            shareProductLifecycleSteps={shareProductLifecycleSteps}
            setShareProductLifecycleSteps={setShareProductLifecycleSteps}
            showShareModal={isSuccess}
            totalProductEmissions={totalProductEmissions}
            mass={mass}
            selectedProduct={selectedProduct}
            productLifecycleEmissions={productLifecycleEmissions}
            factorRequest={factorRequest}
            comment={comment}
            setComment={setComment}
            fileList={fileList}
            onUpload={onUpload}
            onDeleteAttachment={handleDeleteAttachment}
            isSubmitting={factorRequestIsSubmitting}
            disabled={currentStep === 'STEP_1'}
          />
          {factorRequest?.type === 'GHG' && (
            <CompleteProductModal
              showModal={showProductCreatedModal}
              text={t('onboarding.suppliers.create-product-modal.text')}
              buttonText={t('onboarding.suppliers.create-product-modal.buttonText')}
              onClick={() => setShowProductCreatedModal(false)}
              onCancel={() => setShowProductCreatedModal(false)}
            />
          )}
        </Row>
        <SupplierShareModal visible={isSuccess} closeModal={closeShareModal} />

        <FaqSection />
      </div>
    </div>
  )
}

export default SupplierOnboarding
