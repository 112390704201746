import { useCallback } from 'react'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'

export const usePricingFeature = (): {
  featuresAllowed: string[]
  isFeatureEnabled: (feature: string) => boolean
} => {
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const isFeatureEnabled = useCallback(
    (feature: string) => featuresAllowed.includes(feature),
    [featuresAllowed],
  )

  return {
    featuresAllowed,
    isFeatureEnabled,
  }
}
