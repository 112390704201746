import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { routes } from '@cozero/utils'

import Alert from '@/atoms/Alert'
import LoadingSpinner from '@/atoms/LoadingSpinner'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { selectUser, useLoginWithTokenMutation } from '@/redux/auth'

import classes from '../Auth.module.less'

const Callback = (): ReactElement => {
  const { t } = useTranslation('common')
  const user = useAppSelector(selectUser)
  const [loginWithToken, { error }] = useLoginWithTokenMutation()
  const searchParams = new URLSearchParams(location.hash.substring(1))
  const token = searchParams.get('id_token')
  const { isFeatureEnabled } = usePricingFeature()
  const isHomeEnabled = isFeatureEnabled('dashboard-home')

  const login = async (idToken: string): Promise<void> => {
    await loginWithToken(idToken)
  }

  useEffect(() => {
    if (token) {
      login(token)
    }
  }, [token])

  if (user && user?.id) {
    return <Navigate to={isHomeEnabled ? routes.dashboardHome.home : routes.overview.dashboard} />
  }

  if (error) {
    return (
      <div className={classes.container}>
        <Alert message={t('login.error')} type="danger" />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <LoadingSpinner title={t('loading')} />
    </div>
  )
}

export default Callback
