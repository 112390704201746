import { useEffect, useMemo, useState } from 'react'
import { TFunction } from 'react-i18next'

import { UserUpdate, UserUpdateStatus, UserUpdateType } from '@prisma/client'
import { orderBy } from 'lodash'
import moment from 'moment'

import { User, UserUpdatesMilestoneContext, UserUpdatesTaskContext } from '@cozero/models'
import { userFullNameOrEmail } from '@cozero/utils'

import { useReadUserUpdatesMutation, useUpdateUserUpdateMutation } from '@/redux/userUpdates/api'
import { useGetUserUpdatesQuery } from '@/redux/userUpdates/api'
import { useLazyGetUserQuery } from '@/redux/users/api'

type UserUpdateItem = {
  doneBy: User & { doneByName: string }
  update: UserUpdate
}

const DEADLINE_UPDATE_TYPES: UserUpdateType[] = [
  UserUpdateType.MILESTONE_DEADLINE_UPDATED,
  UserUpdateType.TASK_DEADLINE_UPDATED,
]

const UPDATE_TYPES: Record<'MILESTONE' | 'TASK', UserUpdateType[]> = {
  MILESTONE: [
    UserUpdateType.MILESTONE_SUPPORTER_ASSIGNED,
    UserUpdateType.MILESTONE_TASKS_UPDATED,
    UserUpdateType.MILESTONE_DEADLINE_UPDATED,
    UserUpdateType.MILESTONE_OWNER_ASSIGNED,
    UserUpdateType.MILESTONE_DELETED,
    UserUpdateType.MILESTONE_COMPLETED,
  ],
  TASK: [
    UserUpdateType.TASK_OWNER_ASSIGNED,
    UserUpdateType.TASK_DEADLINE_UPDATED,
    UserUpdateType.TASK_DELETED,
    UserUpdateType.TASK_STATUS_UPDATED,
  ],
}

export const getEntityName = (update: UserUpdate): string => {
  if (UPDATE_TYPES.MILESTONE.includes(update.type)) {
    const context = update.context as unknown as UserUpdatesMilestoneContext
    return context.milestoneName
  }
  if (UPDATE_TYPES.TASK.includes(update.type)) {
    const context = update.context as unknown as UserUpdatesTaskContext
    return context.taskName
  }
  return ''
}

export const getAction = (
  type: UserUpdateType,
  t: TFunction,
  context: UserUpdatesMilestoneContext | UserUpdatesTaskContext,
  doerName: string,
): Record<string, string> & { key: string } => {
  const taskContext = context as UserUpdatesTaskContext
  const milestoneContext = context as UserUpdatesMilestoneContext
  let key = `dashboard.home.all-updates.${type.toLowerCase().replace(/_/g, '-')}`
  if (DEADLINE_UPDATE_TYPES.includes(type)) {
    if (!context.previousDeadline) {
      key = `${key}-no-previous`
    } else if (!context.deadline) {
      key = `${key}-no-new`
    }
  }

  return {
    key,
    doerName,
    milestoneName: milestoneContext.milestoneName,
    taskName: taskContext.taskName,
    oldDeadline: moment(context.previousDeadline).format('ll'),
    newDeadline: moment(context.deadline).format('ll'),
    oldStatus: taskContext.previousStatus
      ? t(`dashboard.home.home.tasks.statuses.${taskContext.previousStatus}`)
      : '',
    newStatus: taskContext.taskStatus
      ? t(`dashboard.home.home.tasks.statuses.${taskContext.taskStatus}`)
      : '',
  }
}

type UseUpdatesDrawerReturn = {
  userUpdateItems: UserUpdateItem[]
  isLoading: boolean
  handleClose: () => void
  selectedFilter: 'ALL' | 'UNREAD'
  setSelectedFilter: (filter: 'ALL' | 'UNREAD') => void
  markAllAsRead: () => Promise<void>
  markAsRead: (id: number) => Promise<void>
}

export const useUpdatesDrawer = ({
  onClose,
  isOpen,
  preselctedFilter,
}: {
  onClose?: () => void
  isOpen?: boolean
  preselctedFilter?: 'ALL' | 'UNREAD' | undefined
}): UseUpdatesDrawerReturn => {
  const [userUpdateItems, setUserUpdateItems] = useState<UserUpdateItem[]>([])
  const [selectedFilter, setSelectedFilter] = useState<'ALL' | 'UNREAD'>(preselctedFilter || 'ALL')

  const { data: updates = [], isLoading: isLoadingUpdates, refetch } = useGetUserUpdatesQuery()
  const [getUser, { isLoading: isLoadingUser }] = useLazyGetUserQuery()
  const [readMany] = useReadUserUpdatesMutation()
  const [updateUserUpdate] = useUpdateUserUpdateMutation()

  // Fetch users for each update and set them in userUpdateItems
  useEffect(() => {
    if (updates.length) {
      setUserUpdateItems([])
      for (const update of updates) {
        const context = update.context as unknown as UserUpdatesMilestoneContext
        getUser(context.doneById).then((res) => {
          if (res.data) {
            const user = res.data as User
            setUserUpdateItems((prev) => [
              ...prev,
              {
                doneBy: {
                  ...user,
                  id: context.doneById,
                  doneByName: userFullNameOrEmail(user.email, user.firstName, user.lastName),
                },
                update,
              } as UserUpdateItem,
            ])
          }
        })
      }
    }
  }, [updates])

  const handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const markAllAsRead = async (): Promise<void> => {
    const unreadUpdates = userUpdateItems.filter(
      (item) => item.update.status === UserUpdateStatus.UNREAD,
    )
    if (unreadUpdates.length) {
      await readMany({
        ids: unreadUpdates.map((item) => item.update.id),
      })
    }
  }

  const markAsRead = async (id: number): Promise<void> => {
    await updateUserUpdate({ id, status: UserUpdateStatus.READ })
  }

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen])

  const filteredUserUpdateItems = useMemo(() => {
    if (selectedFilter === 'UNREAD') {
      return userUpdateItems.filter((item) => item.update.status === UserUpdateStatus.UNREAD)
    } else {
      return userUpdateItems
    }
  }, [selectedFilter, userUpdateItems])

  return {
    userUpdateItems: orderBy(filteredUserUpdateItems, 'update.createdAt', 'desc'),
    isLoading: isLoadingUpdates || isLoadingUser,
    handleClose,
    selectedFilter,
    setSelectedFilter,
    markAllAsRead,
    markAsRead,
  }
}
