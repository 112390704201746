import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Text from '@/atoms/Text'

import classes from './SupplierExtraInformation.module.less'

const SupplierExtraInformation = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Text fontWeight="semibold" mode="title" size="xs">
        {t('suppliers.additional-information')}
      </Text>
      <Row gutter={[48, 16]}>
        <Col span={12}>
          <Form.Item
            label={<Label>{t('suppliers.factor.due_date')}</Label>}
            name="dueDate"
            rules={[
              { required: true, message: t('suppliers.factor.validation.required-deadline') },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              picker="date"
              placeholder={t('customer-requests.due-date-placeholder')}
              className={classes.datePicker}
              disabledDate={(d) => d.isBefore(new Date())}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={<Label>{t('suppliers.extra-information')}</Label>} name="note">
            <InputField
              type="textarea"
              autoSize
              placeholder={t('suppliers.additional-information-placeholder')}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default SupplierExtraInformation
