import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'

import { Col, Divider, Row, Spin, message } from 'antd'

import { FactorRequestType } from '@prisma/client'
import { saveAs } from 'file-saver'

import { FactorRequestResponse } from '@cozero/models'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import EmissionPerLifecycleTable from '@/organisms/EmissionPerLifecycleTable'
import { StrategyBuilderOverviewCard } from '@/organisms/StrategyBuilderOverviewCards/StrategyBuilderOverviewCard'

import Button from '@/atoms/Button'
import CheckOutlineIcon from '@/atoms/Icons/CheckOutline'
import PaperClipOutlineIcon from '@/atoms/Icons/PaperClipOutline'
import XOutlineIcon from '@/atoms/Icons/XOutline'
import LazyLoadImage from '@/atoms/LazyLoadImage'
import Text from '@/atoms/Text'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import placeholderImage from '@/assets/placeholder-image.png'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useRequestIsTargetedAtMe } from '@/hooks/useRequestIsTargetedAtMe'
import {
  useFindFactorRequestByIdQuery,
  useUpdateOneFactorRequestMutation,
} from '@/redux/factors-requests'
import { CINDER_BLUE_50 } from '@/styles/variables'
import { formatLifecycleSteps } from '@/utils/formats'
import { formatCompact } from '@/utils/number'

import { FactorStatusTag } from '../Factors/FactorRequests/components/FactorStatusTag'

import classes from './classes.module.less'
import { FactorRequestReviewDescription } from './components/FactorRequestReviewDescription'

function FactorReviewPage(): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  const { data: factorRequest, isLoading } = useFindFactorRequestByIdQuery(id as string, {
    skip: !id,
  })
  const [updateFactorRequestMutation, { isLoading: updateIsPending, originalArgs }] =
    useUpdateOneFactorRequestMutation()

  const isTargetedAtMe = useRequestIsTargetedAtMe(factorRequest)
  const requestIsUpdatable = factorRequest?.status === 'SUBMITTED' && !isTargetedAtMe

  const sharedLifeCycles = React.useMemo(() => {
    if (factorRequest) {
      return formatLifecycleSteps(
        factorRequest?.factorRequestResponse?.shownLifecycleSteps as {
          id: number
          value: number
        }[],
      )
    }
    return []
  }, [factorRequest])

  const goBack = (): void => {
    if (location.state?.redirectAfterClose) {
      navigate(location.state?.redirectAfterClose)
    } else {
      navigate(-1)
    }
  }

  const handleUpdateFactorRequest = async (
    id: number,
    newStatus: 'REJECTED' | 'ACCEPTED',
    denominatorUnitId: number,
  ): Promise<void> => {
    const mapNewStatusToMessageKey: Record<typeof newStatus, string> = {
      ACCEPTED: 'factors.accept-success',
      REJECTED: 'factors.reject-info',
    }
    const result = await updateFactorRequestMutation({ id, status: newStatus, denominatorUnitId })

    if ('error' in result) {
      message.error(t('factors.save-factor.error'))
    } else {
      const translationKey = mapNewStatusToMessageKey[newStatus]
      message.success(t(translationKey))
    }

    goBack()
  }

  const downloadFile = (blob: Blob | string, filename: string): void => {
    saveAs(blob, filename)
  }

  if (!factorRequest || isLoading) {
    return (
      <Row className={classes.centeredSpin}>
        <Spin spinning />
      </Row>
    )
  }

  const renderModalTitleAndDescription = (): {
    title: React.ReactElement
    description: React.ReactElement | string
  } => {
    const { status } = factorRequest

    if (requestIsUpdatable) {
      return {
        title: (
          <Trans
            i18nKey="customer-requests.review-modal.title"
            components={{
              br: <br />,
            }}
          />
        ),
        description: '',
      }
    }

    if (isTargetedAtMe && status !== 'REQUESTED') {
      return {
        title: <FactorStatusTag status={status} />,
        description: t(`customer-requests.review-modal.supplier-descriptions.${status}`, {
          orgName: factorRequest.organization.name,
        }),
      }
    }

    return {
      title: <FactorStatusTag status={status} />,
      description: t(`customer-requests.review-modal.descriptions.${status}`),
    }
  }

  const renderFiles = (files: FactorRequestResponse['files']): React.ReactElement => (
    <>
      {files?.map((file, index: number) => {
        const { name, path } = file || {}
        const fileName = name || (path && path.split('/').pop()) || `file-${index + 1}`
        if (!file.url) {
          return null
        }
        return (
          <div
            className={classes.file}
            key={file.id}
            onClick={() => downloadFile(file.url as string, fileName)}
          >
            <PaperClipOutlineIcon width={14} height={14} color={CINDER_BLUE_50} />
            <Text size="xl" color="secondary">
              {fileName}
            </Text>
          </div>
        )
      })}
    </>
  )

  return (
    <div className={classes.container}>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <>
          <CustomHeader
            showArrow={true}
            title={factorRequest?.activityDataSource?.name as string}
            onClose={goBack}
            goBack={goBack}
          />

          <div className={classes.content}>
            <LifecycleModalTitle
              image={productConfiguration}
              {...renderModalTitleAndDescription()}
            />
            <FactorRequestReviewDescription factorRequest={factorRequest} />
            {factorRequest.type === FactorRequestType.GHG && (
              <Row
                className={classes.productDetails}
                gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              >
                <Col flex={2} className={classes.productInfo}>
                  <Row gutter={20}>
                    <Col>
                      <LazyLoadImage
                        src={factorRequest.product?.image?.url ?? placeholderImage}
                        className={classes.productImage}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col flex={1} className={classes.productFootprint}>
                  <StrategyBuilderOverviewCard title={t('log.products-title')}>
                    <div>
                      <Text
                        mode="title"
                        fontWeight="medium"
                        size="md"
                        className={classes.valueWithUnit}
                      >
                        {factorRequest.value ? formatCompact(factorRequest.value) : '--'}
                      </Text>

                      <Text size="lg" fontWeight="medium">
                        {factorRequest?.unit?.name as string}
                      </Text>
                    </div>
                  </StrategyBuilderOverviewCard>
                </Col>
              </Row>
            )}

            {!!factorRequest?.factorRequestResponse?.shownLifecycleSteps.length && (
              <EmissionPerLifecycleTable
                factorRequest={factorRequest}
                sharedLifeCycles={sharedLifeCycles}
                totalEmissionValue={factorRequest.value}
              />
            )}

            {(!!factorRequest?.factorRequestResponse?.message ||
              !!factorRequest?.factorRequestResponse?.files.length ||
              factorRequest.type === FactorRequestType.CBAM) && (
              <div className={classes.additionalSection}>
                <Row>
                  <Col span={14}>
                    <Text fontWeight="semibold" size="xl">
                      {t('carbon-actions.metadata.title')}
                    </Text>
                  </Col>

                  <Col span={10}>
                    <Text fontWeight="semibold" size="xl">
                      {t('customer-requests.review-modal.attached-files')}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className={classes.additionalInfo}>
                    <div className={classes.message}>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {factorRequest?.factorRequestResponse?.message}
                      </Text>
                    </div>
                  </Col>
                  <Col span={2}>
                    <Divider type="vertical" style={{ height: '100%', marginLeft: '50%' }} />
                  </Col>
                  <Col span={10} className={classes.filesSection}>
                    {factorRequest?.factorRequestResponse?.cbamTemplate && (
                      <div>
                        <Text size="lg">CBAM file</Text>
                        {renderFiles([factorRequest?.factorRequestResponse?.cbamTemplate])}
                      </div>
                    )}
                    {!!factorRequest?.factorRequestResponse?.files.length && (
                      <div>
                        {factorRequest?.factorRequestResponse?.cbamTemplate && (
                          <Text size="lg">Additional files</Text>
                        )}
                        {renderFiles(factorRequest?.factorRequestResponse?.files)}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>

          {requestIsUpdatable && (
            <footer className={classes.footer}>
              <Row gutter={30} className={classes.footerContent}>
                <Col>
                  {!isTargetedAtMe && (
                    <Button
                      size="lg"
                      prefixIcon={<XOutlineIcon width={16} height={16} />}
                      action={'reject'}
                      category={AnalyticsCategories.FACTOR_REQUESTS}
                      color="danger"
                      loading={updateIsPending && originalArgs?.status === 'REJECTED'}
                      onClick={() =>
                        handleUpdateFactorRequest(
                          factorRequest.id,
                          'REJECTED',
                          factorRequest.denominatorUnitId,
                        )
                      }
                    >
                      {t('customer-requests.reject')}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    type="primary"
                    prefixIcon={<CheckOutlineIcon width={16} height={16} />}
                    action={'accept'}
                    category={AnalyticsCategories.FACTOR_REQUESTS}
                    size="lg"
                    loading={updateIsPending && originalArgs?.status === 'ACCEPTED'}
                    onClick={() =>
                      handleUpdateFactorRequest(
                        factorRequest.id,
                        'ACCEPTED',
                        factorRequest.denominatorUnitId,
                      )
                    }
                  >
                    {t('customer-requests.accept')}
                  </Button>
                </Col>
              </Row>
            </footer>
          )}
        </>
      </div>
    </div>
  )
}

export default FactorReviewPage
