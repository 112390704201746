/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row, Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsAdmin, getIsRootBusinessUnitUser } from '@/redux/auth'

import classes from './Organization.module.less'

const OrganizationBase = (): ReactElement => {
  const { t } = useTranslation()

  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { isFeatureEnabled } = usePricingFeature()
  const isHomeEnabled = isFeatureEnabled('dashboard-home')

  const isAdmin = useAppSelector(getIsAdmin)
  const isRootBusinessUnitUser = useAppSelector(getIsRootBusinessUnitUser)

  const menuItems = React.useMemo(() => {
    const items = [
      {
        uri: `${routes.organization.locations.base}`,
        title: t('layout.locations'),
      },
    ]

    if (isFeatureEnabled('business-units')) {
      items.push({
        uri: `${routes.organization.businessUnits}`,
        title: t('layout.business-units'),
      })
    }

    items.push({
      uri: `${routes.organization.organigram}`,
      title: t('layout.organigram'),
    })

    if (isAdmin && isRootBusinessUnitUser) {
      items.push({
        uri: `${routes.organization.revenue}`,
        title: t('layout.revenue'),
      })
    }

    return items
  }, [isAdmin, isFeatureEnabled, isRootBusinessUnitUser, t])

  const [currentTab, setCurrentTab] = useState<{ uri: string; title: string }>(menuItems[0])

  const onTabClickHandler = useCallback(
    (key: string): void => {
      setCurrentTab(menuItems.find((item) => item.uri === key) ?? menuItems[0])

      return navigate(key)
    },
    [menuItems, navigate],
  )

  const TabsItems = menuItems.map(({ uri, title }) => (
    <TabPane key={uri} tab={title}>
      {pathname === uri && <Outlet />}
    </TabPane>
  ))

  useEffect(() => {
    if (pathname) {
      const menuItemFromUrl = menuItems.find((item) => item.uri === pathname)

      if (menuItemFromUrl) {
        setCurrentTab(menuItemFromUrl)
      } else {
        navigate(isHomeEnabled ? routes.dashboardHome.home : routes.overview.dashboard)
      }
    }
  }, [pathname, menuItems, navigate, isHomeEnabled])

  return (
    <Row justify="center" className={classes.tabSection}>
      <Col span={24}>
        <Tabs
          activeKey={currentTab.uri}
          defaultActiveKey={menuItems[0].uri}
          onTabClick={onTabClickHandler}
          data-cy="business-unit-tab"
        >
          {TabsItems}
        </Tabs>
      </Col>
    </Row>
  )
}

export default OrganizationBase
