import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Skeleton } from 'antd'

import classNames from 'classnames'

import Card from '../../../../atoms/Card'
import Tag, { TagType } from '../../../../atoms/Tag'
import Text from '../../../../atoms/Text'

import classes from './ActionCard.module.less'

const StepNumberElement = ({ stepNumber }: { stepNumber: number }): JSX.Element => (
  <div className={classes.stepPill}>
    <Text color="blue" size="lg" mode="text">
      {stepNumber}
    </Text>
  </div>
)

const TitleElement = ({ title }: { title: string }): JSX.Element => (
  <div className={classes.titleContainer}>
    <Text fontWeight="semibold" className={classes.title}>
      {title}
    </Text>
  </div>
)

const TextElement = ({ text }: { text: JSX.Element }): JSX.Element => (
  <div className={classes.stepHeader}>
    <Text color="secondary" mode="paragraph" size="xl">
      {text}
    </Text>
  </div>
)

const ActionCardWithStepNumber = ({
  stepNumber,
  title,
  text,
  type,
  status,
  children,
}: {
  stepNumber: number
  title: string
  text: JSX.Element
  type?: TagType
  status?: string
  children: JSX.Element
}): JSX.Element => {
  return (
    <>
      <Col span={1}>
        <StepNumberElement stepNumber={stepNumber} />
      </Col>
      <Col span={21}>
        <TitleElement title={title} />
      </Col>
      <Col className={classes.justifyContentEnd} span={2}>
        <Tag type={type}>{status}</Tag>
      </Col>
      <Col offset={1} span={23}>
        <TextElement text={text} />
      </Col>
      <Col offset={1} span={23}>
        {children}
      </Col>
    </>
  )
}

const ActionCardWithoutStepNumber = ({
  title,
  text,
  type,
  status,
  children,
}: {
  title: string
  text: JSX.Element
  type?: TagType
  status?: string
  children: JSX.Element
}): JSX.Element => {
  return (
    <>
      <Col span={22}>
        <TitleElement title={title} />
      </Col>
      <Col className={classes.justifyContentEnd} span={2}>
        <Tag type={type}>{status}</Tag>
      </Col>
      <Col span={24}>
        <TextElement text={text} />
      </Col>
      <Col span={24}>{children}</Col>{' '}
    </>
  )
}

const ActionCard = ({
  children,
  disabled,
  title,
  text,
  stepNumber,
  status,
  active = true,
  shadow = 'none',
  isLoading,
  tagType,
}: {
  active?: boolean
  title: string
  text: JSX.Element
  shadow?: 'none' | 'sm' | 'md' | 'lg'
  className?: string
  status?: string
  stepNumber?: number
  disabled?: boolean
  isLoading?: boolean
  tagType?: TagType
  children: JSX.Element
}): JSX.Element => {
  const { t } = useTranslation('common')

  const statusTagType =
    tagType ??
    (status === t('onboarding.suppliers.waiting') ||
    status === t('onboarding.suppliers.in-progress')
      ? 'default'
      : 'info')

  return (
    <div className={classes.container}>
      {disabled && <div className={classes.disableLayer}></div>}
      <Card
        bgColor="default"
        shadow={shadow}
        className={classNames({ [classes.activeCard]: active })}
      >
        <Skeleton loading={isLoading}>
          <Row gutter={[0, 8]}>
            {stepNumber ? (
              <ActionCardWithStepNumber
                stepNumber={stepNumber}
                title={title}
                text={text}
                type={statusTagType}
                status={status}
              >
                {children}
              </ActionCardWithStepNumber>
            ) : (
              <ActionCardWithoutStepNumber
                title={title}
                text={text}
                type={statusTagType}
                status={status}
              >
                {children}
              </ActionCardWithoutStepNumber>
            )}
          </Row>
        </Skeleton>
      </Card>
    </div>
  )
}

export default ActionCard
