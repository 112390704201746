export const GET_LOG = 'getLog'
export const CREATE_LOG = 'createLog'
export const EDIT_LOG = 'editLog'
export const HAS_LOG_ENTRIES = 'hasLogEntries'
export const DELETE_LOG = 'deleteLog'
export const GET_PAGINATED_LOGS = 'getPaginatedLogs'
export const GET_FULL_LOG_RANGE = 'getFullLogRange'

export const SOLUTIONS_TAG = 'SOLUTIONS'
export default [SOLUTIONS_TAG]
