import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { routes } from '@cozero/utils'

import Activate from '@/pages/Auth/Activate'
import ExpiredToken from '@/pages/Auth/ExpiredToken'
import Callback from '@/pages/Auth/Login/Callback'

import PageLayout from '../PageLayout'
import SuspenseSpinner from '../SuspenseSpinner'

const Logout = lazy(() => import('@/pages/Auth/Logout'))

const StartLogin = lazy(() => import('@/pages/Auth/Login/Start'))
const FinishLogin = lazy(() => import('@/pages/Auth/Login/Finish'))

const RequestResetPassword = lazy(() => import('@/pages/Auth/RequestResetPassword'))
const SubmitResetPassword = lazy(() => import('@/pages/Auth/SubmitResetPassword'))

const CreateOrganization = lazy(() => import('@/pages/Auth/Register/CreateOrganization'))
const CreateProfile = lazy(() => import('@/pages/Auth/Register/CreateProfile'))
const StartRegister = lazy(() => import('@/pages/Auth/Register/Start'))

const RouteWrapper = ({ children }: { children: ReactNode }): ReactElement => (
  <>
    <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
  </>
)

const getAuthRoutes = (): RouteObject[] => [
  {
    path: routes.login.base,
    children: [
      {
        path: routes.login.start,
        element: (
          <RouteWrapper>
            <StartLogin />
          </RouteWrapper>
        ),
      },
      {
        path: routes.login.finish,
        element: (
          <RouteWrapper>
            <FinishLogin />
          </RouteWrapper>
        ),
      },
    ],
  },
  {
    path: routes.password.base,
    children: [
      {
        path: routes.password.requestReset,
        element: (
          <RouteWrapper>
            <RequestResetPassword />
          </RouteWrapper>
        ),
      },
      {
        path: routes.password.submitReset,
        element: (
          <RouteWrapper>
            <SubmitResetPassword />{' '}
          </RouteWrapper>
        ),
      },
    ],
  },
  {
    path: routes.register.base,
    children: [
      {
        path: routes.register.start,
        element: (
          <RouteWrapper>
            <StartRegister />
          </RouteWrapper>
        ),
      },
      {
        path: routes.register.user,
        element: (
          <RouteWrapper>
            <CreateProfile />
          </RouteWrapper>
        ),
      },
      {
        path: routes.register.organization,
        element: (
          <RouteWrapper>
            <CreateOrganization />
          </RouteWrapper>
        ),
      },
    ],
  },
  {
    path: routes.logout,
    element: (
      <RouteWrapper>
        <Logout />
      </RouteWrapper>
    ),
  },
  {
    path: routes.base,
    element: <PageLayout />,
    children: [
      {
        path: routes.expiredToken,

        element: (
          <RouteWrapper>
            <ExpiredToken />
          </RouteWrapper>
        ),
      },
    ],
  },
  { path: routes.callback, element: <Callback /> },
  { path: routes.activate, element: <Activate /> },
]

export default getAuthRoutes
